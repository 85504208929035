import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import 'flatpickr/dist/flatpickr.css';
import { AppComponent } from './app.component';
import { HomeComponent } from './components/home/home.component';
import { ContactoComponent } from './components/contacto/contacto.component';
import { AcercaDeComponent } from './components/acerca-de/acerca-de.component';
import { HistoriaComponent } from './components/historia/historia.component';
import { NovedadesComponent } from './components/novedades/novedades.component';
import { PlenariaComponent } from './components/plenaria/plenaria.component';
import { OrganizacionComponent } from './components/organizacion/organizacion.component';
import { InformesComponent } from './components/informes/informes.component';
import { BoletinesComponent } from './components/boletines/boletines.component';
import { ParlacenTvComponent } from './components/parlacen-tv/parlacen-tv.component';
import { HttpClientModule } from '@angular/common/http'
import { routing } from '../app/app.routing';
import { NavbarComponent } from './components/navbar/navbar.component';
import { FooterComponent } from './components/footer/footer.component';
import { MarcoPoliticoJuridicoComponent } from './components/marco-politico-juridico/marco-politico-juridico.component';
import { AsambleaComponent } from './components/asamblea/asamblea.component';
import { BloqueMujeresParlamentariasComponent } from './components/bloque-mujeres-parlamentarias/bloque-mujeres-parlamentarias.component';
import { BoletinParlamentarioComponent } from './components/boletin-parlamentario/boletin-parlamentario.component';
import { ComisionesComponent } from './components/comisiones/comisiones.component';
import { JuntaDirectivaComponent } from './components/junta-directiva/junta-directiva.component';
import { GruposParlamentariosComponent } from './components/grupos-parlamentarios/grupos-parlamentarios.component';
import { EurolatComponent } from './components/eurolat/eurolat.component';
import { Error404Component } from './components/error404/error404.component';
import { Covid19Component } from './components/covid19/covid19.component';
import { DiputadosComponent } from './components/diputados/diputados.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { NoticiaComponent } from './components/noticias/noticia/noticia.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { AgendaComponent } from './components/agenda/agenda.component';
import { NgbModalModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FlatpickrModule } from 'angularx-flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es';
import { GLOBAL } from './services/global.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { RecaptchaModule } from 'ng-recaptcha';
import { NewsComponent } from './components/news/news.component';
import { CardsDisplayComponent } from './components/library/cards-display/cards-display.component';
import { IotaComponent } from './components/iota/iota.component';
import { CarouselComponent } from './components/library/carousel/carousel.component';
import { SpinnerComponent } from './components/library/spinner/spinner.component';
import { BabyBannerComponent } from './components/library/baby-banner/baby-banner.component';
import { ConcursoComponent } from './components/concurso/concurso.component';
import { ConcursoDosComponent } from './components/concurso-dos/concurso.component';
import { OtrosParlamentosComponent } from './components/otros-parlamentos/otros-parlamentos.component';
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { EleccionesComponent } from './components/elecciones/elecciones.component';
import { ConcursotresComponent } from './components/concursotres/concursotres.component';
import { ConvocatoriaComponent } from './components/convocatoria/convocatoria.component';
import { ListaDiputadosComponent } from './components/lista-diputados/lista-diputados.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactoComponent,
    AcercaDeComponent,
    HistoriaComponent,
    NovedadesComponent,
    PlenariaComponent,
    OrganizacionComponent,
    InformesComponent,
    BoletinesComponent,
    ParlacenTvComponent,
    NavbarComponent,
    FooterComponent,
    MarcoPoliticoJuridicoComponent,
    AsambleaComponent,
    BloqueMujeresParlamentariasComponent,
    BoletinParlamentarioComponent,
    ComisionesComponent,
    JuntaDirectivaComponent,
    GruposParlamentariosComponent,
    EurolatComponent,
    Error404Component,
    Covid19Component,
    DiputadosComponent,
    NoticiasComponent,
    NoticiaComponent,
    AgendaComponent,
    NewsComponent,
    CardsDisplayComponent,
    IotaComponent,
    CarouselComponent,
    SpinnerComponent,
    BabyBannerComponent,
    ConcursoComponent,
    ConcursoDosComponent,
    OtrosParlamentosComponent,
    SitemapComponent,
    EleccionesComponent,
    ConcursotresComponent,
    ConvocatoriaComponent,
    ListaDiputadosComponent,

  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    routing,
    NgbModule,
    AngularFireModule.initializeApp(GLOBAL.firebaseConfig),
    AngularFireStorageModule,
    FormsModule,
    NgbModalModule,
    BrowserAnimationsModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    FlatpickrModule.forRoot({ locale: Spanish }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoader,
        deps: [HttpClient]
      }
    }),
    RecaptchaModule,

  ],
  exports: [
    HttpClientModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function httpTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
import { Component, OnInit } from '@angular/core';
import { MemberService } from 'src/app/services/member.services';
import { Member } from 'src/app/models/member.model';
import { Deputies } from 'src/app/models/deputy.model';
import { DiputiesService } from 'src/app/services/diputies.service';

declare var $: any;

@Component({
  selector: 'app-comisiones',
  templateUrl: './comisiones.component.html',
  styleUrls: ['./comisiones.component.css'],
  providers: [MemberService]
})
export class ComisionesComponent implements OnInit {
  public members: Member[];
  public status: string;
  public loading: boolean = false;
  public memebers1: any = {}
  public pg_GPICD: Member[];
  public pg_GPI: Member[];
  public pg_UDI: Member[];
  public deputies: Deputies[] = [];
  public deputy: Deputies;
  public deputiesEuro: Deputies[] = [];
  public deputiesCAAP: Deputies[] = [];
  public deputiesCAJIR: Deputies[] = [];
  public deputiesCDMPC: Deputies[] = [];
  public deputiesCECDCT: Deputies[] = [];
  public deputiesCICDE: Deputies[] = [];
  public deputiesCMNJF: Deputies[] = [];
  public deputiesCMF: Deputies[] = [];
  public deputiesCPIA: Deputies[] = [];
  public deputiesCRIAM: Deputies[] = [];
  public deputiesCSSS: Deputies[] = [];
  public deputiesCSCP: Deputies[] = [];
  public deputiesCES: Deputies[] = [];
  public deputiesCT: Deputies[] = [];
  public deputiesCPAP: Deputies[] = [];
  public deputiesCGI: Deputies[] = [];


  constructor(
    private _membersService: MemberService,
    private _deputyService: DiputiesService
  ) { }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.listarDiputados();
    this.listarDiputadosEurolat();
  }
  listarDocumentos(): void {
    this._membersService.listMember().subscribe(
      res => {
        if (!res.member) {
          this.status = 'error';
        } else {
          if (res.member.length === 0) {
            alert('No hay documentos para mostrar');
          } else {
            this.members = res.member;
            this.pg_GPICD = this.members.filter((member) => member.pg == "GRUPO PARLAMENTARIO INTEGRACIÓN CENTRO DEMOCRÁTICO");
            this.pg_GPICD = this.members.filter((member) => member.pg == "GRUPO PARLAMENTARIO INTEGRACIÓN CENTRO DEMOCRÁTICO")
          }
          this.status = 'ok';
        }
        this.loading = false;
      },
      err => this.onError(err)
    );
  }

  listarDiputados(): void {
    //this.loading = true;
    this._deputyService.listarDiputadosComision().subscribe(

      res => {
        if (res.commissionsDeputies?.length <= 0) {
          this.status = 'error';
        } else {
          if (res.commissionsDeputies?.length === 0) {
            alert('No hay diputados para mostrar');
          } else {
            this.deputiesCAAP = res.commissionsDeputies[0].deputies;
            this.deputiesCAJIR = res.commissionsDeputies[1].deputies;
            this.deputiesCDMPC = res.commissionsDeputies[2].deputies;
            this.deputiesCECDCT = res.commissionsDeputies[3].deputies;
            this.deputiesCICDE = res.commissionsDeputies[4].deputies;
            this.deputiesCMNJF = res.commissionsDeputies[5].deputies;
            this.deputiesCMF = res.commissionsDeputies[6].deputies;
            this.deputiesCPIA = res.commissionsDeputies[7].deputies;
            this.deputiesCRIAM = res.commissionsDeputies[8].deputies;
            this.deputiesCSSS = res.commissionsDeputies[9].deputies;
            this.deputiesCSCP = res.commissionsDeputies[10].deputies;
            this.deputiesCT = res.commissionsDeputies[11].deputies;
            this.deputiesCPAP = res.commissionsDeputies[12].deputies;
            this.deputiesCGI = res.commissionsDeputies[13].deputies;
            this.deputiesCES = res.commissionsDeputies[14].deputies;
            console.log(this.deputiesCAAP);
            this.sortDpByLast(this.deputiesCAAP);
            this.sortDescByDate(this.deputiesCAAP);
            this.sortDpByLast(this.deputiesCAJIR);
            this.sortDescByDate(this.deputiesCAJIR);
            this.sortDpByLast(this.deputiesCDMPC);
            this.sortDescByDate(this.deputiesCDMPC);
            this.sortDpByLast(this.deputiesCECDCT);
            this.sortDescByDate(this.deputiesCECDCT);
            this.sortDpByLast(this.deputiesCICDE);
            this.sortDescByDate(this.deputiesCICDE);
            this.sortDpByLast(this.deputiesCMNJF);
            this.sortDescByDate(this.deputiesCMNJF);
            this.sortDpByLast(this.deputiesCMF);
            this.sortDescByDate(this.deputiesCMF);
            this.sortDpByLast(this.deputiesCPIA);
            this.sortDescByDate(this.deputiesCPIA);
            this.sortDpByLast(this.deputiesCRIAM);
            this.sortDescByDate(this.deputiesCRIAM);
            this.sortDpByLast(this.deputiesCSSS);
            this.sortDescByDate(this.deputiesCSSS);
            this.sortDpByLast(this.deputiesCSCP);
            this.sortDescByDate(this.deputiesCSCP);
            this.sortDpByLast(this.deputiesCT);
            this.sortDescByDate(this.deputiesCT);
            this.sortDpByLast(this.deputiesCPAP);
            this.sortDescByDate(this.deputiesCPAP);
            this.sortDpByLast(this.deputiesCGI);
            this.sortDescByDate(this.deputiesCGI);
            this.sortDpByLast(this.deputiesCES);
            this.sortDescByDate(this.deputiesCES);
          }
          this.status = 'ok';
        }
      },
      err => this.onError(err)
    );

  }

  listarDiputadosEurolat(): void {
    //this.loading = true;
    this._deputyService.listarDiputadosEurolat().subscribe(

      res => {
        if (res.eurolat?.length <= 0) {
          this.status = 'error';
        } else {
          if (res.eurolat?.length === 0) {
            alert('No hay diputados para mostrar');
          } else {
            this.deputiesEuro = res.eurolat;
            console.log(this.deputiesEuro);
            this.sortDpByLast(this.deputiesEuro);
          }
          this.status = 'ok';
        }
      },
      err => this.onError(err)
    );

  }


  sortArray(array: any[]): any[] {
    return array.sort((a, b) => (b.toString()).localeCompare(a.toString()));
  }

  sortDescByDate(arrayObj: Deputies[]): Deputies[] {
    return arrayObj.sort((a, b) => {
      return a?.commissionOrder?.localeCompare(b?.commissionOrder);
    });
  }

  sortDpByLast(arrayObj: Deputies[]): Deputies[] {
    return arrayObj.sort((a, b) => {
      return a?.lastname?.localeCompare(b?.lastname);
    });
  }


  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.loading = false;
  }
}

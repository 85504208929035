import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-asamblea',
  templateUrl: './asamblea.component.html',
  styleUrls: ['./asamblea.component.css']
})
export class AsambleaComponent implements OnInit {

  constructor(protected translate: TranslateService) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
  }

}

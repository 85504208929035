import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'Parlamento';
  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }
  ngOnInit() {
    $(window).on('load', () => {
      $('#myModal').modal('show');
    });
  }

  ngOnDestroy() {
    localStorage.clear();
  } 
  switchLang(lang: string) {
    this.translate.use(lang);
  }
}



import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ParlacenTV } from 'src/app/models/parlacen-tv.model';
import { ParlacenTvService } from 'src/app/services/parlacen-tv.service';
declare var $: any;

@Component({
  selector: 'app-parlacen-tv',
  templateUrl: './parlacen-tv.component.html',
  styleUrls: ['./parlacen-tv.component.css']
})
export class ParlacenTvComponent implements OnInit {
  public videos:    ParlacenTV[];
  public status:    string;
  public loading:   boolean = false;
  public documents: any = {};
  public page:      number = 1;
  public pageSize:  number = 9;

  constructor(
    private _parlacenTvService: ParlacenTvService,
    protected _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.listVideos();
  }

  listVideos(): void {
    this.loading = true;
    this._parlacenTvService.listVideos().subscribe(
      res => {
        if (!res.parlamentTv) {
          this.status = 'error';
        } else {
          if (res.parlamentTv.length === 0) {
            alert('No hay videos para mostrar');
          } else {
            this.videos = res.parlamentTv;
            this.videos = this.sortArray(this.videos);
          }
          this.status = 'ok';
        }
        this.loading = false;
      },
      err => this.onError(err)
    )
  }

  sortArray(arrayObj: any[]): any[] {
    return arrayObj.sort((a, b) =>
      (b.creationDate).localeCompare(a.creationDate)
    );
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.loading = false;
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustUrl(oldURL);
  }

  cleanURLResource(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }
}

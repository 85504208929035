import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NEWS_TYPES } from 'src/app/enums/enums';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/news.service';
declare var $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  public status:    string;
  public news:      News[];
  public loading:   boolean = false;
  public page:      number = 1;
  public pageSize:  number = 6;

  constructor(
    private _newsService: NewsService,
    protected _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.listGeneralNews();
    $('html,body').scrollTop(0);
  } 
  listGeneralNews(): void {
    this.loading = true;
    this._newsService.listNewsByType(NEWS_TYPES.COVID19).subscribe(
      res => {
        if (!res.news) {
          this.status = 'error';
        } else {
          if (res.news.length === 0) {
            alert('No hay noticias para mostrar');
          } else {
            this.news = res.news.filter((noticia: any) => noticia.lang == 'en');
            this.news = this.sortArray(this.news);
          }
          this.status = 'ok';
        }
        this.loading = false;
      },
      err => this.onError(err),
    );
  }

  sortArray(arrayObj: any[]): any[] {
    return arrayObj.sort((a, b) =>
      (b.creationDate + b.creationTime).localeCompare(a.creationDate + a.creationTime)
    );
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.loading = false;
  }

  cleanURLResource(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustUrl(oldURL);
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class PlenaryDocService {
  private url: string;

  constructor(private _http: HttpClient) {
    this.url = GLOBAL.API.url;
  }

  listDocuments: () => Observable<any> = () => {
    let headers = new HttpHeaders().set('Content-Type', 'Application/json');
    return this._http.get(`${this.url}documents`, { headers });
  }

  getDocument: (docId: string) => Observable<any> = docId => {
    let headers = new HttpHeaders().set('Content-Type', 'Application/json');
    return this._http.get(`${this.url}documents/${docId}`, { headers });
  }
}

<div class="container-lg">
    <div class="my-container">
        <div class="row text-center">
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary" mwlCalendarPreviousView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        Anterior
                    </div>
                    <div class="btn btn-outline-secondary" mwlCalendarToday [(viewDate)]="viewDate">
                        Hoy
                    </div>
                    <div class="btn btn-primary" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                        (viewDateChange)="closeOpenMonthViewDay()">
                        Siguiente
                    </div>
                </div>
            </div>
            <div class="col-md-4 month-title">
                <h3>{{ viewDate | calendarDate:(view + 'ViewTitle'):'Es' | uppercase }}</h3>
            </div>
            <div class="col-md-4">
                <div class="btn-group">
                    <div class="btn btn-primary" (click)="setView(CalendarView.Month)"
                        [class.active]="view === CalendarView.Month" >
                        Mes
                    </div>
                    <div class="btn btn-primary" (click)="setView(CalendarView.Week)"
                        [class.active]="view === CalendarView.Week">
                        Semana
                    </div>
                    <div class="btn btn-primary" (click)="setView(CalendarView.Day)"
                        [class.active]="view === CalendarView.Day">
                        Dia
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
                [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)" [locale]="'Es'"
                (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events" [locale]="'Es'"
                (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events" [locale]="'Es'"
                (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
        </div>

        <!-- Everything you see below is just for the demo, you don't need to include it in your app -->

        <br /><br /><br />
        <ng-template #modalContent let-close="close">
            <div class="modal-header">
                <h5 class="modal-title">Eventos</h5>
                <button type="button" class="close" (click)="close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    Evento:
                    <pre>{{ modalData?.event.title }}</pre>
                </div>
                <div>
                    Descripcion:
                    <pre>{{ modalData?.event.description }}</pre>
                </div> 
                <div>
                    Fecha inicio:
                    <input class="form-control" type="datetime-local" mwlFlatpickr [(ngModel)]="modalData?.event.start"
                                [altInput]="true" [convertModelValue]="true" [enableTime]="true"
                                dateFormat="dd-mm-YYYYTH:i" altFormat="j-m-Y H:i" placeholder="Not set"
                                [locale]="Espanol" />
                </div>
                <div>
                    Fecha final:
                    <input class="form-control" type="datetime-local" mwlFlatpickr [(ngModel)]="modalData?.event.end"
                                [altInput]="true" [convertModelValue]="true" [enableTime]="true"
                                dateFormat="dd-mm-YYYYTH:i" altFormat="j-m-Y H:i" placeholder="Not set"
                                [locale]="Espanol" />
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-secondary" (click)="close()">
                    OK
                </button>
            </div>
        </ng-template>
    </div>
</div>

<div class="iota">
   
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb pt-5 pb-4">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">
                            {{ "Convocatoria" | uppercase
                            }}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-body-container">
                <div class="row">
                    
                    <h2 class="section-header w-100">LICITACIÓN PÚBLICA No. 001/2023-2024</h2>
                    <h3>CONVOCA A TODAS LAS PERSONAS JURÍDICAS, NACIONALES O EXTRANJERAS INTERESADAS EN OFERTAR PARA LA: “ADQUISICIÓN DE SEGURO COLECTIVO DE GASTOS MÉDICOS Y SEGURO DE VIDA, PARA EL PARLAMENTO CENTROAMERICANO”
                    </h3>
                    <p>
                        <b>1. ANTECEDENTES:</b>
                        Las personas jurídicas, nacionales o extranjeras interesadas en participar podrán adquirir 
                        los Documentos de Licitación, en forma gratuita, por medio electrónico, descargándolos del 
                        portal electrónico de Parlamento Centroamericano https://parlacen.int/convocatoria. La recepción 
                        de las ofertas se llevará a cabo el 20 de diciembre de 2023, de las 10:00 a 12:00 horas, 
                        Ciudad de Guatemala al correo electrónico seguroparlacen@gmail.com, la apertura de los correos
                         electrónicos se llevará a cabo en la ciudad de Guatemala el día 08 de enero de 2024 a las 10 
                         horas Ciudad de Guatemala. De no realizarse la recepción de la manera indicada, se colocará un
                         aviso en el portal electrónico del Parlamento Centroamericano, con la nueva ubicación. Guatemala, Noviembre de 2023.
                    <p>
                        <a class="nav-link" href="../../../assets/pdf/BASES-LICITACION-2023.pdf" target="_blank">
                            ...Leer BASES DE LICITACIÓN, PARLAMENTO CENTROAMERICANO.</a>
                    </p>
                      
                </div>
            </div>
           
            <div class="section-title-container">
                <h3 class="section-title">
                    {{ "" | translate | uppercase }}
                </h3>
            </div>
            
            <div class="btn-descargar-folleto">
                <div class="row text-center">
                    <div class="col-lg-4">
                      <div class="card">
                          <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                              height="40px">
                          <div class="card-body">
                              <h3 class="card-title">
                                  Convocatoria
                              </h3>
                              <p class="info-text">
                                Bases
                              </p>
                              <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                  <a class="nav-link" href="../../../assets/pdf/BASES-LICITACION-2023.pdf"
                                      target="_blank" download="BASES-LICITACION-2023">
                                      Descargar
                                  </a>
                              </button>
                          </div>
                      </div>
                  </div>
                </div>
            </div>
            
        </div>
    </div> 

</div>

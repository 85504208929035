<div class="boletin-parlamentario">
  <!--  <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="assets/img/parlamentario-anotando.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/parlamentario-anotando.png 1x, assets/img/parlamentario-anotando@2x.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/parlamentario-anotando.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/parlamentario-anotando.png 1x, assets/img/parlamentario-anotando@2x.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/parlamentario-anotando.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/parlamentario-anotando.png 1x, assets/img/parlamentario-anotando@2x.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
        <div class="carousel-text">
            <h3 class="display-title">Nuestro objetivo es la integración regional siendo un órgano de representación
                político democrático.</h3>
        </div>
    </div>

    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb py-5">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform">{{ "Recursos Multimedia" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{ "Boletín Parlamentario" | uppercase }}</a>
                    </li>
                </ol>
            </nav>
            <div class="row d-none">
                <div class="col-lg-12">
                    <div class="input-group">
                        <input type="text" class="form-control" placeholder="Buscar..." aria-label="Buscar"
                            aria-describedby="button-search">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary" type="button" id="button-search">Buscar</button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-body-container">
                <h2 class="info-title">Boletines año 2020</h2>
                <div class="boletines">
                    <div class="row py-5">
                        <div class="col-md-4">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentario.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentario.png 1x, assets/img/perfil-parlamentario@2x.png 2x">
                                <div class="card-body light-container">
                                    <p class="card-text info-text">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                        tempor invidunt ut labore et dolore magna aliquyam.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <p class="card-text info-text">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                        tempor invidunt ut labore et dolore magna aliquyam.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <p class="card-text info-text">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                        tempor invidunt ut labore et dolore magna aliquyam.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-body-container">
                <h2 class="info-title">Boletines año 2019</h2>
                <div class="boletines">
                    <div class="row py-5">
                        <div class="col-md-4">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentario.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentario.png 1x, assets/img/perfil-parlamentario@2x.png 2x">
                                <div class="card-body light-container">
                                    <p class="card-text info-text">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                        tempor invidunt ut labore et dolore magna aliquyam.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <p class="card-text info-text">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                        tempor invidunt ut labore et dolore magna aliquyam.
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <p class="card-text info-text">
                                        Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                                        tempor invidunt ut labore et dolore magna aliquyam.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-body-container">
                <div class="mt-5 pt-5">
                    <h3 class="section-title">{{ "Conocer más" | uppercase }}</h3>
                </div>
                <div class="row conocer-mas text-white">
                    <div class="col-lg-6 text-center align-self-center ti-text">
                        <p class="ti-t">Conozca la Junta Directiva del PARLACEN</p>
                        <button type="button" class="btn btn-primary ti-btn">{{ "Ver Aquí" | uppercase }}</button>
                    </div>
                    <div class="col-lg-6 p-0 ti-image">
                        <img src="assets/img/photo4976974207179139262.png" width="100%" class="ti-img"
                            alt="Diputados del Parlamento Centroamericano" loading="lazy"
                            srcset="assets/img/photo4976974207179139262.png 1x, assets/img/photo4976974207179139262@2x.png 2x">
                    </div>
                </div>
            </div>
        </div>
    </div> -->
  <div class="head-bus">

    <div class="buscador">
      <h2>¿En qué podemos ayudarle?</h2>
      <div class="input-group mb-3 mt-5">
        <input type="text" class="form-control inp-bus" placeholder="Búsqueda de Documentos, Noticias y Parlacen TV"
          aria-label="Username" aria-describedby="basic-addon1" [(ngModel)]="busqueda">
        <span class="input-group-text" id="basic-addon1"><img src="assets/svg/lupa.svg" (click)="listarDiputados()"
            width="20px" alt="Imagen lupa"></span>
      </div>
    </div>

    <div class="resultados">
      <ul>
        <li class="my-3" *ngFor="let item of resultados; let i = index;">
          <img class="img-fluid mx-2" *ngIf="imagen2(item.collection)" src="assets/svg/ResolucionesAzul.svg"
            alt="Ícono de Buscar" width="18px" loading="lazy">
          <img class="img-fluid mx-2" *ngIf="imagen(item.collection)" src="assets/svg/NoticiasAzul.svg"
            alt="Ícono de Buscar" width="18px" loading="lazy">
          <img class="img-fluid mx-2" *ngIf="imagen1(item.collection)" src="assets/svg/DerechaAzul.svg"
            alt="Ícono de Buscar" width="12px" loading="lazy">

          <a style="color: #0F2652 !important;" [href]="direccion(item.collection, item.objectID, item.url)"
            target="_blank">{{item.title}}</a>
        </li>
      </ul>
    </div>


  </div>
  <br><br>
</div>

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NEWS_TYPES } from '../enums/enums';
import { News } from '../models/news.model';
import { GLOBAL } from './global.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class NewsService {
  private url:      string;
  private contType = {
    h: 'Content-Type',
    b: 'application/json',
  }
  private auth = {
    h: 'Authorization',
    b: '',
  }


  constructor(
    private _http: HttpClient,
    private _userService: UserService
  ) {
    this.url = GLOBAL.API.url;
    this.auth.b = _userService.getToken();
  }

  listNews: () => Observable<any> = () => {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}news`, { headers: headers });
  }

  listNewsByType: (type: NEWS_TYPES) => Observable<any> = type => {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}news/type/${type}`, { headers: headers });
  }

  getNews: (newsId: string) => Observable<any> = newsId => {
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b);
    return this._http.get(`${this.url}news/${newsId}`, { headers: headers });
  }

  getNewsForAdmin: () => Observable<any> = () => {
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.get(`${this.url}admin/news`, { headers: headers });
  }

  addNews: (news: News) => Observable<any> = news => {
    let params = JSON.stringify(news);
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}admin/news`, params, { headers: headers });
  }

  deleteNews: (newsId: string) => Observable<any> = newsId => {
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.delete(`${this.url}admin/news/${newsId}`, { headers: headers })
  }

  updateNews: (news: News) => Observable<any> = news => {
    let params = JSON.stringify(news);
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.put(`${this.url}admin/news/${news.id}`, params, { headers: headers });
  }
}

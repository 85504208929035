import { Component, OnInit } from '@angular/core';  
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NEWS_STATUS, NEWS_TYPES } from 'src/app/enums/enums';
import { News } from 'src/app/models/news.model';
import { GeneralFuncsService } from 'src/app/services/general-funcs.service';
import { NewsService } from 'src/app/services/news.service';
declare var $: any;

@Component({
  selector: 'app-noticia',
  templateUrl: './noticia.component.html',
  styleUrls: ['./noticia.component.css'],
  providers: [NewsService]
})
export class NoticiaComponent implements OnInit {
  public news:    News;
  public status:  string;
  public id:      string;
  public loading: boolean = false;
  public enlaces: string[];

  constructor(
    private _newsService: NewsService,
    public _generalFuncService: GeneralFuncsService,
    protected _sanitizer: DomSanitizer,
  ) {
    this.news = new News('', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', NEWS_STATUS.ACTIVE, NEWS_TYPES.GENERAL);
  }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.getNewsToView(this._generalFuncService.getIdOnUrl());
  }

  getNewsToView: (newsId: string) => void = newsId => {
    this.loading = true
    this._newsService.getNews(newsId).subscribe(
      res => {
        if (!res.news) {
          this.status = 'error';
        } else {
          this.status = 'ok';
          this.news = res.news;
          if (res.news.links) {
            this.enlaces = this.news.links.split(';');
          }
        }
        this.loading = false;
      },
      error => this.onError(error)
    );
  }

  onError: (error: any) => void = error => {
    var errorMessage = error;
    this.loading = false;
    if (errorMessage != null) {
      this.status = 'error';
    }
    alert(error.error.message || error.error);
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustUrl(oldURL);
  }
}

<div class="noticias">
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">{{ "News" | uppercase}}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{ "Noticias" | uppercase}}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-body-container">
                <div class="d-flex">
                    <img src="assets/img/Logo.png" width="55.64" alt="Imagen del logo del PARLACEN" height="58">
                    <h3 class="page-title pl-3">{{ "Noticias" | uppercase }}</h3>
                </div>
                <div class="etiquetas py-5 d-none">
                    <div class="d-flex">
                        <button class="btn btn-outline-primary btn-leer-mas-outline" type="button">
                            {{ "Etiquetas" | uppercase }}
                        </button>
                        <button class="btn btn-outline-primary btn-leer-mas-outline ml-4" type="button">
                            {{ "Etiquetas" | uppercase }}
                        </button>
                    </div>
                </div>
                <div class="noticias-cards pt-5 pb-5">
                    <app-cards-display [boundaryLinks]="false" [data]="news" [maxSize]="3" [page]="page"
                        [pageSize]="pageSize" [rotate]="true" size="sm"></app-cards-display>
                </div>
            </div>
        </div>
    </div>
</div>
<app-spinner [loading]="loading"></app-spinner>
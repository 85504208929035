import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global.service';
import { UserService } from './user.service';
import { Member } from '../models/member.model';

@Injectable({
  providedIn: 'root'
})
export class MemberService {
  private url:      string;
  private identity: any;
  private token:    string;
  private contType = {
    h: 'Content-Type',
    b: 'application/json',
  }
  private auth = {
    h: 'Authorization',
    b: '',
  }
  constructor(private _http: HttpClient, private _userService: UserService) {
    this.url = GLOBAL.API.url; 
    this.auth.b = _userService.getToken();
  }

  listMember: () => Observable<any> = () => {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}member`, { headers: headers });
  }
  getMember: (memberId: string) => Observable<any> = memberId => {
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b);
    return this._http.get(`${this.url}admin/member/${memberId}`, { headers: headers });
  } 
}

<div class="noticias-cards pt-5 pb-5">
    <div class="row text-center">
        <div class="col-xl-4 col-md-6 col-sm-12 mt-4"
            *ngFor="let news of data | slice: (page - 1) * pageSize : (page - 1) * pageSize + pageSize">
            <div class="card light-container h-100">
                <img class="card-img-top style-img" [src]="cleanURLResource(news.image)" draggable="true"
                    alt="News image">
                <div class="card-body">
                    <div class="div-padding-title">
                        <h5 class="card-title ti-t">{{ news.title }}</h5>
                    </div>
                    <p *ngIf="news.introduction && news.introduction != ' '" class="card-text info-text">
                        {{ news.introduction }}</p>
                </div>
                <div class="card-footer light-container border-0">
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui mt-2">
                        <a class="nav-link" [routerLink]="['/noticias', news.id]">
                            {{ "Leer Más" | uppercase }}
                        </a>
                    </button>
                </div>
            </div>
        </div>
    </div>
    <ngb-pagination class="d-flex justify-content-center pt-5" [collectionSize]="data?.length" [(page)]="page"
        [boundaryLinks]="boundaryLinks" [pageSize]="pageSize" [size]="size" [maxSize]="maxSize" [rotate]="rotate"
        *ngIf="data && data?.length > pageSize">
    </ngb-pagination>
</div>
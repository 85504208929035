import { Component, OnInit } from '@angular/core';
import { Contact } from 'src/app/models/contact.model';
import { ContactService } from 'src/app/services/contact.service';
declare var $: any;

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  styleUrls: ['./contacto.component.css'],
  providers: [ContactService]
})
export class ContactoComponent implements OnInit {
  contact:    Contact;
  status:     string;
  isSending:  boolean = false;
  recaptcha: any[];
  validated: boolean = false; 

  constructor(private _contactService: ContactService) {
    this.cleanContac()
  }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    
  }

  sendContact(): void {
    if (this.validated) {
      this.isSending = true;
      this._contactService.sendEmail(this.contact).subscribe(
        res => {
          this.isSending = false;
          alert('Mensaje de contacto enviado correctamente');
          this.cleanContac();
        },
        err => {
          this.isSending = false;
          this.onError(err);
        },
      );
    }
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
      console.error(errorMessage);
    }
  }
  resolved(captchaResponse: any[]) {
    this.recaptcha = captchaResponse;
    if (this.recaptcha) {
      this.validated = true;
    }
    console.log("Resolved captcha with response: ", this.recaptcha);
  } 
  cleanContac() {
    this.contact = new Contact('', '', '', '', '', false);
    this.validated = false;
  }
}

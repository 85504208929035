<div class="boletin-parlamentario">
    <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="assets/img/asamblea-parlamentaria.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/asamblea-parlamentaria.png 1x, assets/img/asamblea-parlamentaria@2x.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/asamblea-parlamentaria.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/asamblea-parlamentaria.png 1x, assets/img/asamblea-parlamentaria@2x.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/asamblea-parlamentaria.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/asamblea-parlamentaria.png 1x, assets/img/asamblea-parlamentaria@2x.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
        <div class="carousel-text">
            <h3 class="display-title">
                Asamblea Parlamentaria EuroLatinoamericana<br>
                (EUROLAT)
            </h3>
        </div>
    </div>

    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb py-5">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{ "Eurolat" | uppercase }}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-title-container">
                <h3 class="section-title">{{ "Sobre el EUROLAT" | uppercase }}</h3>
            </div>
            <div class="section-body-container pt-4 pb-3">
                <h2 class="info-title">Composición de EUROLAT</h2>
                <div class="row pt-5 pb-3 px-0">
                    <div class="col-lg-5 px-0">
                        <p class="info-text">
                            EuroLat es una Asamblea Parlamentaria multilateral mixta, compuesta por 150 miembros, 75
                            del Parlamento Europeo y 75 del componente latinoamericano, incluidos Parlatino
                            (Parlamento Latinoamericano), Parlandino (Parlamento Andino), PARLACEN (Parlamento
                            Centroamericano) y Parlasur (Parlamento del Mercosur).
                        </p>
                    </div>
                    <div class="col-lg-2 p-0"></div>
                    <div class="col-lg-5 px-0">
                        <p class="info-text">
                            Dada la existencia de comisiones parlamentarias mixtas UE/Mexico y UE/Chile, los
                            Congresos mexicano y chileno tambièn se encuentran representados. Los miembros de
                            EuroLat se designan de acuerdo con las reglas internas de cada Parlamento, con la
                            finalidad de reflejar el gran numero de grupos politicos y paises representados en cada
                            uno de dichos Parlamentos y Congresos.
                        </p>
                    </div>
                </div>
            </div>
            <div class="section-body-container pb-2">
                <h2 class="info-title">¿Cómo funciona EuroLat?</h2>
                <div class="row pt-5 px-0">
                    <div class="col-lg-5 px-0">
                        <p class="info-text">
                            La estructura de EuroLat es la siguiente:
                        </p>
                        <ul class="info-text circled-list">
                            <li class="pb-2">Asamblea Plenaria</li>
                            <li class="pb-2">Mesa Directiva</li>
                            <li class="pb-2">Comisiones permanentes</li>
                            <li class="pb-2">Grupos de Trabajo Secretaria</li>
                        </ul>
                        <p class="info-text">
                            Dos Co-Presidentes presiden la Asamblea, uno europeo y uno latinoamericano. Los
                            Co-Presidentes junto con los 14 Co-Vicepresidentes (7 por cada lado) componen la Mesa
                            Directiva. Este órgano coordina el trabajo de la Asamblea, supervisa sus actividades y
                            mantiene relaciones con la Cumbre UE-ALC, la Fundación UE-ALC, las conferencias
                            ministeriales y Grupos de Altos Funcionarios y Embajadores.
                        </p>
                        <p class="info-text">
                            Cada órgano de EuroLat tiene el mismo número de miembros de cada componente. La toma de
                            decisiones se basa principalmente en el trabajo de las tres Comisiones Permanentes:
                        </p>
                        <ul class="info-text circled-list">
                            <li class="pb-2">Asuntos Políticos, de Seguridad y de Derechos Humanos</li>
                            <li class="pb-2">Asuntos Económicos, Financieros y Comerciales</li>
                            <li class="pb-2">
                                Asuntos Sociales, Intercambios Humanos, Medio Ambiente, Educacion y Cultura
                            </li>
                            <li class="pb-2">
                                Desarrollo Sostenible, Medio Ambiente, Política Enérgetica, Investigación, Innovación y
                                Tecnología
                            </li>
                        </ul>
                    </div>
                    <div class="col-lg-2 p-0"></div>
                    <div class="col-lg-5 px-0">
                        <p class="info-text">
                            Las propuestas de resolución y las recomendaciones adoptadas por una Comision se examinan y
                            votan en la Asamblea, generalmente por mayoría simple. Declaraciones de EUROLAT son
                            presentadas a nivel de combres de presidentes y ministeriales eurolatinoamericanas con las
                            resoluciones y sugerencias de EUROLAT para impulsar las relaciones biregionales.
                        </p>
                        <p class="info-text">
                            Las Comisiones y la Mesa Directiva se reunen por lo menos dos veces al año. Además un Grupo
                            de Trabajo ad hoc se concentra de forma temporal sobre los aspectos de violencia organizada,
                            seguridad y terrorismo. En EUROLAT sesionan también los foros eurolatinoamericanos de la
                            sociedad civil y de la mujer, los que son copresididos cada uno como las comisiones y el
                            grupo de trabajo por una o un eurodiputado y y una o un diputado latinoamericano,
                            manteniendo una representatividad proporcional de los diferentes parlamentos regionales.
                        </p>
                    </div>
                </div>
            </div>
            <div class="section-title-container pb-1 d-none">
                <h3 class="section-title">{{ "Grupo Parlamentario de Alianza Democrática" | uppercase }}</h3>
            </div>
            <div class="section-body-container  d-none">
                <div class="light-container p-5">
                    <div class="row py-4 d-responsive">
                        <div class="col-lg-2">
                            <p class="group-title">
                                No.
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <p class="group-title">
                                Nombre
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="group-title">
                                Cargo
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="group-title">
                                País
                            </p>
                        </div>
                    </div>
                    <div class="row pb-4">
                        <div class="col-lg-2">
                            <p class="info-text">
                                0000
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <p class="info-text">
                                Título Nombre Apellido
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Presidente
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Guatemala
                            </p>
                        </div>
                    </div>
                    <div class="row pb-4">
                        <div class="col-lg-2">
                            <p class="info-text">
                                0000
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <p class="info-text">
                                Título Nombre Apellido
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Presidente
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Honduras
                            </p>
                        </div>
                    </div>
                    <div class="row pb-4">
                        <div class="col-lg-2">
                            <p class="info-text">
                                0000
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <p class="info-text">
                                Título Nombre Apellido
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Presidente
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                El Salvador
                            </p>
                        </div>
                    </div>
                    <div class="row pb-4">
                        <div class="col-lg-2">
                            <p class="info-text">
                                0000
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <p class="info-text">
                                Título Nombre Apellido
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Presidente
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Panamá
                            </p>
                        </div>
                    </div>
                    <div class="row pb-4">
                        <div class="col-lg-2">
                            <p class="info-text">
                                0000
                            </p>
                        </div>
                        <div class="col-lg-4">
                            <p class="info-text">
                                Título Nombre Apellido
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                Presidente
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                República Dominicana
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-title-container mt-5 pt-5">
                <h3 class="section-title">{{ "Conocer más" | uppercase }}</h3>
            </div>
            <div class="section-body-container">
                <div class="row conocer-mas text-white">
                    <div class="col-lg-6 text-center align-self-center ti-text">
                        <p class="ti-t">Conozca más sobre el Parlamento Europeo</p>
                        <button type="button" class="btn btn-primary ti-btn">{{ "Aquí" | uppercase }}</button>
                    </div>
                    <div class="col-lg-6 p-0 ti-image">
                        <img src="assets/img/JuntaDirectivaEnPlenario.jpg" width="100%" class="ti-img"
                            alt="Diputados del Parlamento Centroamericano" loading="lazy"
                            srcset="assets/img/JuntaDirectivaEnPlenario.jpg 1x, assets/img/JuntaDirectivaEnPlenario.jpg 2x">
                    </div>
                </div>
            </div>
        </div>
    </div>

/* export const GLOBAL = {
    API: {
        url: 'https://us-central1-parlacen-back.cloudfunctions.net/Api/api/'
    },
    firebaseConfig: {
        apiKey:             'AIzaSyBwT2KwsDstPJWh-ixbdfJ1_DHZbr6QC3Y',
        authDomain:         'parlacen-back.firebaseapp.com',
        databaseURL:        'https://parlacen-back.firebaseio.com',
        projectId:          'parlacen-back',
        storageBucket:      'parlacen-back.appspot.com',
        messagingSenderId:  '630803623653',
        appId:              '1:630803623653:web:8eafb35744ee099a136e0c',
        measurementId:      'G-2F31BFGB55'
    },
}; */

export const GLOBAL = {
    API: {
        url: 'https://us-central1-parlacen-back-int.cloudfunctions.net/Api/api/'
    },
    firebaseConfig: {
        apiKey: "AIzaSyA471kqY21BfykRBEOB026FIuSVc05eD_U",
        authDomain: "parlacen-back-int.firebaseapp.com",
        projectId: "parlacen-back-int",
        storageBucket: "parlacen-back-int.appspot.com",
        messagingSenderId: "1079701252953",
        appId: "1:1079701252953:web:849cf605596a28780f7a48"
    },
};


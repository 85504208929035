<div class="historia">
    <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="assets/img/Foto-01.jpg" class="d-block w-100 carousel-img"
                    srcset="assets/img/Foto-01.jpg 1x, assets/img/Foto-01.jpg 2x" data-interval="10000" alt="Historia"
                    loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/Foto-02.jpg" class="d-block w-100 carousel-img"
                    srcset="assets/img/Foto-02.jpg 1x, assets/img/Foto-02.jpg 2x" data-interval="10000" alt="Historia"
                    loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/Foto-03.jpg" class="d-block w-100 carousel-img"
                    srcset="assets/img/Foto-03.jpg 1x, assets/img/Foto-03.jpg 2x" data-interval="10000" alt="Historia"
                    loading="lazy">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
        <div class="carousel-text">
            <h3 class="display-title">{{"history_of_PARLACEN.carousel.title"| translate}}</h3>
            <p class="display-text">
                {{"history_of_PARLACEN.carousel.text"| translate}}
            </p>
        </div>
    </div>
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb" *ngIf="bodySize >= 991">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">{{"history_of_PARLACEN.nav.home"| translate |uppercase}}</li>
                    <li class="breadcrumb-item quote-transform">{{"history_of_PARLACEN.nav.about"| translate
                        |uppercase}}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{"history_of_PARLACEN.nav.history"| translate
                            |uppercase}}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-title-container">
                <h3 class="section-title">{{"history_of_PARLACEN.history_of_PARLACEN.title"| translate |uppercase}}</h3>
            </div>
            <div class="section-body-container">
                <div class="row">
                    <div class="col-lg-6 p-0">
                        <h2 class="section-header">
                            {{"history_of_PARLACEN.history_of_PARLACEN.header"| translate }} <span
                                class="d-responsive-lg">{{"history_of_PARLACEN.history_of_PARLACEN.header1"| translate
                                }}</span>
                        </h2>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-lg-6 p-0">
                        <p class="info-text pr-2-responsive">
                            {{"history_of_PARLACEN.history_of_PARLACEN.paragraph_1"| translate }}
                        </p>
                    </div>
                    <div class="col-lg-6 p-0">
                        <p class="info-text pr-2-responsive">
                            {{"history_of_PARLACEN.history_of_PARLACEN.paragraph_2"| translate }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="conoce-mas">
        <div class="container-lg">
            <div class="my-container d-flex pb-0">
                <div class="row pb-5">
                    <div class="col-lg-6 row">
                        <div class="col-lg-12 mb-5">
                            <div class="section-title-container">
                                <h3 class="section-title text-white">{{"history_of_PARLACEN.know_more.title"| translate
                                    |uppercase}}</h3>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="px-3">
                                <h3 class="text-over-img">
                                    {{"history_of_PARLACEN.know_more.head"| translate }}
                                </h3>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="px-3 pt-3">
                                <button type="button" class="btn btn-dark ver-aqui buttonHome">
                                    <a href="assets/pdf/Historia del Parlamento Centroamericano.pdf">
                                        {{"history_of_PARLACEN.know_more.btn"| translate |uppercase }}
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-lg">
        <div class="my-container d-none">
            <div class="section-title-container pt-5">
                <h3 class="section-title pt-5">{{"history_of_PARLACEN.know_more_about_PARLACEN.title"| translate
                    |uppercase}}</h3>
            </div>
            <div class="section-body-container">
                <div class="row conocer-mas-parlacen text-white">
                    <div class="col-lg-6 text-center align-self-center ti-text">
                        <p class="ti-t">{{"history_of_PARLACEN.know_more_about_PARLACEN.ti_text_1"| translate}}</p>
                        <<<<<<< HEAD <button type="button" class="btn btn-primary ti-btn" routerLink="/acerca-de">
                            {{"history_of_PARLACEN.know_more_about_PARLACEN.ti_btn"| translate
                            |uppercase}}</button>
                            =======
                            <button type="button" class="btn btn-primary prox-actividades-btn buttonHome"
                                routerLink="/acerca-de">{{"history_of_PARLACEN.know_more_about_PARLACEN.ti_btn"|
                                translate |uppercase}}</button>
                            >>>>>>> f659d21acebfcd56131348bf4da23efb9807ed02
                    </div>
                    <div class="col-lg-6 p-0 ti-image">
                        <img src="assets/img/historia-del-parlacen-1.png" width="100%" class="ti-img"
                            alt="Diputados del Parlamento Centroamericano" loading="lazy"
                            srcset="assets/img/historia-del-parlacen-1.png 1x, assets/img/historia-del-parlacen-1.png 2x">
                    </div>
                </div>
            </div>
        </div>
        <div class="my-container">
            <div class="fechas-historicas">
                <div class="section-body-container">
                    <div class="accordion" id="accordion">
                        <div class="card atr-h">
                            <div class="card-header" id="headingOne">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left dropdown-btn-atr" type="button"
                                        data-toggle="collapse" data-target="#collapseOne" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        {{"history_of_PARLACEN.accordions.jan_1983.title"| translate |uppercase}}
                                        <img src="assets/svg/DropdownCeleste.svg" class="float-right mt-2"
                                            alt="Ícono de desplegar" width="25" loading="lazy">
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" class="collapse light-container" aria-labelledby="headingOne"
                                data-parent="#accordion">
                                <div class="card-body m-0 px-5">
                                    <div class="row">
                                        <div class="col-lg-6 pb-4">
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.parragraph_1"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.parragraph_2"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.parragraph_3"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.parragraph_4"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.parragraph_5"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.parragraph_6"|
                                                translate }}
                                            </p>
                                            <blockquote>
                                                <p class="info-text">
                                                    {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.quote_1"|
                                                    translate }}
                                                </p>
                                                <p class="info-text">
                                                    {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.quote_2"|
                                                    translate }}
                                                </p>
                                            </blockquote>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.left.parragraph_7"|
                                                translate }}
                                            </p>
                                        </div>
                                        <div class="col-lg-6 pb-4">
                                            <blockquote>
                                                <p class="info-text">
                                                    {{"history_of_PARLACEN.accordions.jan_1983.collapse.right.quote_1"|
                                                    translate }}
                                                </p>
                                            </blockquote>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.right.parragraph_1"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.right.parragraph_2"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.right.parragraph_3"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.right.parragraph_4"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.right.parragraph_5"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.jan_1983.collapse.right.parragraph_6"|
                                                translate }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion pt-4" id="accordion2">
                        <div class="card atr-h">
                            <div class="card-header" id="headingTwo">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left dropdown-btn-atr" type="button"
                                        data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true"
                                        aria-controls="collapseTwo">
                                        {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.title"| translate
                                        |uppercase}}
                                        <img src="assets/svg/DropdownCeleste.svg" class="float-right mt-2"
                                            alt="Ícono de desplegar" width="25" loading="lazy">
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse light-container" aria-labelledby="headingTwo"
                                data-parent="#accordion">
                                <div class="card-body m-0 px-5">
                                    <div class="row">
                                        <div class="col-lg-6 pb-4">
                                            <p class="info-subtitle">
                                                {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.left.sub_title"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.left.parragraph_1"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.left.parragraph_2"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.left.parragraph_3"|
                                                translate }}
                                            </p>
                                        </div>
                                        <div class="col-lg-6 pb-4">
                                            <p class="info-subtitle">
                                                {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.right.sub_title"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.right.parragraph_1"|
                                                translate }}
                                            </p>
                                            <p class="info-subtitle">
                                                {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.right.list_title"|
                                                translate }}
                                            </p>
                                            <ul class="info-text circled-list">
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.right.list_elem_1"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.right.list_elem_2"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.right.list_elem_3"|
                                                    translate }}
                                                </li>
                                                <li>
                                                    {{"history_of_PARLACEN.accordions.first_quinquennium_1991-1996.accordion.right.list_elem_4"|
                                                    translate }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion pt-4" id="accordion3">
                        <div class="card atr-h">
                            <div class="card-header" id="headingThree">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left dropdown-btn-atr" type="button"
                                        data-toggle="collapse" data-target="#collapseThree" aria-expanded="true"
                                        aria-controls="collapseThree">
                                        {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.title"|
                                        translate |uppercase}}
                                        <img src="assets/svg/DropdownCeleste.svg" class="float-right mt-2"
                                            alt="Ícono de desplegar" width="25" loading="lazy">
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse light-container" aria-labelledby="headingThree"
                                data-parent="#accordion">
                                <div class="card-body m-0 px-5">
                                    <div class="row">
                                        <div class="col-lg-6 pb-4">
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.right.parragraph_1"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.right.parragraph_2"|
                                                translate }}
                                            </p><br>
                                            <p class="info-subtitle">
                                                {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.right.sub_title"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                <span class="content-text">
                                                    {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.right.parragraph_3"|
                                                    translate }}
                                                </span>
                                                {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.right.parragraph_4"|
                                                translate }}

                                            </p>
                                            <p class="info-text">
                                                <span class="content-text">
                                                    {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.left.parragraph_1"|
                                                    translate }}
                                                </span>
                                                {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.left.parragraph_2"|
                                                translate }}

                                            </p>
                                        </div>
                                        <div class="col-lg-6 pb-4 d-responsive">
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.left.parragraph_3"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.second_quinquennium_1996-2001.collapse.left.parragraph_4"|
                                                translate }}
                                            </p>
                                            <div class="divider p-4">
                                                <hr>
                                            </div>
                                            <p class="info-text">
                                                Nacional –PPN-, de Nicaragua; Partido Revolucionario Democrático –PRD-,
                                                Partido Democracia Cristiana –PDC-, PartidoSolidaridad –PS-, Partido
                                                Molirena, Partido Arnulfista, Partido Cambio Democrático –PCD-, de
                                                Panamá y Partido Revolucionario Dominicano –PRD-, Concertación
                                                Democrática –CD-, Partido de la Liberación Dominicana –PLD-, Partido
                                                UnidadDemocrática –PUD-. Partido Liberal –PL-. Y Unión por la Democracia
                                                de República Dominicana.
                                            </p>
                                            <p class="info-text">
                                                <span class="content-text">
                                                    Grupo Parlamentario de Izquierda -PI-, integrado por:
                                                </span>
                                                el Partido Frente Democrático Nueva Guatemala, Partido Frente Farabundo
                                                Martí -FMLN-, de El Salvador y el Frente Sandinista de Liberación
                                                Nacional-FSLN, de Nicaragua.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion pt-4" id="accordion4">
                        <div class="card atr-h">
                            <div class="card-header" id="headingFour">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left dropdown-btn-atr" type="button"
                                        data-toggle="collapse" data-target="#collapseFour" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        {{"history_of_PARLACEN.accordions.third_quinquennium_2001-2006.title"| translate
                                        |uppercase}}
                                        <img src="assets/svg/DropdownCeleste.svg" class="float-right mt-2"
                                            alt="Ícono de desplegar" width="25" loading="lazy">
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" class="collapse light-container" aria-labelledby="headingFour"
                                data-parent="#accordion">
                                <div class="card-body m-0 px-5">
                                    <div class="row">
                                        <div class="col-lg-6 pb-4">
                                            <p class="info-subtitle">
                                                {{"history_of_PARLACEN.accordions.third_quinquennium_2001-2006.collapse.right.sub_title"|
                                                translate }}

                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.third_quinquennium_2001-2006.collapse.right.parragraph_1"|
                                                translate }}
                                            </p>
                                        </div>
                                        <div class="col-lg-6 pb-4">
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.third_quinquennium_2001-2006.collapse.left.parragraph_1"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.third_quinquennium_2001-2006.collapse.left.parragraph_2"|
                                                translate }}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion pt-4" id="accordion4">
                        <div class="card atr-h">
                            <div class="card-header" id="headingFive">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left dropdown-btn-atr" type="button"
                                        data-toggle="collapse" data-target="#collapseFive" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.title"|
                                        translate |uppercase }}
                                        <img src="assets/svg/DropdownCeleste.svg" class="float-right mt-2"
                                            alt="Ícono de desplegar" width="25" loading="lazy">
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFive" class="collapse light-container" aria-labelledby="headingFive"
                                data-parent="#accordion">
                                <div class="card-body m-0 px-5">
                                    <div class="row">
                                        <div class="col-lg-6 pb-4">
                                            <p class="info-subtitle">
                                                {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.right.sub_title_1"|
                                                translate }}
                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.right.parragraph_1"|
                                                translate }}
                                            </p>
                                            <p class="info-subtitle">
                                                {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.right.sub_title_2"|
                                                translate }}

                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.right.parragraph_2"|
                                                translate }}

                                            </p>
                                            <p class="info-subtitle">
                                                {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.right.sub_title_3"|
                                                translate }}

                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.right.parragraph_3"|
                                                translate }}

                                            </p>
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.right.parragraph_4"|
                                                translate }}

                                            </p>
                                        </div>
                                        <div class="col-lg-6 pb-4">
                                            <p class="info-text">
                                                {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.parragraph_1"|
                                                translate }}

                                            </p>
                                            <ol class="info-text">
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_1"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_2"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_3"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_4"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_5"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_6"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_7"|
                                                    translate }}
                                                </li>
                                                <li class="pb-2">
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_8"|
                                                    translate }}
                                                </li>
                                                <li>
                                                    {{"history_of_PARLACEN.accordions.fourth_quinquennium_2006-2012.collapse.left.list_elem_9"|
                                                    translate }}
                                                </li>
                                            </ol>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="accordion pt-4" id="accordion4">
                        <div class="card atr-h">
                            <div class="card-header" id="headingFive">
                                <h2 class="mb-0">
                                    <button class="btn btn-link btn-block text-left dropdown-btn-atr" type="button"
                                        data-toggle="collapse" data-target="#collapseSix" aria-expanded="true"
                                        aria-controls="collapseOne">
                                        {{"history_of_PARLACEN.accordions.parliamentary_periods.title"| translate
                                        |uppercase}}
                                        <img src="assets/svg/DropdownCeleste.svg" class="float-right mt-2"
                                            alt="Ícono de desplegar" width="25" loading="lazy">
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseSix" class="collapse light-container" aria-labelledby="headingFive"
                                data-parent="#accordion">
                                <div class="card-body m-0 px-5">

                                    <div class="row pt-1 pb-4">
                                        <div class="col-lg-4 pb-4">
                                            <h5 class="info-title">
                                                {{"history_of_PARLACEN.accordions.parliamentary_periods.collapse.right.title"|
                                                translate }}
                                            </h5>
                                            <p class="info-text pt-3 pb-4">
                                                {{"history_of_PARLACEN.accordions.parliamentary_periods.collapse.right.parragraph_1"|
                                                translate }}
                                            </p>
                                            <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                                <a class="nav-link" href="assets/pdf/Periodos Parlamentarios.pdf"
                                                    target="blank">
                                                    {{"history_of_PARLACEN.accordions.parliamentary_periods.collapse.right.btn"|
                                                    translate |uppercase}}
                                                </a>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="composicion">
                <div class="section-body-container">
                    <div class="row">
                        <div class="col-sm-6 align-self-center content">
                            <h2 class="info-title w-100 p-0">
                                {{"history_of_PARLACEN.composition.title"| translate }}
                            </h2>
                            <p class="content-text">
                                {{"history_of_PARLACEN.composition.parragraph_1"| translate }}

                            </p>
                        </div>
                        <div class="col-sm-6">
                            <img src="assets/img/Parlacen-asamblea.png" alt="Asamblea PARLACEN" width="100%"
                                srcset="assets/img/Parlacen-asamblea.png 1x, assets/img/Parlacen-asamblea@2x.png 2x">
                        </div>
                    </div>
                </div>
            </div>
            <div class="diputados-centroamerica-91">
                <div class="section-title-container">
                    <h3 class="section-title">
                        {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.title"| translate |uppercase
                        }}</h3>
                </div>
                <div class="section-body-container">
                    <div class="light-container">
                        <div class="row px-5 pt-5">
                            <div class="col-md-3">
                                <p class="group-title">
                                    {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.table.headers.country"|
                                    translate }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="group-title">
                                    {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.table.headers.total"|
                                    translate }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="group-title">
                                    {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.table.headers.political_parties"|
                                    translate }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="group-title">
                                    {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.table.headers.independent"|
                                    translate }}

                                </p>
                            </div>
                        </div>
                        <div class="row px-5 pt-4 pb-3">
                            <div class="col-md-3">
                                <p class="info-text">
                                    Guatemala
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    22
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    UCN 6<br><br>
                                    MAS 5<br><br>
                                    DCG 6<br><br>
                                    PAN 4<br><br>
                                    MLN 1<br><br>
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    Guatemala
                                </p>
                            </div>
                        </div>
                        <div class="row px-5 pt-4 pb-3">
                            <div class="col-md-3">
                                <p class="info-text">
                                    Honduras
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    22
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    PN 12<br><br>
                                    PL 8<br><br>
                                    DC 1<br><br>
                                    PINU 1<br><br>
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">

                                </p>
                            </div>
                        </div>
                        <div class="row px-5 pt-4 pb-3">
                            <div class="col-md-3">
                                <p class="info-text">
                                    El Salvador
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    22
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    ARENA 11<br><br>
                                    PDC 4<br><br>
                                    PCN 2<br><br>
                                    CD 2<br><br>
                                    MAC 1<br><br>
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">

                                </p>
                            </div>
                        </div>
                        <div class="row px-5 pt-4 pb-5">
                            <div class="col-md-3">
                                <p class="info-text">
                                    Nicaragua
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    2
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    FSLN 2
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="row py-5">
                        <div class="col-sm-6 py-5">
                            <img src="assets/img/parlamentarias.png" alt="Parlamentarias" width="100%"
                                srcset="assets/img/parlamentarias.png 1x, assets/img/parlamentarias@2x.png 2x">
                        </div>
                        <div class="col-sm-6 align-self-center content py-5">
                            <p class="content-text">
                                {{"history_of_PARLACEN.composition.parragraph_2"| translate }}

                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="suscripcion-panama-93">
                <div class="section-title-container">
                    <h3 class="section-title">
                        {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.title"| translate |uppercase
                        }}</h3>
                </div>
                <div class="section-body-container">
                    <div class="light-container">
                        <div class="row px-5 pt-5 d-responsive">
                            <div class="col-md-3">
                                <p class="group-title">
                                    {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.table.headers.country"|
                                    translate }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="group-title">
                                    {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.table.headers.total"|
                                    translate }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="group-title">
                                    {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.table.headers.political_parties"|
                                    translate }}
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="group-title">
                                    {{"history_of_PARLACEN.composition.deputies_in_central_america_1991.table.headers.independent"|
                                    translate }}

                                </p>
                            </div>
                        </div>
                        <div class="row px-5 pt-4 pb-3">
                            <div class="col-md-3">
                                <p class="info-text">
                                    Panamá
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    20
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    PRD 10<br><br>
                                    DC 2<br><br>
                                    PE 1<br><br>
                                    LR<br><br>
                                    S 4<br><br>
                                </p>
                            </div>
                            <div class="col-md-3">
                                <p class="info-text">
                                    2
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="my-container">
            <div class="section-title-container pt-5">
                <h3 class="section-title pt-5">
                    {{"history_of_PARLACEN.know_more_about_PARLACEN.title"|translate|uppercase}}
                </h3>
            </div>
            <div class="section-body-container">
                <div class="row conocer-mas-parlacen text-white">
                    <div class="col-lg-6 text-center align-self-center ti-text">
                        <p class="ti-t">{{"history_of_PARLACEN.know_more_about_PARLACEN.ti_text_2"| translate }}</p>
                        <button type="button" class="btn btn-primary ti-btn" routerLink="/resoluciones">
                            {{"history_of_PARLACEN.know_more_about_PARLACEN.ti_btn"|translate|uppercase}}
                        </button>
                    </div>
                    <div class="col-lg-6 p-0 ti-image">
                        <img src="assets/img/historia-del-parlacen-2.png" width="100%" class="ti-img"
                            alt="Diputados del Parlamento Centroamericano" loading="lazy"
                            srcset="assets/img/historia-del-parlacen-2.png 1x, assets/img/historia-del-parlacen-2.png 2x">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-historia',
  templateUrl: './historia.component.html',
  styleUrls: ['./historia.component.css']
})
export class HistoriaComponent implements OnInit, OnDestroy {
  public bodySize: number = 0;

  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    this.bodySize = window.screen.width;
    window.addEventListener("resize", this.onResize);
    $('html,body').scrollTop(0);
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.onResize);
  }

  onResize(): void {
    this.bodySize = window.screen.width;
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }

}

import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { PLENARY_DOCS_TYPES } from 'src/app/enums/enums';
import { PlenaryDoc } from 'src/app/models/plenary-documents.model';
import { PlenaryDocService } from 'src/app/services/plenary-doc.service';
declare var $: any;

@Component({
  selector: 'app-plenaria',
  templateUrl: './plenaria.component.html',
  styleUrls: ['./plenaria.component.css']
})
export class PlenariaComponent implements OnInit {
  public plenaryDocs: PlenaryDoc[];
  public status:      string;
  public loading:     boolean = false;
  public documents:   any = {}
  public filter:      PLENARY_DOCS_TYPES = PLENARY_DOCS_TYPES.ANY;
  public page:        number = 1;
  public pageSize:    number = 3;
  public docTypes:    any[] = [
    PLENARY_DOCS_TYPES.PRONOUNCEMENTS,
    PLENARY_DOCS_TYPES.RESOLUTIONS,
    PLENARY_DOCS_TYPES.DECLARATIONS,
  ]


  constructor(private _plenaryDocService: PlenaryDocService, protected _sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.listarDocumentos();
  }

  listarDocumentos(): void {
    this.loading = true;
    this._plenaryDocService.listDocuments().subscribe(
      res => {
        if (!res.documents) {
          this.status = 'error';
        } else {
          if (res.documents.length === 0) {
            alert('No hay documentos para mostrar');
          } else {
            this.plenaryDocs = res.documents;
            switch (this.filter) {
              case PLENARY_DOCS_TYPES.ANY:
                this.filterForAll();
                break;
              case PLENARY_DOCS_TYPES.DECLARATIONS:
                this.filterForDeclarations();
                break;
              case PLENARY_DOCS_TYPES.RESOLUTIONS:
                this.filterForResolutions();
                break;
              case PLENARY_DOCS_TYPES.PRONOUNCEMENTS:
                this.filterForPronouncements();
                break;
            }
          }
          this.status = 'ok';
        }
        this.loading = false;
      },
      err => this.onError(err)
    );
  }

  filterForAll(): void {
    if (this.plenaryDocs?.length) {
      this.documents = [];
      this.plenaryDocs.forEach(doc => {
        if (this.docTypes.includes(doc.type)) {
          this.insertToItYear(doc);
        }
      });
    }
    $('#resoluciones').css({ 'font-size': '16px' });
    $('#declaraciones').css({ 'font-size': '16px' });
    $('#pronunciamientos').css({ 'font-size': '16px' });
  }

  filterForResolutions(): void {
    if (this.plenaryDocs?.length) {
      this.documents = [];
      this.plenaryDocs.forEach(doc => {
        if (doc.type == PLENARY_DOCS_TYPES.RESOLUTIONS) {
          this.insertToItYear(doc);
        }
      });
    }
    $('#resoluciones').css({ 'font-size': '22px' });
    $('#declaraciones').css({ 'font-size': '16px' });
    $('#pronunciamientos').css({ 'font-size': '16px' });
  }

  filterForDeclarations(): void {
    if (this.plenaryDocs.length) {
      this.documents = [];
      this.plenaryDocs.forEach(doc => {
        if (doc.type == PLENARY_DOCS_TYPES.DECLARATIONS) {
          this.insertToItYear(doc);
        }
      });
    }
    $('#resoluciones').css({ 'font-size': '16px' });
    $('#declaraciones').css({ 'font-size': '22px' });
    $('#pronunciamientos').css({ 'font-size': '16px' });
  }

  filterForPronouncements(): void {
    if (this.plenaryDocs.length) {
      this.documents = [];
      this.plenaryDocs.forEach(doc => {
        if (doc.type == PLENARY_DOCS_TYPES.PRONOUNCEMENTS) {
          this.insertToItYear(doc);
        }
      });
    }
    $('#resoluciones').css({ 'font-size': '16px' });
    $('#declaraciones').css({ 'font-size': '16px' });
    $('#pronunciamientos').css({ 'font-size': '22px' });
  }

  insertToItYear(document: PlenaryDoc): void {
    if (!this.documents[document.year]) {
      this.documents[document.year] = [];
    }
    this.documents[document.year] = [...this.documents[document.year], document];
  }

  getObjectKeys(object: any): string[] {
    let objectKeys = Object.keys(object);
    return this.sortArray(objectKeys)
  }

  sortArray(array: any[]): any[] {
    return array.sort((a, b) => (b.toString()).localeCompare(a.toString()));
  }
  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.loading = false;
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustUrl(oldURL);
  }
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-elecciones',
  templateUrl: './elecciones.component.html',
  styleUrls: ['./elecciones.component.css']
})
export class EleccionesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}

import { AGEND_STATUS } from "../enums/enums";

export class Agend {
  constructor(
    public id?:           string,
    public start?:        Date,
    public end?:          Date,
    public title?:        string,
    public color?:        any,
    public description?:  string, 
    public actions?:      any,
    public allDay?:       boolean,
    public cssClass?:     string,
    public status?:       AGEND_STATUS,
    public resizable?:    any,
    public draggable?:     boolean,
  ) { }
}
import { Component, OnInit } from '@angular/core';
import { Deputies } from 'src/app/models/deputy.model';
import { DiputiesService } from 'src/app/services/diputies.service';
declare var $: any;

@Component({
  selector: 'app-diputados',
  templateUrl: './diputados.component.html',
  styleUrls: ['./diputados.component.css']
})
export class DiputadosComponent implements OnInit {
  public deputies: Deputies[] = [];
  public deputiesSL: Deputies[] = [];
  public deputiesGT: Deputies[] = [];
  public deputiesHN: Deputies[] = [];
  public deputiesNI: Deputies[] = [];
  public deputiesPN: Deputies[] = [];
  public deputiesRD: Deputies[] = [];
  public deputy: Deputies;
  public status: string;

  constructor(
    private _deputyService: DiputiesService
  ) { }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.listarDiputados();

  }


  listarDiputados(): void {
    //this.loading = true;
    this._deputyService.listarDiputadosBancada().subscribe(

      res => {
        if (res.benchDeputies?.length <= 0) {
          this.status = 'error';
        } else {
          if (res.benchDeputies?.length === 0) {
            alert('No hay diputados para mostrar');
          } else {
            this.deputiesGT = res.benchDeputies[0].deputies;
            this.deputiesSL = res.benchDeputies[1].deputies;
            this.deputiesHN = res.benchDeputies[2].deputies;
            this.deputiesNI = res.benchDeputies[3].deputies;
            this.deputiesPN = res.benchDeputies[4].deputies;
            this.deputiesRD = res.benchDeputies[5].deputies;
            this.sortDescByDate(this.deputiesSL);
            this.sortDescByDate(this.deputiesGT);
            this.sortDescByDate(this.deputiesHN);
            this.sortDescByDate(this.deputiesNI);
            this.sortDescByDate(this.deputiesPN);
            this.sortDescByDate(this.deputiesRD);
            /* this.nosedos = this.deputy.parliamentaryGroups; */
          }
          this.status = 'ok';
        }
      },
      err => this.onError(err)
    );

  }

  sortDescByDate(arrayObj: Deputies[]): Deputies[] {
    return arrayObj.sort((b, a) => {
      return b?.lastname?.localeCompare(a?.lastname);
    });
  }
  onError(error: any): void {
    let errorMessage = error;
    alert("Error al ejecutar la acción");
    if (errorMessage != null) {
      this.status = 'error';
    }
    /* this.isSubmitting = false;
    this.isDeleting = false;
    this.isUpdating = false; */
  }

}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class ParlacenTvService {
  private url: string;

  constructor(private _http: HttpClient) {
    this.url = GLOBAL.API.url;
  }

  listVideos: () => Observable<any> = () => {
    let headers = new HttpHeaders().set('Content-Type', 'Application/json');
    return this._http.get(`${this.url}parlacen-tv`, { headers });
  }

  getVideo: (palacenTvId: string) => Observable<any> = palacenTvId => {
    let headers = new HttpHeaders().set('Content-Type', 'Application/json');
    return this._http.get(`${this.url}parlacen-tv/${palacenTvId}`, { headers });
  }
}

<div class="noticia">
    <div class="container-lg">
        <div class="my-container">
            <div class="m-5 p-5 space" *ngIf="loading || !news.id">

            </div>
            <div class="card">
                <h5 class="card-title info-title pt-4 px-1-responsive pb-0">
                    {{ news.title }}
                </h5>
                <p class="px-1-responsive py-0 my-0 info-text w-100" *ngIf="news.author">
                    <span class="text-muted">
                        Publicado por: {{ news.author }}
                    </span>
                </p>
                <p class="px-1-responsive pb-5 mb-0 info-text w-100" *ngIf="news.creationDate">
                    <small class="text-muted">
                        {{ news.creationDate }} {{ news.creationTime }} GTM
                    </small>
                </p>
                <img class="card-img-top" [src]="news.image" draggable="true" alt="Imagen Principal de Noticia"
                    *ngIf="news.image">
                <p class="info-text text-center">
                    <small class="text-muted" *ngIf="news.imgCredits">
                        Créditos: {{ news.imgCredits }}
                    </small>
                </p>
                <div class="card-body px-2-responsive" *ngIf="news.text && news.text != ' '">
                    <p class="card-text info-text pt-5 mt-2 px-2-responsive">
                        <span [innerText]="news.text"></span>
                    </p>
                    <br>
                    <br>
                </div>
                <img class="card-img" [src]="news.image1" draggable="true" alt="Imagen de Noticia" *ngIf="news.image1">
                <p class="info-text text-center">
                    <small class="text-muted" *ngIf="news.imgCredits1">
                        Créditos: {{ news.imgCredits1 }}
                    </small>
                </p>
                <div class="card-body px-2-responsive" *ngIf="news.text1 && news.text1 != ' '">
                    <p class="card-text info-text pt-3 px-2-responsive">
                        <span [innerText]="news.text1"></span>
                    </p>
                    <br>
                    <br>
                </div>
                <img class="card-img" [src]="news.image2" draggable="true" alt="Imagen de Noticia" *ngIf="news.image2">
                <p class="info-text text-center">
                    <small class="text-muted" *ngIf="news.imgCredits2">
                        Créditos: {{ news.imgCredits2 }}
                    </small>
                </p>
                <div class="card-body px-2-responsive" *ngIf="news.text2 && news.text2 != ' '">
                    <p class="card-text info-text pt-3 px-2-responsive">
                        <span [innerText]="news.text2"></span>
                    </p>
                    <br>
                    <br>
                </div>
                <img class="card-img" [src]="news.image3" draggable="true" alt="Imagen de Noticia" *ngIf="news.image3">
                <p class="info-text text-center">
                    <small class="text-muted" *ngIf="news.imgCredits3">
                        Créditos: {{ news.imgCredits3 }}
                    </small>
                </p>
                <div class="card-body px-2-responsive" *ngIf="news.text3 && news.text3 != ' '">
                    <p class="card-text info-text pt-3 px-2-responsive">
                        <span [innerText]="news.text3"></span>
                    </p>
                    <br>
                    <br>
                </div>

                <div class="card-body px-2-responsive text-center" *ngIf="enlaces">
                    <p class="card-text info-text text-muted">
                        <span>Enlaces Relacionados</span>
                    </p>
                    <small *ngFor="let link of enlaces" class="info-text">
                        <a [href]="cleanURL(link)" class="nav-link" target="_blank" rel="noopener noreferrer">
                            {{ link }}
                        </a>
                    </small>
                    <br>
                </div>
            </div>
        </div>
    </div>
</div>
<app-spinner [loading]="loading"></app-spinner>
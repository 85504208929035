import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class GeneralFuncsService {

  constructor(
    private _router: Router,
  ) { }

  getImgUrl: (img_name: string) => string = (img_name) => {
    return `assets/img/${img_name}`;
  }

  getIdOnUrl: () => string = () => {
    return this._router.url.split('/').pop();
  }
}

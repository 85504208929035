<div class="sitemap">
  <h1>
    Mapa de Sitio PARLACEN
  </h1>
  <div class="enlaces">
    <div class="row">
      <div class="col">
        <h2>Acerca</h2>
        <a routerLink="/acerca-de">Acerca del PARLACEN</a>
        <a routerLink="/historia">Historia del PARLACEN</a>
        <a routerLink="/marco-politico-juridico">Marco Político y Jurídico</a>
        <a href="../../../assets/pdf/PC _ CASOS DE EXITO.pdf">Casos de exitos</a>
        <a routerLink="/contacto">Contacto</a>
      </div>
      <div class="col">
        <h2>Organizacion</h2>
        <a routerLink="/junta-directiva">Junta Directiva</a>
        <a routerLink="/diputados">Bancadas Nacionales</a>
        <a routerLink="/comisiones">Comisiones Permanentes</a>
        <a routerLink="/grupos-parlamentarios">Grupos Parlamentarios</a>
        <a routerLink="/asamblea-plenaria">Asamblea Parlamentaria</a>
        <a routerLink="/bloque-mujeres-parlamentarias">Bloque de Mujeres Parlamentarias</a>
        <a routerLink="/eurolat">Eurolat</a>

        <a href="https://observatorio.parlacen.int/home" target="_blank">Observatorio del PARLACEN</a>
        <a href="/other-parliaments">Otros parlamentos</a>
      </div>
      <div class="col">
        <h2>Documentos PARLACEN</h2>
        <a routerLink="/resoluciones">Resoluciones</a>
        <a routerLink="/informes-plenaria">Informes</a>
        <a routerLink="/informes-plenaria">Actas</a>
        <!-- <a routerLink="/informes-plenaria">Boletin </a> -->
        <a routerLink="/resoluciones">Pronunciamientos</a>
        <a routerLink="/resoluciones">Declaraciones</a>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <h2>Agenda</h2>
        <a routerLink="/agenda">Agenda PARLACEN</a>
      </div>
      <div class="col">
        <h2>Multimedia</h2>
        <a routerLink="/parlacen-tv">Parlacen TV</a>
      </div>
      <div class="col">
        <h2>Noticias</h2>
        <a routerLink="/noticias">Actualidad</a>

        <a routerLink="/covid-19">Covid-19</a><a routerLink="/iota">Acciones por ETA e IOTA</a>
        <a routerLink="/concurso-miguel-angel-asturias">Concurso Miguel Angel Asturias</a>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <h2>Administrativo</h2>
        <a href="http://mail.parlacen.int/groupoffice/" target="_blank">Mail</a>
        <a href="https://admin.parlacen.int/iniciar-sesion" target="_blank">Admin Parlacen</a>
      </div>
      <div class="col">
        <!--   <h2>Multimedia</h2>
        <a routerLink="/parlacen-tv">Parlacen TV</a> -->
      </div>
      <div class="col">
        <!-- <h2>Noticias</h2>
        <a routerLink="/noticias">Actualidad</a>

        <a routerLink="/covid-19">Covid-19</a><a
          routerLink="/iota">Acciones por ETA e IOTA</a>
          <a routerLink="/concurso-miguel-angel-asturias">Concurso Miguel Angel Asturias</a> -->
      </div>
    </div>
  </div>
</div>

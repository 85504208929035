<div class="bloque-mujeres-parlamentarias">
  <div class="container-lg">
    <div class="my-container">
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">{{ "EL SALVADOR" | uppercase }}</h3>
      </div>
      			
<!-- <div class="section-body-container">
  <div class="light-container p-5">
    <div class="row py-4 d-responsive">
      <div class="col-lg-8">
        <p class="group-title">
          {{ "parlamentary_groups.group.text" |translate }}
        </p>
      </div>
      <div class="col-lg-3">
        <p class="group-title">
          {{ "parlamentary_groups.group.text_2" |translate }}
        </p>
      </div>
    </div>				
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          JOHANA MICHELLE ANAYA DE MURILLO
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          ANA LISSETH ARIAS HERNÁNDEZ
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          YISEEL BEATRIZ BARAHONA DE CAMPOS
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          DONALD RICARDO CALDERON LAM
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          JOSÉ NELSON GUARDADO MENJIVAR
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          GILBERTO EFRAÍN GUATEMALA VILLALTA
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          CARLOS RENÉ HERNÁNDEZ CASTILLO
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          ANA GLENDA HERNÁNDEZ ESTRADA
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          ÁNGEL JHOANNE MARAVILLA MURCIA
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          ANA GUADALUPE MEJÍA RAYMUNDO
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          JOSÉ FRANCISCO MENJÍVAR BARAHONA
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          HEIDY CAROLINA MIRA SARAVIA
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          BELINDA BEATRIZ NÚÑEZ DE MONTES
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          BRYAN RENÉ ORELLANA HERNÁNDEZ
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          NORMAN NOÉL QUIJANO GONZÁLEZ
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          ANA ELEONORA REYES ARRIAZA
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          DAVID ERNESTO REYES MOLINA
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          CECILIA GUADALUPE RIVERA MÉNDEZ
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          KARINA IVETTE SOSA DE RODAS
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
<div class="row pb-4">
      <div class="col-lg-8">
        <p class="info-text">
          CECILIA EVELYN VARGAS MARTÍNEZ
        </p>
      </div>
      <div class="col-lg-3">
        <p class="info-text">
          EL SALVADOR
</p>
      </div>
    </div>
  </div>
</div>
 -->
      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div *ngFor="let sl of deputiesSL">
            <div class="row pb-4">
              <div class="col-lg-8">
                <p class="info-text text-uppercase">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  EL SALVADOR
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <br>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">{{ "GUATEMALA" | uppercase }}</h3>
      </div>

<!--       <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>				
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ANA INGRID BERNAT COFIÑO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MÓNICA GABRIELA BRONCY GARCÍA DE HERNÁNDEZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JAFETH ERNESTO CABRERA FRANCO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ILIANA GUADALUPE CALLES DOMÍNGUEZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                NADIA LORENA DE LEÓN TORRES
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                CAMILO JOHANES DEDET CASPROWITZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                AURA LILY ESCOBAR ANLEU
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                CARLOS RAFAEL FION MORALES
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOAQUÍN FLORES ESPAÑA
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MARÍA ALMA JUDITH FLORES PORRAS DE SALAZAR
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JUAN GERARDO GUERRERO GARNICA
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ANA VICTORIA HERNÁNDEZ PÉREZ DE MORALES
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MANUEL ANTONIO MENESES RUÍZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                EVA NICOLLE MONTE BAC
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JIMMY MORALES CABRERA
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                EDUARDO FERNANDO PONCE AGUILAR
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                AMÍLCAR DE JESÚS POP AC
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JUAN PABLO RILEY PORTILLO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ERICK HAROLDO RODAS RUANO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JACQUES EMANUEL SEIDNER WEISS
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MARIO ROBERTO VALDEAVELLANO HERNÁNDEZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ERWIN EDUARDO VELÁSQUEZ HERRERA
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                GUATEMALA
</p>
            </div>
          </div>
        </div>
      </div>	
       -->
      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div *ngFor="let sl of deputiesGT">
            <div class="row pb-4">
              <div class="col-lg-8">
                <p class="info-text text-uppercase">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  Guatemala
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <br>
      <br>
      <br>
      <br>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">{{ "HONDURAS" | uppercase }}</h3>
      </div>
<!--       <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>				
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ LEÓN AGUILAR ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ MARÍA ALVARENGA CASTELLANOS,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                RICARDO ANTONIO ALVAREZ ARIAS,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                OLMAN RENÉ ARGUETA TURCIOS,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ARMANDO BARDALES PAZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MIRIAN HORTENCIA BU OSORTO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                WALTER REINIERY CASTELLANOS SAUCEDA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                AUGUSTO DOMINGO CRUZ ASENSIO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ OCTAVIO GODOY URBINA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JUAN ORLANDO HERNÁNDEZ ALVARADO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JORGE FERNANDO JIMÉNEZ REYES,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                NEDIS ADRIÁN LICONA REYES,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                COSSETTE ALEJANDRA LÓPEZ OSORIO AGUILAR,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                YEHRING ROBERTO MALDONADO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                CARLOS HUMBERTO MANZANARES CABRERA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                NELSY YOLANY MENCÍAS MARTÍNEZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JENNY ELIZETH NAVARRO OSORIO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ENGELS MARTÍN PINEDA GARCÍA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MARIO HERNÁN SORTO DERAS,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ALEJANDRO VILLATORO AGUILAR,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                RODIMIRO ZELAYA CORTÉS,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                HONDURAS
</p>
            </div>
          </div>
        </div>
      </div>
       -->	
      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div *ngFor="let sl of deputiesHN">
            <div class="row pb-4">
              <div class="col-lg-8">
                <p class="info-text text-uppercase">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  Honduras
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <br>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">{{ "NICARAGUA" | uppercase }}</h3>
      </div>
<!--       <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>				
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ ANTONIO ALVARADO CORREA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                WINSTON EDGARDO BETANCO BLANDÓN,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                AIDA ESTER BLANCO TALAVERA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JULIO CÉSAR BLANDÓN SÁNCHEZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                LARISSA REGINA COLINDRES MALDONADO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                SIDNEY ORLINGTON FRANCIS MARTIN,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ELIDA MARÍA GALEANO CORNEJO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MEYLIN OLMARA GALEANO VILLACHICA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                CARLOS SALOMÓN GARCIA BONILLA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JAIME RENÉ MORALES CARAZO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                GUILLERMO DANIEL ORTEGA REYES,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                SOCORRO JACINTA REYES LARA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                LUDENDORFF ENRIQUE ROSALES HUECK,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                VIDAL ANTONIO RUÍZ CERDA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                DORIS MARÍA TIJERINO HASLAM,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ALEYDA DE LA CRUZ TRUJILLO RUIZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MARGINE DEL CARMEN TRUJILLO RUIZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                EVILE DEL SOCORRO UMAÑA OLIVAS,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                CARLA EVIS WHITE HODGSON,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ ANTONIO ZEPEDA LÓPEZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                NICARAGUA
</p>
            </div>
          </div>
        </div>
      </div>	
       -->
      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div *ngFor="let sl of deputiesNI">
            <div class="row pb-4">
              <div class="col-lg-8">
                <p class="info-text text-uppercase">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  Nicaragua
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <br>
      <br>
      <br>
      <br>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">{{ "PANAMÁ" | uppercase }}</h3>
      </div>
<!--       <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>				
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                UARREN OSCAR BEITIA PEÑA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                GISELLE DE LOURDES BURILLO SAIZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                HERNANDO ABRAHAM CARRASQUILLA ABADI-BALID,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                OMAR MARIO CARRIZO ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MANUEL ISIDRO CASTILLERO GUERRA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                AMADO CERRUD ACEVEDO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                DORINDO JAYAN CORTEZ MARCIAGA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                RUBÉN DE LEÓN SÁNCHEZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JUAN PABLO GARCÍA FARINONI,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                RACHEL GONZÁLEZ SANTOS DE RODRÍGUEZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JACKELINE DEL CARMEN MUÑOZ CEDEÑO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                SANDRA IBERIA NORIEGA SIEIRO,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                CARLOS RODOLFO OUTTEN GONZÁLEZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ NARCISO RAMOS REYES,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                CIRILO SALAS LEMOS,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                GILBERTO MANUEL SUCCARI ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                DALVIER AMATH TUÑÓN REYES,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                HÉCTOR ABDIEL VALDERRAMA TABORDA,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                LUZ MARGARITA VÁSQUEZ ESCOBAR,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ALCIBÍADES VÁSQUEZ VELÁSQUEZ,
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                PANAMÁ
</p>
            </div>
          </div>
        </div>
      </div>
       -->
      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div *ngFor="let sl of deputiesPN">
            <div class="row pb-4">
              <div class="col-lg-8">
                <p class="info-text text-uppercase">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  Panamá
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> 
      <br>
      <br>
      <br>
      <br>
      <div class="section-title-container pt-5 pb-1">
        <h3 class="section-title">{{ "REPÚBLICA DOMINICANA" | uppercase }}</h3>
      </div>
<!--       <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>				
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                PEDRO ALEJANDRO AGUIRRE HERNÁNDEZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ ANTONIO ALFONSECA SUNCAR
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                HIGINIO ANTONIO BÁEZ UREÑA
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                BIENVENIDO CASADO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                MARGARITA MARÍA CEDEÑO LIZARDO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                LUIS JORGE ALTAGRACIA CORONADO SUÁREZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                FRANKLIN RAFAEL DE LA CRUZ RAMOS
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ÉLSIDO ANTONIO DÍAZ BUENO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                NAMIBIA ANGOLA DIDIEZ OGANDO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                SILVIA GARCÍA POLANCO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                RAMÓN EMILIO GORI TAVERAS
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                KENIA XIOMARA GUANTE VALDEZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                EDUARDO REY GUERRERO SOLANO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ GABRIEL LIZ REYES
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                DANILO MEDINA SÁNCHEZ
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JOSÉ FRANCISCO PEÑA GUABA
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ALTAGRACIA MARTHA PÉREZ CAMPUSANO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                RICARDO TOMAS POLANCO REINOSO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                SANTIAGO DE JESÚS RODRÍGUEZ PEÑA
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                CARLOS LUIS SÁNCHEZ SOLIMÁN
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                ROSA ENILDA SOLÍS PAULINO
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
<div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                JUAN MANUEL VENTURA UREÑA
              </p>
            </div>
            <div class="col-lg-3">
              <p class="info-text">
                REPÚBLICA DOMINICANA
</p>
            </div>
          </div>
        </div>
      </div>		 -->		
      
      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-3">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_2" |translate }}
              </p>
            </div>
          </div>
          <div *ngFor="let sl of deputiesRD">
            <div class="row pb-4">
              <div class="col-lg-8">
                <p class="info-text text-uppercase">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-3">
                <p class="info-text">
                  REPÚBLICA DOMINICANA
                </p>
              </div>
            </div>
          </div>
        </div>
      </div> 
    </div>
  </div>
</div>

<div class="iota">
    <!--<div>
        <app-carousel [heightIota]="true" class="hola" [images]="images"></app-carousel>
    </div> -->

    
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb pt-5 pb-4">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">
                            {{ "CONCURSO RUBEN DARIO" | uppercase
                            }}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-body-container">
                <div class="row">

                    <h2 class="section-header w-100">“DIVULGACIÓN CIENTÍFICA Y TECNOLÓGICA JÓVENES CREANDO CONEXIONES”</h2>
                    <h3> RUBÉN DARÍO 2024</h3>
                    <p></p>
                    
                </div>
            </div>
            
            <div class="container-lg">
                <div class="section-body-container">
                    <div class="row mision-vision text-center">
                        <div class="col-lg-6">
                            <h2 class="mision-h" loading="lazy">BASES DEL CONCURSO</h2>
                            <h3 class="mision-h premio" loading="lazy">I. ORGANIZACIÓN.</h3>
                            <ul class="mision-t">
                                <li>El concurso “RUBEN DARIO 2024” V EDICIÓN, modalidad “DIVULGACIÓN 
                                    CIENTIFICA Y TECNOLOGICA JOVENES CREANDO CONEXIONES”, se 
                                    declarará abierto a partir del viernes 03 de mayo hasta el miércoles 31 de julio de 
                                    2024, a las 23 horas con 59 minutos hora Guatemala.                                    
                                </li>
                                <li>
                                    El formulario de inscripción estará disponible en el siguiente vínculo:
                                    https://parlacen.int/ruben-dario los interesados deberán ingresar al link 
                                    correspondiente en la página web y redes sociales del PARLACEN, en donde 
                                    estarán adjuntas las bases del concurso y formulario de inscripción.
                                </li>
                            </ul>
                            <h3 class="mision-h" loading="lazy">II. TÉRMINOS Y CONDICIONES DE PARTICIPACIÓN.</h3>
                            <ul class="mision-t">
                                <li>
                                    Para intervenir en el CONCURSO, cada participante deberá presentar un artículo
original digital en temas de literatura, ciencia o tecnología, escrito en español o en 
idiomas autóctonos (acompañado de su respectiva traducción al español).
                                </li>
                                <li>
Se verificará por parte del jurado, que el mismo no haya sido premiado con 
antelación y no estar en proceso editorial. Se descalificará sin responsabilidad 
PARLAMENTO CENTROAMERICANO
alguna para el Parlamento Centroamericano cualquier trabajo que no cumpla con 
tales requisitos.
                                </li>
                                <li>
                                    Los participantes menores de edad deberán contar con autorización escrita de uno 
                                    de sus padres o tutor legal, debiendo expresar, al inscribirse la aceptación de
                                    sujetarse a las bases del presente concurso y acatar la decisión del jurado.
                                    Para el efecto deberán acreditar fehacientemente la representación legal con que 
                                    actúa</li>
                            </ul>
                            
                        </div>
                        <div class="col-lg-6">
                            <h3 class="mision-h" loading="lazy">V. REQUISITOS DE ENTREGA DEL ARTICULO DIGITAL :</h3>
                            <ol class="mision-t">
                                <li>Recepción del articulo digital, en caso de ser infografías enviar directamente al correo concurso@parlacen.int en caso de ser audiovideos enviar vía wetransfer.com a:concurso@parlacen.int </li>
                                <li> Ver contenido completo descargando los Terminos y condiciones.</li>
                            </ol>
                            <h3 class="mision-h" loading="lazy">VI. JURADO CALIFICADOR Y CRITERIOS DE EVALUACIÓN DEL ARTÍCULO DIGITAL :</h3>
                            <ul class="mision-t">
                                <li>El Jurado Calificador estará integrado por tres personas con las cualidades siguientes:  </li>
                                <li>Ver contenido completo descargando los Terminos y condiciones.</li>
                            </ul>
                            
                            <h3 class="mision-h" loading="lazy">VII. PREMIO.</h3>
                            <ol class="mision-t">
                                <li>La premiación se realizará el viernes 6 de septiembre de 2024, los premios serán entregados en la Ciudad de Guatemala, en la Sede del Parlamento Centroamericano o en las subsedes de los Estados Parte del PARLACEN, según la nacionalidad de los ganadores.  
                                </li>
                                <li>Se otorgará un Premio Único para los estudiantes, jóvenes, universitarios, profesionales emprendedores y entusiastas de la ciencia o tecnología, que resulten ganadores en su respectivo lugar o mención especial atendiendo el nivel de complejidad.
                                    <ul>
                                        <li>PRIMER LUGAR: USD 2000 (dos mil dólares). </li>
                                        <li>Ver contenido completo descargando los Terminos y condiciones.</li>
                                    </ul>
                                </li>
                            </ol>
                            <h3 class="mision-h" loading="lazy">VII. PROPIEDAD INTELECTUAL (CESIÓN DE DERECHOS).</h3>
                            <ol class="mision-t">
                                <li>Los artículos digitales premiados serán publicados en la página web del Parlamento Centroamericano, así como en los medios de comunicación y redes sociales que consideren oportunos.</li>
                                <li> Ver contenido completo descargando los Terminos y condiciones.</li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-body-container">
                <div class="row">
                    <p>El hecho de concurrir a la convocatoria implica la sujeción a las normas del Concurso Centroamericano y de República Dominicana Rubén Darío 2024, V Edición en la Modalidad Divulgación Científica y Tecnológica Jóvenes Creando Conexiones. </p>
                    <p>En la Ciudad de Guatemala, Guatemala, 23 de abril de 2024 </p>
                </div>
                <div class="row">
                    <img src="assets/img/Afiche-Ruben-Dario-2024.jpeg" class="img-fluid d-block" alt="...">
                </div>
            </div>
            
            <div class="section-title-container">
                <h3 class="section-title">
                    {{ "" | translate | uppercase }}
                </h3>
            </div>
            
            <div class="btn-descargar-folleto">
                <div class="row text-center">
                    <div class="col-lg-4">
                        <div class="card">
                            <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                                height="40px">
                            <div class="card-body">
                                <h3 class="card-title">
                                    Formulario
                                </h3>
                                <p class="info-text">
                                    Formulario PARA MENORES DE EDAD CONCURSO RUBEN DARIO 2024
                                </p>
                                <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                    <a class="nav-link" href="../../../assets/pdf/FRM-MENORES_DE_EDAD-CONCURSO_RUBEN_DARIO_2024.pdf"
                                        target="_blank" download="Formulario_MENORES_DE_EDAD-CONCURSO_RUBEN_DARIO_2024">
                                        Descargar
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="card">
                            <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                                height="40px">
                            <div class="card-body">
                                <h3 class="card-title">
                                    Formulario
                                </h3>
                                <p class="info-text">
                                    Formulario MAYORES DE EDAD CONCURSO RUBEN DARIO 2024
                                </p>
                                <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                    <a class="nav-link" href="../../../assets/pdf/FRM-CONCURSORUBEN_DARIO_2024.pdf"
                                        target="_blank" download="Formulario_CONCURSORUBEN_DARIO_2024">
                                        Descargar
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="card">
                          <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                              height="40px">
                          <div class="card-body">
                              <h3 class="card-title">
                                  Convocatoria
                              </h3>
                              <p class="info-text">
                                BASES DE LA CONVOCATORIA, V EDICIÓN
                              </p>
                              <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                  <a class="nav-link" href="../../../assets/pdf/BASES_CONCURSO_RUBEN-DARIO_2024.pdf"
                                      target="_blank" download="BASES_CONCURSO_RUBEN-DARIO_2024">
                                      Descargar
                                  </a>
                              </button>
                          </div>
                      </div>
                  </div>
                </div>
            </div>

        </div>
    </div> 

</div>
<app-spinner [loading]="loading"></app-spinner>
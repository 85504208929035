import { Component, OnInit } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-eurolat',
  templateUrl: './eurolat.component.html',
  styleUrls: ['./eurolat.component.css']
})
export class EurolatComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
  }

}

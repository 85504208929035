import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-marco-politico-juridico',
  templateUrl: './marco-politico-juridico.component.html',
  styleUrls: ['./marco-politico-juridico.component.css']
})
export class MarcoPoliticoJuridicoComponent implements OnInit {

  constructor(public translate: TranslateService) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}

import { NEWS_STATUS, NEWS_TYPES } from "../enums/enums";

export class News {
  constructor(
    public id:            string,
    public title:         string,
    public creationDate:  string,
    public creationTime:  string,
    public author:        string,
    public text:          string,
    public image:         string,
    public imgCredits:    string,
    public text1:         string,
    public image1:        string,
    public imgCredits1:   string,
    public text2:         string,
    public image2:        string,
    public imgCredits2:   string,
    public text3:         string,
    public image3:        string,
    public imgCredits3:   string,
    public introduction:  string,
    public lang:          string,
    public links:         string,
    public status:        NEWS_STATUS,
    public type:          NEWS_TYPES,
  ) { }
}
<div class="bloque-mujeres-parlamentarias">
  <div class="container-lg">
    <div class="my-container">
      <nav aria-label="breadcrumb d-responsive">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">{{ "Inicio" | uppercase}}</li>
          <li class="breadcrumb-item quote-transform">{{ "Organización" | uppercase}}</li>
          <li class="breadcrumb-item quote-transform active" aria-current="page">
            <a class="nav-item" role="button">{{ "Bloque de Mujeres Parlamentarias" | uppercase}}</a>
          </li>
        </ol>
      </nav>
      <div class="section-title-container">
        <h3 class="page-title">
          Bloque de Mujeres Parlamentarias 2024-2025
        </h3>
      </div>
      <!--div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "Bloque de Mujeres Parlamentarias" | uppercase }}</h3>
            </div-->
  <!--     <div class="section-body-container d-none">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Larissa Regina Colindres Maldonado

              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Presidenta
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Mónica Grabriela Broncy García
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Vicepresidenta
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Rosa Enilda Solís Paulino
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Secretaria
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Heydi Carolina Mira Saravia
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Tesorera
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Jackeline Del Carmen Muñoz Cedeño
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Tesorera
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Blanca Flor Bonilla Bonilla
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                María Mirtala López Mejía
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Fanny Carolina Salinas Fernández
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Doris María Tijerino Haslam
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Silvia García Polanco
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Jamilet Del Socorro Bonilla
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Ava Rossana Guevara Pinto
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                María Esperanza Valle Buitrago
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Kimberly Saraí Guevara Miralda
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Hiliana Sarmiento Torres
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Matilde Mercedes Bonilla Rosales
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Elida María Galeano Cornejo
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Martha Marina González Dávila
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Nery Bonilla Amaya
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                María Dilma Quezada
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Sandra Iberia Noriega Sieiro
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Irma Segunda Amaya Echeverría
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Luz Margarita Vásquez Escobar
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Rachel González Santos de Rodríguez
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Altagracia Martha Pérez Campusano
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Claudia Lorena Alas de Ávila
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Gracia María Larrave Rivas
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Nadia Lorena De León Torres
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Aura Lily Escobar Anleu
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Ana Ingrid Bernat Cofiño
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Eva Nicolle Monte Bac
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Iliana Guadalupe Calles Domínguez
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Rina Floryselva García
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Aleyda De la Cruz Trujillo Ruiz
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                María Alma Judith Flores Porras
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Ana Victoria Hernández Pérez
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Namibia Angola Didiez Ogando
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Giselle de Lourdes Burillo Saiz
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Gloria Guadalupe Oquelí Solórzano
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
          <div class="row pb-4">
            <div class="col-lg-8">
              <p class="info-text">
                Aida Ester Blanco Talavera
              </p>
            </div>
            <div class="col-lg-4">
              <p class="info-text">
                Integrante
              </p>
            </div>
          </div>
        </div>
        <div class="d-none">
          <div class="cargos-mayores">
            <div class="row py-5 px-2-responsive">
              <div class="col-md-6">
                <div class="card mb-3">
                  <img src="assets/img/perfil-parlamentaria.png" class="card-img-top" alt="Perfil Parlamentaria"
                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                  <div class="card-body light-container">
                    <h2 class="info-title">Presidente</h2>
                    <p class="card-text info-text">Título Nombre Apellido</p>
                    <p class="enhanced-text">País</p>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="card">
                  <img src="assets/img/perfil-parlamentaria.png" class="card-img-top" alt="Perfil Parlamentaria"
                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                  <div class="card-body light-container">
                    <h2 class="info-title">Vice-Presidente</h2>
                    <p class="card-text info-text">Título Nombre Apellido</p>
                    <p class="enhanced-text">País</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div> -->


      <div class="section-body-container">
        <div class="light-container p-5">
          <div class="row py-4 d-responsive">
            <div class="col-lg-8">
              <p class="group-title">
                {{ "parlamentary_groups.group.text" |translate }}
              </p>
            </div>
            <div class="col-lg-4">
              <p class="group-title">
                {{ "parlamentary_groups.group.text_1" |translate }}
              </p>
            </div>
          </div>
          <div *ngFor="let sl of deputies">


            <div class="row pb-4">
              <div class="col-lg-8">
                <p class="info-text">
                  {{sl.name}} {{sl.lastname}}
                </p>
              </div>
              <div class="col-lg-4">
                <p class="info-text">
                  {{sl.position}}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="section-title-container">
          <h3 class="section-title">{{ "Novedades de la Plenaria" | uppercase }}</h3>
        </div>
        <div class="section-body-container">
          <div class="trabajo-parlacen">
            <h2 class="info-title">{{"parlamentary_groups.news_from_the_plenary.title2" |translate }}</h2>
            <div class="row text-center">
              <div class="col-lg-4">
                <div class="card hecho">
                  <div class="card-body hecho-b">
                    <img src="assets/svg/Resoluciones y  declaracionesAzul.svg" class="hecho-img align-self-center"
                      alt="Ícono de Historia" width="34" height="65" loading="lazy">
                    <h2 class="group-title">
                      {{"parlamentary_groups.news_from_the_plenary.resolution" |translate }}
                    </h2>
                  </div>
                  <div class="card-footer">
                    <p class="hecho-f">
                      <button type="button" class="btn btn-outline-dark outline-ver-aqui" routerLink="/resoluciones">
                        <a class="nav-link">
                          {{"parlamentary_groups.news_from_the_plenary.btn" |translate |uppercase}}
                        </a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card hecho">
                  <div class="card-body nobedad-b">
                    <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                      alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                    <h2 class="group-title">
                      {{"parlamentary_groups.news_from_the_plenary.minutes" |translate }}
                    </h2>
                  </div>
                  <div class="card-footer">
                    <p class="hecho-f">
                      <button type="button" class="btn btn-outline-dark outline-ver-aqui"
                        routerLink="/informes-plenaria">
                        <a class="nav-link">
                          {{"parlamentary_groups.news_from_the_plenary.btn" |translate |uppercase}}
                        </a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="card hecho">
                  <div class="card-body">
                    <img src="assets/svg/Actas e informesAzul.svg" class="hecho-img align-self-center"
                      alt="Ícono de Documentos Parlamentaria" width="28" height="65" loading="lazy">
                    <h2 class="group-title">
                      {{"parlamentary_groups.news_from_the_plenary.parliament_reports" |translate }}
                    </h2>
                  </div>
                  <div class="card-footer">
                    <p class="hecho-c">
                      <button type="button" class="btn btn-outline-dark outline-ver-aqui"
                        routerLink="/informes-plenaria">
                        <a class="nav-link">
                          {{"parlamentary_groups.news_from_the_plenary.btn" |translate |uppercase}}
                        </a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-5 pt-5">
            <h3 class="section-title">{{"parlamentary_groups.know_more.title" |translate |uppercase}}</h3>
          </div>
          <div class="row conocer-mas text-white">
            <div class="col-lg-6 text-center align-self-center ti-text">
              <p class="ti-t">{{"parlamentary_groups.know_more.text" |translate }}</p>
              <button type="button" class="btn btn-primary ti-btn" routerLink="/junta-directiva">
                {{"parlamentary_groups.know_more.btn" |translate |uppercase}}
              </button>
            </div>
            <div class="col-lg-6 p-0 ti-image">
              <img src="assets/img/Organizacion--Grupos-parlamentarios.png" width="100%" class="ti-img"
                alt="Diputados del Parlamento Centroamericano" loading="lazy"
                srcset="assets/img/Organizacion--Grupos-parlamentarios.png 1x, assets/img/Organizacion--Grupos-parlamentarios.png 2x">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="light-container d-none">
      <div class="container-lg">
        <div class="my-container">
          <div class="section-title-container cuadro-admin-pasadas">
            <h3 class="section-title">{{ "Cuadro histórico de administraciones pasadas" | uppercase }}</h3>
          </div>
          <div class="section-body-container">
            <h2 class="info-title">Histórico Administración Bloques de Mujeres Parlamentarias</h2>
          </div>
          <div class="cuadro-admin">
            <div class="row text-center pt-3">
              <div class="col-lg-3">
                <div class="card hecho">
                  <div class="card-body hecho-b">
                    <h2 class="group-title">2019-2020</h2>
                  </div>
                  <div class="card-footer">
                    <p class="hecho-f">
                      <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                        <a class="nav-link">{{ "Consultar" | uppercase }}</a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card hecho">
                  <div class="card-body nobedad-b">
                    <h2 class="group-title">2018-2019</h2>
                  </div>
                  <div class="card-footer">
                    <p class="hecho-f">
                      <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                        <a class="nav-link">{{ "Consultar" | uppercase }}</a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card hecho">
                  <div class="card-body">
                    <h2 class="group-title">2017-2018</h2>
                  </div>
                  <div class="card-footer">
                    <p class="hecho-c">
                      <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                        <a class="nav-link">{{ "Consultar" | uppercase }}</a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-lg-3">
                <div class="card hecho">
                  <div class="card-body">
                    <h2 class="group-title">2016-2017</h2>
                  </div>
                  <div class="card-footer">
                    <p class="hecho-c">
                      <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                        <a class="nav-link">{{ "Consultar" | uppercase }}</a>
                      </button>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

<div class="container-lg">
    <div class="my-container">
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">{{ "Inicio" | uppercase}}</li>
                <li class="breadcrumb-item quote-transform">{{ "recursos multimedia" | uppercase}}</li>
                <li class="breadcrumb-item quote-transform active" aria-current="page">
                    <a class="nav-item" role="button">{{ "PARLACEN TV" | uppercase}}</a>
                </li>
            </ol>
        </nav>
        <br>
        <br>
        <div class="d-flex section-title-container">
            <img src="assets/img/Logo.png" alt="Logo PARLACEN" width="50.64" height="58">
            <h3 class="title-p pl-2"> PARLACEN TV</h3>
        </div>
        <div class="section-title-container d-none">
            <button type="button" class="btn btn-outline-primary" style="width: 250px; margin: 2%;">Categoria 1</button>
            <button type="button" class="btn btn-outline-primary" style="width: 250px; margin: 2%;">Categoria 2</button>
            <button type="button" class="btn btn-outline-primary" style="width: 250px; margin: 2%;">Categoria 3</button>
        </div>
        <div class="section-body-container">
            <div class="row">
                <div class="col-lg-4"
                    *ngFor="let item of videos | slice: (page - 1) * pageSize : (page - 1) * pageSize + pageSize">
                    <div class="card">
                        <iframe [title]="item.title" class="embed-responsive-item"
                            [src]="cleanURLResource('https://www.youtube.com/embed/' + item.video)" allowfullscreen
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;">
                        </iframe>
                        <div class="card-body">
                            <h5 class="group-title">
                                {{ item.title | uppercase }}
                            </h5>
                            <p class="info-text">
                                {{ item.description }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <ngb-pagination class="d-flex justify-content-center pt-5" [collectionSize]="videos?.length" [(page)]="page"
                [boundaryLinks]="true" [pageSize]="pageSize" size="sm" [maxSize]="5" [rotate]="true"
                *ngIf="videos && videos?.length">
            </ngb-pagination>
        </div>
    </div>

    <div>

    </div>
</div>
import { Component, Input, OnInit } from '@angular/core';
import { IButton } from 'src/app/interfaces/i-button';
import { IImage } from 'src/app/interfaces/i-image';

@Component({
  selector: 'app-baby-banner',
  templateUrl: './baby-banner.component.html',
  styleUrls: ['./baby-banner.component.css']
})
export class BabyBannerComponent implements OnInit {
  @Input() mainTitle: string;
  @Input() title: string;
  @Input() button: IButton;
  @Input() image: IImage;

  constructor() { }

  ngOnInit(): void {
  }

}

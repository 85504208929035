<img src="assets/img/MAPA_MUNDI_AZUL.png" width="100%" alt="">
<div class="plenaria parlamentos">
  <div class="container-lg">
    <div class="my-container">
      <nav aria-label="breadcrumb d-responsive">
        <ol class="breadcrumb py-5">
          <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
          <li class="breadcrumb-item quote-transform active" aria-current="page">
            <a href="" target="_blank" class="nav-item" role="button">{{ "Parlamentos" | uppercase }}</a>
          </li>
        </ol>
      </nav>

      <div class="section-title-container">
        <h2>
          ORGANISMOS DE INTEGRACIÓN
        </h2>
        <div class="header-resoluciones">
          <div class="card border-0 light-container">
            <div class="card-body py-5 rounded text-center" style="border-radius: 26px;">
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="https://www.sica.int" target="_blank" class="card-text section-title my-3" id="resoluciones">
                    {{ "Sistema de Integración Centroamericana" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.sieca.int" target="_blank" class="card-text section-title  my-3"
                    id="pronunciamientos">
                    {{ "Secretaría de Integración Económica Centroamericana" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4  margin-left-sb" role="button">
                  <a href="https://www.bcie.org" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "Banco Centroamericano de Integración Económica" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="http://portal.ccj.org.ni/ccj2/" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "Corte Centroamericana de Justicia" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.juntossomosmas.info" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "Juntos somos mas" | uppercase }}
                  </a>
                </div>

                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="" target="_blank" class="card-text section-title my-3" id="declaraciones">
                    {{ "" | uppercase }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-title-container">
        <h2>
          PARLAMENTOS Regionales
        </h2>
        <div class="header-resoluciones">
          <div class="card border-0 light-container">
            <div class="card-body py-5 rounded text-center" style="border-radius: 26px;">
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="https://www.parlamentomercosur.org" target="_blank" class="card-text section-title my-3" id="resoluciones">
                    {{ "Mercosur" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://europa.eu/european-union/index_es" target="_blank" class="card-text section-title  my-3"
                    id="pronunciamientos">
                    {{ "Unión europea" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4  margin-left-sb" role="button">
                  <a href="https://www.congreso.gob.pe/ParlamentoAmazonico/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "Parlamento Amazonico" | uppercase }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="informacion-parlamentos">
        <h3 class="mt-3">
          LINKS
        </h3>
        <h2>
          Links Parlamentos
        </h2>
        <div class="info-seccion">
          <div class="sec-pag">

            <h4>
              En esta Sección usted encontrará las paginas web de distintos Parlamentos del mundo.
            </h4>
          </div>
          <h5>
            AMÉRICA - OCEANÍA - ÁFRICA - EUROPA - ASIA
          </h5>
        </div>
      </div>
      <div class="section-title-container">
        <h2>
          américa
        </h2>
        <div class="header-resoluciones">
          <div class="card border-0 light-container">
            <div class="card-body py-5 rounded text-center" style="border-radius: 26px;">
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="http://senado.gov.ar/" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "Argentina" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.asambleanacional.gob.ec/es" target="_blank" class="card-text section-title  my-3"
                    id="pronunciamientos">
                    {{ "Ecuador" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4  margin-left-sb" role="button">
                  <a href="https://bit.ly/2dumhof" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "Panamá" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="http://senado.gov.br/" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "brasil" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://asamblea.gob.sv/" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "el salvador" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://bit.ly/2McMnix" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "paraguay" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 ">
                  <a href="" target="_blank" class="card-text section-title my-3" href="https://www.w3schools.com"
                    target="_blank">
                    {{ "chile" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://house.gov/" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "estados unidos" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://congreso.gob.pe/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "PERÚ" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="http://parl.gc.ca/" target="_blank" class="card-text section-title my-3" id="resoluciones">
                    {{ "canadá" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://congreso.gob.gt/index.php" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "guatemala" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://senado.gob.do/senado/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "REPÚBLICA DOMINICANA" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 " role="button">
                  <a href="http://senado.gov.co/" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "colombia" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://www.parlacen.int/.parlacenhonduras.blogspot.com" target="_blank"
                    class="card-text section-title my-3" id="pronunciamientos">
                    {{ "honduras" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://ttparliament.org/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "TRINIDAD Y TOBAGO" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="http://asamblea.go.cr/" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "costa rica" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://asamblea.gob.ni/" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "nicaragua" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="" target="_blank" class="card-text section-title my-3" id="declaraciones">
                    {{ "" | uppercase }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-title-container">
        <h2>
          EUROPA
        </h2>
        <div class="header-resoluciones">
          <div class="card border-0 light-container">
            <div class="card-body py-5 rounded text-center" style="border-radius: 26px;">
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="https://www.bundestag.de/en/" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "ALEMANIA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://bit.ly/1MVRQxd" target="_blank" class="card-text section-title  my-3"
                    id="pronunciamientos">
                    {{ "FRANCIA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4  margin-left-sb" role="button">
                  <a href="https://www.parlement.nl/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "PAÍSES BAJOS" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="https://www.parlament.gv.at/ENGL/" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "AUSTRIA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.parliament.uk/" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "GRAN BRETAÑA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.parlamento.pt/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "PORTUGAL" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 " role="button">
                  <a href="https://bit.ly/2YRxmnr" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "BÉLGICA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://https//www.oireachtas.ie/" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "IRLANDA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.riksdagen.se/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "SUECIA" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="https://bit.ly/1LhfdDn" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "ESPAÑA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://https//bit.ly/2NgFn3g" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "ITALIA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="" target="_blank" class="card-text section-title my-3" id="declaraciones">
                    {{ "" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4 " role="button">
                  <a href="http://valtioneuvosto.fi/etusivu/fi.jsp" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "FINLANDIA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.chd.lu/wps/portal/public" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "LUXENBURGO" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="" target="_blank" class="card-text section-title my-3" id="declaraciones">
                    {{ "" | uppercase }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-title-container">
        <h2>
          ÁFRICA
        </h2>
        <div class="header-resoluciones">
          <div class="card border-0 light-container">
            <div class="card-body py-5 rounded text-center" style="border-radius: 26px;">
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="https://bit.ly/1U7GSvP" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "ANGOLA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://bit.ly/2JHObxN" target="_blank" class="card-text section-title  my-3"
                    id="pronunciamientos">
                    {{ "EGIPTO" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4  margin-left-sb" role="button">
                  <a href="https://www.sec.gouv.sn/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "SENEGAL" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="https://bit.ly/2XaAdrb" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "BENIN" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://www.parlacen.int/.netafrica.org/" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "ERITREA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://bit.ly/2YTvjiJƒ" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "TANZANIA" | uppercase }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-title-container">
        <h2>
          OCEANÍA
        </h2>
        <div class="header-resoluciones">
          <div class="card border-0 light-container">
            <div class="card-body py-5 rounded text-center" style="border-radius: 26px;">
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="https://www.parliament.nsw.gov.au/Pages/home.aspx" target="_blank"
                    class="card-text section-title my-3" id="resoluciones">
                    {{ "NEW SOUTH GALES" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://bit.ly/2YP1hg0" target="_blank" class="card-text section-title  my-3"
                    id="pronunciamientos">
                    {{ "TASMANIA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4  margin-left-sb" role="button">
                  <a href="https://www.parliament.vic.gov.au/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "VICTORIA" | uppercase }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section-title-container">
        <h2>
          ASIA
        </h2>
        <div class="header-resoluciones">
          <div class="card border-0 light-container">
            <div class="card-body py-5 rounded text-center" style="border-radius: 26px;">
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="http://assembly.go.kr/main.acl" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "COREA DEL SUR" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://majlis.ir/" target="_blank" class="card-text section-title  my-3"
                    id="pronunciamientos">
                    {{ "IRÁN" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4  margin-left-sb" role="button">
                  <a href="https://www.parliament.go.th/ewtadmin/ewt/parliament_parcy/main.php?filename=thai_national_assembly"
                    target="_blank" class="card-text section-title my-3" id="declaraciones">
                    {{ "TAILANDIA" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="http://almajles.gov.ae/Pages/Home.aspx" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "EMIRATOS ÁRABES" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="http://knesset.gov.il/index.html" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "ISRAEL" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.tbmm.gov.tr/develop/owa/tbmm_internet.anasayfa" target="_blank"
                    class="card-text section-title my-3" id="declaraciones">
                    {{ "TURQUÍA" | uppercase }}
                  </a>
                </div>
              </div>
              <div class="row">
                <div class="col-lg-4" role="button">
                  <a href="http://parliamentofindia.nic.in/" target="_blank" class="card-text section-title my-3"
                    id="resoluciones">
                    {{ "INDIA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://bit.ly/1lZ7KSj" target="_blank" class="card-text section-title my-3"
                    id="pronunciamientos">
                    {{ "MALASIA" | uppercase }}
                  </a>
                </div>
                <div class="col-lg-4 margin-left-sb" role="button">
                  <a href="https://www.parliament.gov.sg/" target="_blank" class="card-text section-title my-3"
                    id="declaraciones">
                    {{ "SINGAPUR" | uppercase }}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="bloque-mujeres-parlamentarias">
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">{{ "parlamentary_groups.breadcum.home" |translate|uppercase}}</li>
                    <li class="breadcrumb-item quote-transform">
                        {{ "parlamentary_groups.breadcum.organitation" |translate|uppercase}}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">
                            {{ "parlamentary_groups.breadcum.parlamentary_groups" |translate|uppercase}}
                        </a>
                    </li>
                </ol>
            </nav>
            <div class="section-title-container">
                <h3 class="page-title">
                    {{ "parlamentary_groups.body.title" |translate}}
                </h3>
            </div>
            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "parlamentary_groups.body.text_1" |translate |uppercase}}</h3>
            </div>
            <div class="section-body-container">
                <div class="light-container p-5">
                    <div class="row py-4 d-responsive">
                        <div class="col-lg-6">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text" |translate }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text_1" |translate }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text_2" |translate }}
                            </p>
                        </div>
                    </div>
                    <div class="row pb-4" *ngFor="let member of deputiesGPICD;let i = index;">
                        <div class="col-lg-6">
                            <p class="info-text">
                                {{member.name + " " + member.lastname |uppercase}}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                {{member.position}}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                {{member.country}}
                            </p>
                        </div>
                    </div>

                </div>
                <!--div class="cargos-mayores">
                    <div class="row py-5 px-2-responsive">
                        <div class="col-md-6">
                            <div class="card mb-3">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <h2 class="info-title">Presidente</h2>
                                    <p class="card-text info-text">Título Nombre Apellido</p>
                                    <p class="enhanced-text">País</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <h2 class="info-title">Vice-Presidente</h2>
                                    <p class="card-text info-text">Título Nombre Apellido</p>
                                    <p class="enhanced-text">País</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div-->
            </div>
            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "parlamentary_groups.body.text_2" |translate |uppercase}}</h3>
            </div>
            <div class="section-body-container">
                <div class="light-container p-5">
                    <div class="row py-4 d-responsive">
                        <div class="col-lg-6 ">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text" |translate }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text_1" |translate }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text_2" |translate }}
                            </p>
                        </div>
                    </div>
                    <div class="row pb-4" *ngFor="let member of deputiesGPI;let i = index;">
                        <div class="col-lg-6">
                            <p class="info-text">
                                {{member.name + " " + member.lastname |uppercase}}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                {{member.position}}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                {{member.country}}
                            </p>
                        </div>
                    </div>

                </div>
                <!--div class="cargos-mayores">
                    <div class="row py-5 px-2-responsive">
                        <div class="col-md-6">
                            <div class="card mb-3">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <h2 class="info-title">Presidente</h2>
                                    <p class="card-text info-text">Título Nombre Apellido</p>
                                    <p class="enhanced-text">País</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <h2 class="info-title">Vice-Presidente</h2>
                                    <p class="card-text info-text">Título Nombre Apellido</p>
                                    <p class="enhanced-text">País</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div-->
            </div>
            <div class="section-title-container pt-5 pb-1">
                <h3 class="section-title">{{ "parlamentary_groups.body.text_3" |translate |uppercase}}</h3>
            </div>
            <div class="section-body-container">
                <div class="light-container p-5">
                    <div class="row py-4 d-responsive">
                        <div class="col-lg-6">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text" |translate }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text_1" |translate }}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="group-title">
                                {{ "parlamentary_groups.group.text_2" |translate }}
                            </p>
                        </div>
                    </div>
                    <div class="row pb-4" *ngFor="let member of deputiesUDI;let i = index;">
                        <div class="col-lg-6">
                            <p class="info-text">
                                {{member.name + " " + member.lastname |uppercase}}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                {{member.position}}
                            </p>
                        </div>
                        <div class="col-lg-3">
                            <p class="info-text">
                                {{member.country}}
                            </p>
                        </div>
                    </div>

                </div>
                <!--div class="cargos-mayores">
                    <div class="row py-5 px-2-responsive">
                        <div class="col-md-6">
                            <div class="card mb-3">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <h2 class="info-title">Presidente</h2>
                                    <p class="card-text info-text">Título Nombre Apellido</p>
                                    <p class="enhanced-text">País</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="card">
                                <img src="assets/img/perfil-parlamentaria.png" class="card-img-top"
                                    alt="Perfil Parlamentaria"
                                    srcset="assets/img/perfil-parlamentaria.png 1x, assets/img/perfil-parlamentaria@2x.png 2x">
                                <div class="card-body light-container">
                                    <h2 class="info-title">Vice-Presidente</h2>
                                    <p class="card-text info-text">Título Nombre Apellido</p>
                                    <p class="enhanced-text">País</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div-->
            </div>
            <div class="section-title-container">
                <h3 class="section-title">{{ "parlamentary_groups.news_from_the_plenary.title" |translate |uppercase}}
                </h3>
            </div>
            <div class="section-body-container">
                <div class="trabajo-parlacen">
                    <h2 class="info-title">{{"parlamentary_groups.news_from_the_plenary.title2" |translate }}</h2>
                    <div class="row text-center">
                        <div class="col-lg-4">
                            <div class="card hecho">
                                <div class="card-body hecho-b">
                                    <img src="assets/svg/Resoluciones y  declaracionesAzul.svg"
                                        class="hecho-img align-self-center" alt="Ícono de Historia" width="34"
                                        height="65" loading="lazy">
                                    <h2 class="group-title">
                                        {{"parlamentary_groups.news_from_the_plenary.resolution" |translate }}
                                    </h2>
                                </div>
                                <div class="card-footer">
                                    <p class="hecho-f">
                                        <button type="button" class="btn btn-outline-dark outline-ver-aqui"
                                            routerLink="/resoluciones">
                                            <a class="nav-link">
                                                {{"parlamentary_groups.news_from_the_plenary.btn" |translate |uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card hecho">
                                <div class="card-body nobedad-b">
                                    <img src="assets/svg/PronunciamientosAzul.svg" class="hecho-img align-self-center"
                                        alt="Ícono de Marco Jurídico y Político" width="28" height="65" loading="lazy">
                                    <h2 class="group-title">
                                        {{"parlamentary_groups.news_from_the_plenary.minutes" |translate }}
                                    </h2>
                                </div>
                                <div class="card-footer">
                                    <p class="hecho-f">
                                        <button type="button" class="btn btn-outline-dark outline-ver-aqui"
                                            routerLink="/informes-plenaria">
                                            <a class="nav-link">
                                                {{"parlamentary_groups.news_from_the_plenary.btn" |translate |uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="card hecho">
                                <div class="card-body">
                                    <img src="assets/svg/Actas e informesAzul.svg" class="hecho-img align-self-center"
                                        alt="Ícono de Documentos Parlamentaria" width="28" height="65" loading="lazy">
                                    <h2 class="group-title">
                                        {{"parlamentary_groups.news_from_the_plenary.parliament_reports" |translate }}
                                    </h2>
                                </div>
                                <div class="card-footer">
                                    <p class="hecho-c">
                                        <button type="button" class="btn btn-outline-dark outline-ver-aqui"
                                            routerLink="/informes-plenaria">
                                            <a class="nav-link">
                                                {{"parlamentary_groups.news_from_the_plenary.btn" |translate |uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-5 pt-5">
                    <h3 class="section-title">{{"parlamentary_groups.know_more.title" |translate |uppercase}}</h3>
                </div>
                <div class="row conocer-mas text-white">
                    <div class="col-lg-6 text-center align-self-center ti-text">
                        <p class="ti-t">{{"parlamentary_groups.know_more.text" |translate }}</p>
                        <button type="button" class="btn btn-primary ti-btn" routerLink="/junta-directiva">
                            {{"parlamentary_groups.know_more.btn" |translate |uppercase}}
                        </button>
                    </div>
                    <div class="col-lg-6 p-0 ti-image">
                        <img src="assets/img/Organizacion--Grupos-parlamentarios.png" width="100%" class="ti-img"
                            alt="Diputados del Parlamento Centroamericano" loading="lazy"
                            srcset="assets/img/Organizacion--Grupos-parlamentarios.png 1x, assets/img/Organizacion--Grupos-parlamentarios.png 2x">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="light-container">
        <div class="container-lg">
            <div class="my-container">
                <div class="section-title-container cuadro-admin-pasadas">
                    <h3 class="section-title">{{"parlamentary_groups.historic.title" |translate |uppercase}}</h3>
                </div>
                <div class="section-body-container">
                    <h2 class="info-title">{{"parlamentary_groups.historic.title_2" |translate }}</h2>
                </div>
                <div class="cuadro-admin">
                    <div class="row text-center pt-3">
                        <div class="col-lg-3">
                            <div class="card hecho">
                                <div class="card-body hecho-b">
                                    <h2 class="group-title">2019-2020</h2>
                                </div>
                                <div class="card-footer">
                                    <p class="hecho-f">
                                        <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                                            <a class="nav-link" href="assets/pdf/COMISIONES 2017-2020.xlsx - 2019-2020.pdf">
                                                {{"parlamentary_groups.historic.btn" |translate |uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="card hecho">
                                <div class="card-body nobedad-b">
                                    <h2 class="group-title">2018-2019</h2>
                                </div>
                                <div class="card-footer">
                                    <p class="hecho-f">
                                        <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                                            <a class="nav-link" href="assets/pdf/COMISIONES 2017-2020.xlsx - 2018-2019.pdf">
                                                {{"parlamentary_groups.historic.btn" |translate |uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="card hecho">
                                <div class="card-body">
                                    <h2 class="group-title">2017-2018</h2>
                                </div>
                                <div class="card-footer">
                                    <p class="hecho-c">
                                        <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                                            <a class="nav-link" href="assets/pdf/COMISIONES 2017-2020.xlsx - 2017-2018.pdf">
                                                {{"parlamentary_groups.historic.btn" |translate |uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3">
                            <div class="card hecho">
                                <div class="card-body">
                                    <h2 class="group-title">2016-2017</h2>
                                </div>
                                <div class="card-footer">
                                    <p class="hecho-c">
                                        <button type="button" class="btn btn-outline-dark outline-ver-aqui" >
                                            <a class="nav-link" href="assets/pdf/COMISIONES 2016-2017.pdf">
                                                {{"parlamentary_groups.historic.btn" |translate |uppercase}}
                                            </a>
                                        </button>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-spinner [loading]="loading"></app-spinner>

import { Component, Input, OnInit } from '@angular/core';
import { IButton } from 'src/app/interfaces/i-button';
import { IImage } from 'src/app/interfaces/i-image';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.css']
})
export class CarouselComponent implements OnInit {
  @Input() images: IImage[];
  @Input() textImg: string;
  @Input() textImgAlt: string;
  @Input() title: string;
  @Input() text: string;
  @Input() buttonOptions: IButton;
  @Input() reduceBrightness: boolean;

  @Input() heightIota: boolean;
  @Input() interval: number = 10000;
  constructor() { }

  ngOnInit(): void {
  }

}

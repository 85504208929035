<div>
    <div id="Indicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="assets/img/carusel-1.jpeg" class="d-block w-100 carousel-img"
                    srcset="assets/img/carusel-1.jpeg 1x, assets/img/carusel-1.jpeg 2x" data-interval="10000"
                    alt="JD-2024-2025" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/carusel-2.jpeg" class="d-block w-100 carousel-img"
                    srcset="assets/img/carusel-2.jpeg, assets/img/carusel-2.jpeg 2x" data-interval="10000"
                    alt="JD-2024-2025" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/carusel-3.jpeg" class="d-block w-100 carousel-img"
                    srcset="assets/img/carusel-3.jpeg 1x, assets/img/carusel-3.jpeg 2x"
                    data-interval="10000" alt="JD-2024-2025" loading="lazy">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
        <div class="carousel-text d-lg-block">
            <h3 class="page-title text-white">{{ "parliamentary_assembly.carusel.title" | translate }}</h3>
            <button type="button" class="btn btn-primary btn-leer-mas" routerLink="/agenda">
                {{ "parliamentary_assembly.carusel.btn" | translate | uppercase }}
            </button>
        </div>
    </div>
</div>
<br>
<br>
<div class="container-lg">
    <div class="my-container">
        <nav aria-label="breadcrumb d-responsive">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    {{ "parliamentary_assembly.breadcum.home" | translate | uppercase }}
                </li>
                <li class="breadcrumb-item quote-transform">
                    {{ "parliamentary_assembly.breadcum.organization" | translate | uppercase }}
                </li>
                <li class="breadcrumb-item quote-transform active" aria-current="page">
                    <a class="nav-item" role="button">
                        {{ "parliamentary_assembly.breadcum.parliamentary_assembly" | translate | uppercase }}
                    </a>
                </li>
            </ol>
        </nav>
        <div class="section-title-container">
            <h3 class="info-title">
                {{ "parliamentary_assembly.body.title" | translate }}
            </h3>
        </div>
    </div>
</div>
<div class="container-lg">
    <div class="my-container">
        <div class="section-body-container">
            <div class="accordion" id="accordionExample">
                <div class="card style" style="border-bottom: 2px solid #56bfff; border-radius: 10px">
                    <div class="card-header" id="headingTwo">
                        <h2 class="mb-0 titulo">
                            <button class="btn" type="button" data-toggle="collapse" data-target="#collapseTwo"
                                aria-expanded="true" aria-controls="collapseTwo">
                                {{ "parliamentary_assembly.body.title_body" | translate }}
                                <img src="../../../assets/svg/DropdownCeleste.svg" width="25px" height="25px" />
                            </button>
                        </h2>
                    </div>
                    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionExample">
                        <div class="card-body cuerpo" style="background-color: #F5F5F5 ;">
                            <ul class="list">
                                <div class="row">
                                    <div class="col">
                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text" | translate }}
                                        </p> <br>

                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text_2" | translate }}
                                        </p> <br>
                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text_3" | translate }}
                                        </p>
                                        <br>
                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text_4" | translate }}
                                        </p>
                                        <br>
                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text_5" | translate }}
                                        </p>
                                    </div>
                                    <div class="col">
                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text_6" | translate }}
                                        </p>
                                        <br>
                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text_7" | translate }}
                                        </p>
                                        <br>
                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text_8" | translate }}
                                        </p>
                                        <br>
                                        <p class="ptitel">
                                            {{ "parliamentary_assembly.body.text_9" | translate }}
                                        </p>
                                    </div>
                                </div>
                            </ul>
                            <!--a href="../../../assets/img/descargar.pdf" download="DescargaNueva">
              <button type="button" class="btn btn-outline-secondary">
                Descargas
              </button>
            </a-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <br>
    <br>
    <br>
    <br>
</div>

<div>
    <div class="container-lg">
        <div class="my-container">
            <div class="section-title-container">
                <h3 class="section-title">
                    {{ "parliamentary_assembly.news_from_the_plenary.title" | translate | uppercase }}
                </h3>
                <br>
            </div>
            <div class="section-body-container">
                <h3 class="info-title">
                    {{ "parliamentary_assembly.news_from_the_plenary.title2" | translate }}
                </h3>
                <div class="row historia-marco-documentos text-center">
                    <div class="col-lg-3">
                        <div class="card">
                            <div class="card-body">
                                <img src="assets/svg/ResolucionesAzul.svg" class="historia-img align-self-center"
                                    alt="Ícono de Historia" width="43">
                                <h2 class="historia-parlacen-h2">
                                    {{ "parliamentary_assembly.news_from_the_plenary.resolution" | translate }}
                                </h2>
                            </div>
                            <div class="card-footer">
                                <p class="historia-parlacen-t">
                                    <button type="button" class="btn btn-outline-dark outline-leer-mas"
                                        routerLink="/resoluciones">
                                        <a class="nav-link">
                                            {{ "parliamentary_assembly.news_from_the_plenary.btn" | translate |
                                            uppercase }}
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card">
                            <div class="card-body">
                                <img src="assets/svg/DeclaracionesAzul.svg" class="marco-img align-self-center"
                                    alt="Ícono de Marco Jurídico y Político" width="34">
                                <h2 class="historia-parlacen-h2">
                                    {{ "parliamentary_assembly.news_from_the_plenary.declarations" | translate }}
                                </h2>
                            </div>
                            <div class="card-footer">
                                <p class="marco-pj-t">
                                    <button type="button" class="btn btn-outline-dark outline-leer-mas"
                                        routerLink="/resoluciones">
                                        <a class="nav-link">
                                            {{ "parliamentary_assembly.news_from_the_plenary.btn" | translate |
                                            uppercase }}
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card">
                            <div class="card-body">
                                <img src="assets/svg/PronunciamientosAzul.svg" class="documentos-img align-self-center"
                                    alt="Ícono de Documentos Parlamentaria" width="34">
                                <h2 class="historia-parlacen-h2">
                                    {{ "parliamentary_assembly.news_from_the_plenary.pronouncements" | translate }}
                                </h2>
                            </div>
                            <div class="card-footer">
                                <p class="docs-plenaria-t">
                                    <button type="button" class="btn btn-outline-dark outline-leer-mas"
                                        routerLink="/resoluciones">
                                        <a class="nav-link">
                                            {{ "parliamentary_assembly.news_from_the_plenary.btn" | translate |
                                            uppercase }}
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3">
                        <div class="card">
                            <div class="card-body">
                                <img src="assets/svg/Actas e informesAzul.svg" class="documentos-img align-self-center"
                                    alt="Ícono de Documentos Parlamentaria" width="34">
                                <h2 class="historia-parlacen-h2">
                                    {{ "parliamentary_assembly.news_from_the_plenary.parliament_reports" | translate }}
                                </h2>
                            </div>
                            <div class="card-footer">
                                <p class="docs-plenaria-t">
                                    <button type="button" class="btn btn-outline-dark outline-leer-mas"
                                        routerLink="/informes-plenaria">
                                        <a class="nav-link">
                                            {{ "parliamentary_assembly.news_from_the_plenary.btn" | translate |
                                            uppercase }}
                                        </a>
                                    </button>
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <br>
        <br>
        <br>
    </div>
    <div class="container-lg">
        <div class="my-container">
            <div class="section-title-container">
                <h3 class="section-title">
                    {{ "parliamentary_assembly.know_more.title" | translate | uppercase }}
                </h3>
            </div>
            <div class="exito">
                <div class="section-body-container">
                    <div class="row text-white">

                        <div class="col-lg-6 text-center align-self-center ti-text">
                            <p class="ti-t px-2">
                                {{ "parliamentary_assembly.know_more.text" | translate }}
                            </p>
                            <button type="button" class="btn btn-primary ti-btn" routerLink='/agenda'>
                                {{ "parliamentary_assembly.know_more.btn" | translate | uppercase }}
                            </button>

                        </div>
                        <div class="col-lg-6 p-0">
                            <img src="assets/img/Organizacion--Asamble-plenaria.png" width="100%"
                                alt="Diputados del Parlamento Centroamericano" class="ti-img"
                                srcset="assets/img/Organizacion--Asamble-plenaria.png 1x, assets/img/Organizacion--Asamble-plenaria.png 2x">
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <br>
        <br>
        <br>
    </div>
</div>
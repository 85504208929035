<div class="section-title-container pt-5">
    <h3 class="section-title">
        {{ mainTitle | translate | uppercase }}
    </h3>
</div>
<div class="section-body-container">
    <div class="row conocer-mas text-white">
        <div class="col-lg-6 text-center align-self-center ti-text">
            <p class="ti-t">
                {{ title | translate }}
            </p>
            <button type="button" class="btn btn-primary ti-btn" [routerLink]="button.link">
                {{ button.text| translate | uppercase }}
            </button>
        </div>
        <div class="col-lg-6 p-0 ti-image">
            <img [src]="image.src" width="100%" class="ti-img" [alt]="image.alt" loading="lazy" [srcset]="image.srcset">
        </div>
    </div>
</div>
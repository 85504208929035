import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { PLENARY_DOCS_TYPES } from 'src/app/enums/enums';
import { PlenaryDoc } from 'src/app/models/plenary-documents.model';
import { PlenaryDocService } from 'src/app/services/plenary-doc.service';
declare var $: any;

@Component({
  selector: 'app-informes',
  templateUrl: './informes.component.html',
  styleUrls: ['./informes.component.css'],
  providers: [PlenaryDocService]
})
export class InformesComponent implements OnInit {
  public plenaryDocs: PlenaryDoc[];
  public status:      string;
  public loading:     boolean = false;
  public documents:   any = {}
  public filter:      PLENARY_DOCS_TYPES = PLENARY_DOCS_TYPES.ANY;
  public page:        number = 1;
  public pageSize:    number = 3;
  public docTypes:    any[] = [
    PLENARY_DOCS_TYPES.REPORTS,
    PLENARY_DOCS_TYPES.PROCEEDINGS,
  ]


  constructor(
    private _plenaryDocService: PlenaryDocService,
    private _sanitizer:         DomSanitizer,
    protected translate:        TranslateService
  ) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.listarDocumentos();
  }

  listarDocumentos(): void {
    this.loading = true;
    this._plenaryDocService.listDocuments().subscribe(
      res => {
        if (!res.documents) {
          this.status = 'error';
        } else {
          if (res.documents.length === 0) {
            alert('No hay documentos para mostrar');
          } else {
            this.plenaryDocs = res.documents;
            this.filterForAll();
          }
          this.status = 'ok';
        }
        this.loading = false;
      },
      err => this.onError(err)
    );
  }

  filterForAll(): void {
    if (this.plenaryDocs?.length) {
      this.documents = [];
      this.plenaryDocs.forEach(doc => {
        if (this.docTypes.includes(doc.type)) {
          this.insertToItYear(doc);
        }
      });
    }
    $('#actas').css({ 'font-size': '16px' });
    $('#informes').css({ 'font-size': '16px' });
  }

  filterForReports(): void {
    if (this.plenaryDocs?.length) {
      this.documents = [];
      this.plenaryDocs.forEach(doc => {
        if (doc.type == PLENARY_DOCS_TYPES.REPORTS) {
          this.insertToItYear(doc);
        }
      });
    }
    $('#actas').css({ 'font-size': '16px' });
    $('#informes').css({ 'font-size': '24px' });
  }

  filterForProceedings(): void {
    if (this.plenaryDocs?.length) {
      this.documents = [];
      this.plenaryDocs.forEach(doc => {
        if (doc.type == PLENARY_DOCS_TYPES.PROCEEDINGS) {
          this.insertToItYear(doc);
        }
      });
    }
    $('#actas').css({ 'font-size': '24px' });
    $('#informes').css({ 'font-size': '16px' });
  }

  insertToItYear(document: PlenaryDoc): void {
    if (!this.documents[document.year]) {
      this.documents[document.year] = [];
    }
    this.documents[document.year] = [...this.documents[document.year], document];
  }

  getObjectKeys(object: any): string[] {
    let objectKeys = Object.keys(object);
    return this.sortArray(objectKeys)
  }

  sortArray(array: any[]): any[] {
    return array.sort((a, b) => (b.toString()).localeCompare(a.toString()));
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.loading = false;
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustUrl(oldURL);
  }
}

<div class="plenaria">
  <div class="container-lg">
    <div class="my-container">
      <nav aria-label="breadcrumb d-responsive">
        <ol class="breadcrumb py-5">
          <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
          <li class="breadcrumb-item quote-transform active" aria-current="page">
            <a class="nav-item" role="button">{{ "Resoluciones" | uppercase }}</a>
          </li>
        </ol>
      </nav>
      <div class="section-title-container">
        <div class="header-resoluciones">
          <div class="card border-0 light-container">
            <div class="card-body rounded text-center">
              <div class="row">
                <div class="col-lg-4 my-3" (click)="filterForResolutions()" role="button">
                  <p class="card-text section-title" id="resoluciones">
                    {{ "plenary_reports.resolutions" | translate | uppercase }}
                  </p>
                </div>
                <div class="col-lg-4 my-3 margin-left-sb" (click)="filterForPronouncements()" role="button">
                  <p class="card-text section-title" id="pronunciamientos">
                    {{ "plenary_reports.pronouncements" | translate | uppercase }}
                  </p>
                </div>
                <div class="col-lg-4 my-3 margin-left-sb" (click)="filterForDeclarations()" role="button">
                  <p class="card-text section-title" id="declaraciones">
                    {{ "plenary_reports.declarations" | translate | uppercase }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="resoluciones">
        <div class="section-body-container">
          <div class="accordion" id="accordion" class="pb-5"
            *ngFor="let item of getObjectKeys(documents); let i = index">
            <div class="card atr-h">
              <div class="card-header" [id]="'heading' + i">
                <h2 class="mb-0">
                  <button class="btn btn-link btn-block text-left dropdown-btn-atr" type="button" data-toggle="collapse"
                    [attr.data-target]="'#collapse' + i" aria-expanded="true" [attr.aria-controls]="'#collapse' + i">
                    {{ item }}
                    <img src="assets/svg/DropdownCeleste.svg" class="float-right mt-2" alt="Ícono de desplegar"
                      width="25" loading="lazy">
                  </button>
                </h2>
              </div>
              <div [id]="'collapse' + i" class="collapse light-container" data-parent="#accordion">
                <div class="card-body m-0 px-5">
                  <div class="row pt-1 pb-4">
                    <div class="col-lg-4 pb-4" *ngFor="let doc of documents[item]">
                      <h5 class="info-title">
                        {{doc.title}}
                      </h5>
                      <p class="info-text pt-3 pb-4">
                        {{doc.description}}
                      </p>
                      <button type="button" class="btn btn-outline-dark outline-leer-mas">
                        <a class="nav-link" [href]="cleanURL(doc.document)" target="blank">
                          {{ "Leer Más" | uppercase }}
                        </a>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="section-title-container pt-5">
        <h3 class="section-title pt-5">
          {{ "plenary_reports.latest" | translate | uppercase }}
        </h3>
      </div>
      <div class="section-body-container">
        <div class="row informes-actas text-center">
          <h2 class="info-title">
            {{ "plenary_reports.read" | translate }}
          </h2>
          <div class="col-lg-6 pt-3">
            <img src="assets/svg/Reglamento InternoAzul.svg" class="info-img" alt="Ícono de Misión" width="25"
              height="30" loading="lazy">
            <h2 class="group-title">
              <br>
              <br>
              {{ "plenary_reports.policies" | translate }}
            </h2>
            <button type="button" class="btn btn-outline-dark outline-ver-aqui">
              <a class="nav-link" routerLink="/informes-plenaria">
                {{ "plenary_reports.button" | translate | uppercase }}
              </a>
            </button>
          </div>
          <div class="col-lg-6 pt-3">
            <img src="assets/svg/ReclamentoAzul.svg" class="info-img" alt="Ícono de Visión" width="25" height="30"
              loading="lazy">
            <h2 class="group-title">
              <br>
              <br>
              {{ "plenary_reports.statements" | translate }}
            </h2>
            <button type="button" class="btn btn-outline-dark outline-ver-aqui">
              <a class="nav-link" routerLink="/informes-plenaria">
                {{ "plenary_reports.button" | translate | uppercase
                }}</a>
            </button>
          </div>
        </div>
      </div>
      <div class="section-title-container pt-5">
        <h3 class="section-title pt-5">
          {{ "plenary_reports.learn" | translate | uppercase }}
        </h3>
      </div>
      <div class="section-body-container">
        <div class="row conocer-mas-parlacen text-white">
          <div class="col-lg-6 text-center align-self-center ti-text">
            <p class="ti-t">
              {{ "plenary_reports.discover" | translate }}
            </p>
            <button type="button" class="btn btn-primary ti-btn" routerLink="/parlacen-tv">
              <a class="nav-link text-white" routerLink="/parlacen-tv">
                {{ "plenary_reports.button_dos" | translate | uppercase
                }}</a>
            </button>
          </div>
          <div class="col-lg-6 p-0 ti-image">
            <img src="assets/img/Resoluciones.png" width="100%" class="ti-img"
              alt="Diputados del Parlamento Centroamericano" loading="lazy"
              srcset="assets/img/Resoluciones.png 1x, assets/img/Resoluciones.png 2x">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<app-spinner [loading]="loading"></app-spinner>

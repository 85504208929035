<div class="iota">
    <div >
        <app-carousel [heightIota]="true" class="hola" [images]="images"></app-carousel>
    </div>
    

    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb pt-5 pb-4">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{ "Iota" | uppercase }}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-body-container">
                <div class="row">
                    <h2 class="section-header w-100">Acciones del PARLACEN ante tormentas Eta e Iota</h2>
                    <div class="col-lg-12 text-justify pt-3 px-0">
                        <p class="info-text pr-lg-3">
                            El Parlamento Centroamericano, comprometido con la ayuda de todas las personas que se vieron
                            afectas por las Tormentas Tropicales Eta e Iota a finales de 2020, se solidarizó con todos
                            países que fueron afectados y desde ese momento se han estado llevando a cabo acciones para
                            hacer entrega de Ecofiltros purificadores para agua.
                        </p>
                    </div>
                </div>
            </div>
            <div class="section-title-container">
                <h3 class="section-title">
                    {{ "" | translate | uppercase }}
                </h3>
            </div>
            <div class="section-body-container">
                <div class="row px-sm-5 pt-4 pb-5">
                    <div id="carousel-articles" class="carousel slide" data-ride="carousel">
                        <div class="carousel-inner">
                            <div class="carousel-item active" data-interval="20000">
                                <a [href]="cleanURLResource('/assets/img/iota/Acciones.jpeg')" target="_blank"
                                    rel="noopener noreferred">
                                    <img src="assets/img/iota/Acciones.jpeg" class="d-block" alt="...">
                                </a>
                            </div>
                            <div class="carousel-item" data-interval="20000">
                                <a [href]="cleanURLResource('/assets/img/iota/Resultados-Comparecencia.jpeg')"
                                    target="_blank" rel="noopener noreferred">
                                    <img src="assets/img/iota/Resultados-Comparecencia.jpeg" class="d-block" alt="...">
                                </a>
                            </div>
                            <div class="carousel-item" data-interval="20000">
                                <a [href]="cleanURLResource('/assets/img/iota/Donacion-Taiwan.jpeg')" target="_blank"
                                    rel="noopener noreferred">
                                    <img src="assets/img/iota/Donacion-Taiwan.jpeg" class="d-block" alt="...">
                                </a>
                            </div>
                            <div class="carousel-item" data-interval="20000">
                                <a [href]="cleanURLResource('/assets/img/iota/Declaracion-Eta-1.jpg')" target="_blank"
                                    rel="noopener noreferred">
                                    <img src="assets/img/iota/Declaracion-Eta-1.jpg" class="d-block" alt="...">
                                </a>
                            </div>
                        </div>
                        <a class="carousel-control-prev" href="#carousel-articles" role="button" data-slide="prev">
                            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span class="sr-only">Previous</span>
                        </a>
                        <a class="carousel-control-next" href="#carousel-articles" role="button" data-slide="next">
                            <span class="carousel-control-next-icon" aria-hidden="true"></span>
                            <span class="sr-only">Next</span>
                        </a>
                    </div>
                </div>
            </div>
            <nav aria-label="breadcrumb" *ngIf="noticias?.length || news?.length">
                <ol class="breadcrumb pt-5">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{ "Noticias" | uppercase }}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-body-container mt-0" *ngIf="noticias?.length || news?.length">
                <div class="header-resoluciones">
                    <div class="card border-0" style="background: #F5F5F5 0% 0% no-repeat padding-box">
                        <div class="card-body rounded text-center">
                            <div class="row">
                                <div class="col-lg-6 my-3" (click)="switchLang('es')" role="button">
                                    <p class="card-text section-title" id="resoluciones"
                                        [ngClass]="{'active-lang': language === 'es'}">
                                        {{ "Español" | uppercase }}
                                    </p>
                                </div>
                                <div class="col-lg-6 my-3 margin-left-sb" (click)="switchLang('en')" role="button">
                                    <p class="card-text section-title" id="pronunciamientos"
                                        [ngClass]="{'active-lang': language === 'en'}">
                                        {{ "Inglés" | uppercase }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container [ngSwitch]="language">
                    <app-cards-display *ngSwitchCase="'es'"  [boundaryLinks]="boundaryLinks" [data]="noticias"
                        [page]="page" [pageSize]="pageSize" [maxSize]="maxSize" [rotate]="rotate" [size]="size">
                    </app-cards-display>
                    <app-cards-display *ngSwitchCase="'en'" [boundaryLinks]="boundaryLinks" [data]="news" [page]="page"
                        [pageSize]="pageSize" [maxSize]="maxSize" [rotate]="rotate" [size]="size">
                    </app-cards-display>
                </ng-container>
            </div>
        </div>
    </div>
</div>
<app-spinner [loading]="loading"></app-spinner>
import { Component, ViewChild, TemplateRef, OnInit, } from '@angular/core';
import { isSameDay, isSameMonth, } from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEventAction, CalendarView, } from 'angular-calendar';
import flatpickr from 'flatpickr';
import { Spanish } from 'flatpickr/dist/l10n/es'
import { Agend } from 'src/app/models/diary.model'
import { DiaryService} from 'src/app/services/diary.service'
import { AGEND_STATUS } from 'src/app/enums/enums';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

@Component({
  selector: 'app-agenda',
  templateUrl: './agenda.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./agenda.component.css'],
  providers:[DiaryService]
})
export class AgendaComponent implements OnInit {
  @ViewChild('modalContent', { static: true }) modalContent: TemplateRef<any>;

  public view: CalendarView = CalendarView.Month;
  public CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public Espanol = Spanish;
  public status:  string;
  public agend:   Agend[];
  public token:   string;
  public agenda:  Agend; 
  public diaryEvents: Agend[];
  public colors = {
    amarillo:{
      primary: '#f9cf48',
      secondary: '#f9cf48'
    },
    verde: {
      primary: '#139e4b',
      sencondary: '#139e4b'
    },
    naranja: {
      primary: '#ef6100',
      sencondary: '#ef6100'
    },
    azul: {
      primary: '#475c96',
      secondary: '#475c96'
    }, 
    gris:{
      primary: '#e2e2e2',
      secondary: '#e2e2e2'
    }
  } 
  public colorslbl: any = [
    { name: "amarillo", type:"Comisiones" },
    { name: "verde", type: "Bancada nacional" },
    { name: "naranja", type:"Asamblea Plenaria" },
    { name: "azul", type: "Elecciones JD"  },
    { name: "gris", type: "Otros"  },
  ]

  modalData: {
    action: string;
    event: Agend;
  };

  actions: CalendarEventAction[] = [
    {
      label: '<i class="fas fa-fw fa-pencil-alt"></i>',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: any }): void => {
        this.handleEvent('Edited', event);
      },
    }, {
      label: '<i class="fas fa-fw fa-trash-alt"></i>',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: any }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh: Subject<any> = new Subject();
  events: Agend[] = [];
  activeDayIsOpen: boolean = true;

  constructor(private modal: NgbModal, private _agendService: DiaryService) {
    this.agenda = new Agend('', new Date(), new Date(), '', '', '', '', false, '', AGEND_STATUS.ACTIVE);
    this.listDiary();
  }

  ngOnInit() {
    flatpickrFactory();
    registerLocaleData(localeEs);
  }

  dayClicked({ date, events }: { date: Date; events: Agend[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        this.activeDayIsOpen = false;
      } else {
        this.activeDayIsOpen = true;
      }
      this.viewDate = date;
    }
  }

  eventTimesChanged({ event, newStart, newEnd }): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: Agend): void {
    this.modalData = { event, action };
    this.modal.open(this.modalContent, { size: 'lg' });
  }

  addDiary(): void {
    this.agenda.allDay = false;
    this.agenda.actions = this.actions;
    this._agendService.addDiary(this.agenda).subscribe(
      response => {
        if (response.agend) {
          console.log(response.agend);
          this.status = 'ok';
        }
      },
      error => {
        var errorMessage = error;
        console.log(errorMessage);
        if (errorMessage != null) {
          this.status = 'error'
        }
      }
    );
  } 

  listDiary() {
    this._agendService.listDiary().subscribe(
      res => {
        if (res.agend) {
          this.diaryEvents = res.agend;
          this.diaryEvents.forEach(event => {
            event.start = new Date(event.start);
            event.end = new Date(event.end);
          })
          this.events = <any>this.diaryEvents;
          console.log(this.events)
          this.status = 'ok';
        }
      }, error => {
        var errorMessage = error;
        console.log(errorMessage);
        if (errorMessage != null) {
          this.status = 'error'
        }
      }
    )
  }

  // deleteEvent(eventToDelete: Agend) {
  //   this.events = this.events.filter((event) => event !== eventToDelete);
  // }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  } 

  deleteEvent(id: string) {
    if (confirm("¿esta seguro de que quiere eliminar?")) {
      console.log('el ', id);
      this._agendService.deleteDiary(id).subscribe(
        response => {
          console.log(response)
          if (response.agenda) {

            this.status = "ok"
            this.listDiary();
          }
        }, error => {
          var errorMessage = error
          console.log(errorMessage);
          if (errorMessage != null) {
            this.status = 'error'
          }
        }
      )
    }
    this.listDiary();
  }
}

export function flatpickrFactory() {
  flatpickr.localize(Spanish)
  return flatpickr;
} 
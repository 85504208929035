<div class="marco-politico-juridico">
    <div id="carouselIndicators" class="carousel slide" data-ride="carousel">
        <ol class="carousel-indicators">
            <li data-target="#carouselIndicators" data-slide-to="0" class="active"></li>
            <li data-target="#carouselIndicators" data-slide-to="1"></li>
            <li data-target="#carouselIndicators" data-slide-to="2"></li>
        </ol>
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img src="assets/img/Marco-politico-y-juridico-1.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/Marco-politico-y-juridico-1.png 1x, assets/img/Marco-politico-y-juridico-1.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/PCSliderExpandido2400x1078.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/PCSliderExpandido2400x1078.png 1x, assets/img/PCSliderExpandido2400x1078.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
            <div class="carousel-item">
                <img src="assets/img/PCSliderExpandido2400x1078.png" class="d-block w-100 carousel-img"
                    srcset="assets/img/PCSliderExpandido2400x1078.png 1x, assets/img/PCSliderExpandido2400x1078.png 2x"
                    data-interval="10000" alt="Banderas izadas" loading="lazy">
            </div>
        </div>
        <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
        </a>
        <div class="carousel-text">
            <h3 class="display-title">
                {{ "political_legal_framework.carousel.text" | translate }}
            </h3>
        </div>
    </div>

    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        {{ "political_legal_framework.nav.home" | translate | uppercase }}
                    </li>
                    <li class="breadcrumb-item quote-transform">
                        {{ "political_legal_framework.nav.about" | translate | uppercase }}
                    </li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">
                            {{ "political_legal_framework.nav.political_legal_framework" | translate | uppercase }}
                        </a>
                    </li>
                </ol>
            </nav>
            <div class="section-title-container">
                <h3 class="section-title">
                    {{ "political_legal_framework.novelties.title" | translate | uppercase }}
                </h3>
            </div>
            <div class="row section-body-container text-center">
                <div class="col-lg-3">
                    <div class="card novedad">
                        <div class="card-body novedad-b">
                            <img src="assets/svg/EsquipulasAzul.svg" class="novedad-img align-self-center"
                                alt="Ícono de Historia" width="34" height="65" loading="lazy">
                            <h2 class="novedad-h">
                                {{ "political_legal_framework.novelties.card-1.title" | translate | uppercase }}
                            </h2>
                        </div>
                        <div class="card-footer">
                            <p class="novedad-f">
                                <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                                    <a class="nav-link" href="assets/pdf/Parlacen-esquipulas I- 1986.pdf">
                                        {{ "political_legal_framework.novelties.card-1.btn" | translate | uppercase }}
                                    </a>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card novedad">
                        <div class="card-body nobedad-b">
                            <img src="assets/svg/EsquipulasAzul.svg" class="novedad-img align-self-center"
                                alt="Ícono de Marco Jurídico y Político" width="34" height="65" loading="lazy">
                            <h2 class="novedad-h">
                                {{ "political_legal_framework.novelties.card-2.title" | translate | uppercase }}
                            </h2>
                        </div>
                        <div class="card-footer">
                            <p class="novedad-f">
                                <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                                    <a class="nav-link" href="assets/pdf/Parlacen-esquipulas II-1987.pdf">
                                        {{ "political_legal_framework.novelties.card-2.btn" | translate | uppercase }}
                                    </a>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card novedad">
                        <div class="card-body">
                            <img src="assets/svg/TratadoAzul.svg" class="novedad-img align-self-center"
                                alt="Ícono de Documentos Parlamentaria" width="26" height="65" loading="lazy">
                            <h2 class="novedad-h">
                                {{ "political_legal_framework.novelties.card-3.title" | translate | uppercase }}
                            </h2>
                        </div>
                        <div class="card-footer">
                            <p class="novedad-c">
                                <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                                    <a class="nav-link " href="assets/pdf/Parlacen-Tratado Constitutivo.pdf">
                                        {{ "political_legal_framework.novelties.card-3.btn" | translate | uppercase }}
                                    </a>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="card novedad">
                        <div class="card-body">
                            <img src="assets/svg/TratadoAzul.svg" class="novedad-img align-self-center"
                                alt="Ícono de Documentos Parlamentaria" width="26" height="65" loading="lazy">
                            <h2 class="novedad-h">
                                {{ "political_legal_framework.novelties.card-4.title" | translate | uppercase }}
                            </h2>
                        </div>
                        <div class="card-footer">
                            <p class="novedad-c">
                                <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                                    <a class="nav-link " href="assets/pdf/ACUERDO_SEDE_PARLACEN.pdff">
                                        {{ "political_legal_framework.novelties.card-4.btn" | translate | uppercase }}
                                    </a>
                                </button>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="tratado-constitutivo">
        <div class="container-lg">
            <div class="my-container d-flex pb-0">
                <div class="row pb-5">
                    <div class="col-lg-6 row ">
                        <div class="col-lg-12 mb-5">
                            <div class="section-title-container">
                                <h3 class="section-title text-white">
                                    {{ "political_legal_framework.read_constitutive_treaty.title" | translate |
                                    uppercase }}
                                </h3>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="px-3 ">
                                <h3 class="text-over-img">
                                    {{ "political_legal_framework.read_constitutive_treaty.text" | translate }}
                                </h3>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="px-3 pt-3">
                                <button type="button" class="btn btn-dark ver-aqui">
                                    <a class="nav-link text-white" href="assets/pdf/Modificaciones al Tratado.pdf">
                                        {{ "political_legal_framework.read_constitutive_treaty.btn" | translate |
                                        uppercase }}
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container-lg">
        <div class="my-container">
            <div class="section-title-container pt-5">
                <h3 class="section-title">
                    {{ "political_legal_framework.read_more_about_PARLACEN.title" | translate | uppercase }}
                </h3>
            </div>
            <div class="row reglamento text-center">
                <div class="col-lg-6 pt-3">
                    <img src="assets/svg/Reglamento InternoAzul.svg" class="info-img" alt="Ícono de Misión" width="25"
                        height="30" loading="lazy">
                    <h2 class="info-h">
                        {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.title" |
                        translate }}
                    </h2>
                    <p class="info-text">
                        {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.text" |
                        translate }}

                    </p>
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                        <a class="nav-link" href="assets/pdf/Reglamento_Inteno_2022_PARLACEN.pdf">
                            {{ "political_legal_framework.read_more_about_PARLACEN.cards.rules_of_procedure.btn" |
                            translate | uppercase }}

                        </a>
                    </button>
                </div>
                <div class="col-lg-6 pt-3">
                    <img src="assets/svg/ReclamentoAzul.svg" class="info-img" alt="Ícono de Visión" width="25"
                        height="30" loading="lazy">
                    <h2 class="info-h">
                        {{ "political_legal_framework.read_more_about_PARLACEN.cards.special_regulation.title" |
                        translate }}
                    </h2>
                    <p class="info-text">
                        {{ "political_legal_framework.read_more_about_PARLACEN.cards.special_regulation.text" |
                        translate }}
                        <br>
                        <br>
                        <br>
                    </p>
                    <button type="button" class="btn btn-outline-dark outline-ver-aqui">
                        <a class="nav-link" href="assets/pdf/Reglamento-Especial-Parlacen.pdf">
                            {{ "political_legal_framework.read_more_about_PARLACEN.cards.special_regulation.btn" |
                            translate | uppercase }}
                        </a>
                    </button>
                </div>
            </div>
            <div class="section-title-container pt-5">
                <h3 class="section-title">
                    {{ "political_legal_framework.know_more.title" | translate | uppercase }}
                </h3>
            </div>
            <div class="section-body-container">
                <div class="row conocer-mas text-white">
                    <div class="col-lg-6 text-center align-self-center ti-text">
                        <p class="ti-t">
                            {{ "political_legal_framework.know_more.ti_text" | translate }}
                        </p>
                        <button type="button" class="btn btn-primary ti-btn" routerLink="/resoluciones">
                            {{ "political_legal_framework.know_more.ti_btn" | translate | uppercase }}
                        </button>
                    </div>
                    <div class="col-lg-6 p-0 ti-image">
                        <img src="assets/img/Marco-politico-y-juridico-2.png" width="100%" class="ti-img"
                            alt="Diputados del Parlamento Centroamericano" loading="lazy"
                            srcset="assets/img/Marco-politico-y-juridico-2.png 1x, assets/img/Marco-politico-y-juridico-2.png 2x">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
export enum NEWS_STATUS {
  ACTIVE    = 'ACTIVE',
  INACTIVE  = 'INACTIVE',
}

export enum NEWS_TYPES {
  GENERAL = 'GENERAL',
  COVID19 = 'COVID 19',
  IOTA    = 'IOTA',
}

export enum USER_ROLES {
  SYSTEMS       = "SISTEMAS",
  ADMIN         = "ADMIN",
  SUPER_ADMIN   = "SUPER_ADMIN",
  COMUNICATIONS = "COMUNICACIONES",
  SECRETARY     = "SECRETARIA",
}

export enum PLENARY_DOCS_STATUS {
  ACTIVE    = 'ACTIVO',
  INACTIVE  = 'INACTIVO',
}

export enum PLENARY_DOCS_TYPES {
  RESOLUTIONS     = 'RESOLUCIONES',
  DECLARATIONS    = 'DECLARACIONES',
  PRONOUNCEMENTS  = 'PRONUNCIAMIENTOS',
  PROCEEDINGS     = 'ACTAS',
  REPORTS         = 'INFORMES',
  ANY             = 'NINGUNO',
}

export enum PARLACEN_TV_STATUS {
  ACTIVE    = 'ACTIVO',
  INACTIVE  = 'INACTIVO',
}

export enum USER_STATUS {
  ACTIVE    = 'ACTIVO',
  INACTIVE  = 'INACTIVO',
}
export enum AGEND_STATUS {
  ACTIVE    = 'ACTIVO',
  INACTIVE  = 'INACTIVO',
}
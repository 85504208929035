import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global.service';

@Injectable({
  providedIn: 'root'
})
export class DiputiesService {
  token: string;
  url: string;

  constructor(
    private _httpClient: HttpClient
  ) {
    this.url = GLOBAL.API.url;
  }

  listarDiputadosBancada(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._httpClient.get(this.url + 'bancadas/diputados', { headers });
  }

  listarDiputadosComision(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._httpClient.get(this.url + 'comisiones/diputados', { headers });
  }

  listarDiputadosEurolat(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._httpClient.get(this.url + 'eurolat/diputados', { headers });
  }

  listarDiputadosGrupo(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._httpClient.get(this.url + 'grupos-parlamentarios/diputados', { headers });
  }
  listarDiputadosBlock(): Observable<any> {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._httpClient.get(this.url + 'bloque-de-mujeres/diputados', { headers });
  }
  search: (ind: string) => Observable<any> = ind => {
    const headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._httpClient.get(`${this.url}search/${ind}`, { headers: headers });
  }
}

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { GLOBAL } from './global.service';
import { UserService } from './user.service';
import { Agend } from '../models/diary.model';


@Injectable({
  providedIn: 'root'
})
export class DiaryService {
  private url:      string;
  private identity: any;
  private token:    string;
  private contType = {
    h: 'Content-Type',
    b: 'application/json',
  }
  private auth = {
    h: 'Authorization',
    b: '',
  }
  constructor(private _http: HttpClient, private _userService: UserService) {
    this.url = GLOBAL.API.url; 
    this.auth.b = _userService.getToken();
  }

  listDiary: () => Observable<any> = () => {
    let headers = new HttpHeaders().set('Content-Type', 'application/json');
    return this._http.get(`${this.url}diary`, { headers: headers });
  }

  updateDiary: (newsId: string) => Observable<any> = (agendId) => {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this._userService.getToken());
    return this._http.put(`${this.url}/admin/diary/${agendId}`, { headers: headers });
  }

  addDiary: (agend: Agend) => Observable<any> = agend => {
    let params = JSON.stringify(agend);
    let headers = new HttpHeaders().set(this.contType.h, this.contType.b).set(this.auth.h, this.auth.b);
    return this._http.post(`${this.url}admin/diary`, params, { headers: headers });
  }

  deleteDiary(agendId: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-Type', 'application/json').set('Authorization', this._userService.getToken());
    return this._http.delete(`${this.url}admin/diary/${agendId}`, { headers: headers });
  }

}

import { Component, OnDestroy, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NEWS_TYPES } from 'src/app/enums/enums';
import { IImage } from 'src/app/interfaces/i-image';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/news.service';
declare var $: any;

@Component({
  selector: 'app-concurso-dos',
  templateUrl: './concurso.component.html',
  styleUrls: ['./concurso.component.css']
})
export class ConcursoDosComponent implements OnInit, OnDestroy {
  public bodySize: number = 0;
  loading: boolean = false;
  status: string;
  news: News[];
  noticias: News[];
  page: number = 1;
  pageSize: number = 6;
  boundaryLinks: boolean = true;
  rotate: boolean = true;
  size: string = 'sm';
  maxSize: number = 3;
  language: 'es' | 'en' = 'es';
  images: IImage[] = [
    {
      src: 'assets/img/PCSliderExpandido2400x1078.png',
      srcset: 'assets/img/PCSliderExpandido2400x1078.png x1, assets/img/PCSliderExpandido2400x1078.png x2',
      alt: 'Imagen de concurso',
    },
    {
      src: 'assets/img/PCSliderExpandido2400x1078.png',
      srcset: 'assets/img/PCSliderExpandido2400x1078.png x1, assets/img/PCSliderExpandido2400x1078.png x2',
      alt: 'Imagen de concurso'
    },
  ];
  constructor(
    private _newsService: NewsService,
    protected _sanitizer: DomSanitizer,
  ) { }


  ngOnDestroy(): void {
    window.removeEventListener("resize", this.onResize);
  }

  onResize(): void {
    this.bodySize = window.screen.width;
  }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.bodySize = window.screen.width;
    window.addEventListener("resize", this.onResize);
  }


  listIotaNews(): void {
    this.loading = true;
    this._newsService.listNewsByType(NEWS_TYPES.IOTA).subscribe(
      res => {
        if (!res.news) {
          this.status = 'error';
        } else {
          if (res.news?.length === 0) {
            // SOMETHING
          } else {
            this.news = res.news.filter((noticia: any) => noticia.lang == 'en');
            this.noticias = res.news.filter((noticia: any) => noticia.lang == 'es');
            this.news = this.sortArray(this.news);
            this.noticias = this.sortArray(this.noticias);
            console.log({ noticias: this.noticias, news: this.news });
          }
          this.status = 'ok';
        }
        this.loading = false;
      },
      err => this.onError(err),
    );
  }

  switchLang(lang: 'es' | 'en'): void {
    this.language = lang;
  }

  sortArray(arrayObj: any[]): any[] {
    return arrayObj.sort((a, b) =>
      (b.creationDate + b.creationTime).localeCompare(a.creationDate + a.creationTime)
    );
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.loading = false;
  }

  cleanURLResource(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

}

import { ModuleWithProviders } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./components/home/home.component";
import { AcercaDeComponent } from "./components/acerca-de/acerca-de.component";
import { ContactoComponent } from "./components/contacto/contacto.component";
import { HistoriaComponent } from "./components/historia/historia.component";
import { InformesComponent } from "./components/informes/informes.component";
import { OrganizacionComponent } from "./components/organizacion/organizacion.component";
import { ParlacenTvComponent } from "./components/parlacen-tv/parlacen-tv.component";
import { PlenariaComponent } from "./components/plenaria/plenaria.component";
import { MarcoPoliticoJuridicoComponent } from './components/marco-politico-juridico/marco-politico-juridico.component';
import { BloqueMujeresParlamentariasComponent } from './components/bloque-mujeres-parlamentarias/bloque-mujeres-parlamentarias.component';
import { ComisionesComponent } from './components/comisiones/comisiones.component';
import { JuntaDirectivaComponent } from './components/junta-directiva/junta-directiva.component';
import { AsambleaComponent } from './components/asamblea/asamblea.component';
import { GruposParlamentariosComponent } from './components/grupos-parlamentarios/grupos-parlamentarios.component';
import { EurolatComponent } from './components/eurolat/eurolat.component';
import { Covid19Component } from './components/covid19/covid19.component';
import { NoticiasComponent } from './components/noticias/noticias.component';
import { DiputadosComponent } from './components/diputados/diputados.component';
import { ListaDiputadosComponent } from "./components/lista-diputados/lista-diputados.component";
import { NoticiaComponent } from './components/noticias/noticia/noticia.component';
import { AgendaComponent } from './components/agenda/agenda.component';
import { NewsComponent } from "./components/news/news.component";
import { Error404Component } from "./components/error404/error404.component";
import { IotaComponent } from "./components/iota/iota.component";
import { ConcursoComponent } from "./components/concurso/concurso.component";
import { ConcursoDosComponent } from "./components/concurso-dos/concurso.component";
import { ConcursotresComponent } from "./components/concursotres/concursotres.component";
import { OtrosParlamentosComponent } from "./components/otros-parlamentos/otros-parlamentos.component";
import { SitemapComponent } from './components/sitemap/sitemap.component';
import { BoletinParlamentarioComponent } from "./components/boletin-parlamentario/boletin-parlamentario.component";
import { EleccionesComponent } from "./components/elecciones/elecciones.component";
import { ConvocatoriaComponent } from "./components/convocatoria/convocatoria.component";

const appRoutes: Routes = [
  { path: '', redirectTo: 'inicio', pathMatch: 'full' },
  { path: 'inicio', component: HomeComponent },
  { path: 'eurolat', component: EurolatComponent },
  { path: 'acerca-de', component: AcercaDeComponent },
  // { path: 'boletines', component: BoletinesComponent },
  { path: 'historia', component: HistoriaComponent },
  { path: 'informes-plenaria', component: InformesComponent },
  // { path: 'novedades', component: NovedadesComponent },
  { path: 'organizacion', component: OrganizacionComponent },
  { path: 'parlacen-tv', component: ParlacenTvComponent },
  { path: 'resoluciones', component: PlenariaComponent },
  { path: 'marco-politico-juridico', component: MarcoPoliticoJuridicoComponent },
  { path: 'bloque-mujeres-parlamentarias', component: BloqueMujeresParlamentariasComponent },
  { path: 'elecciones', component: EleccionesComponent },
  { path: 'elecciones-2023', component: EleccionesComponent },
  { path: 'comisiones', component: ComisionesComponent },
  { path: 'junta-directiva', component: JuntaDirectivaComponent },
  { path: 'buscar', component: BoletinParlamentarioComponent },
  { path: 'grupos-parlamentarios', component: GruposParlamentariosComponent },
  { path: 'asamblea-plenaria', component: AsambleaComponent },
  { path: 'agenda', component: AgendaComponent },
  { path: 'diputados', component: DiputadosComponent },
  { path: 'lista-diputados', component: ListaDiputadosComponent },
  { path: 'noticias', component: NoticiasComponent },
  { path: 'covid-19', component: Covid19Component },
  { path: 'iota', component: IotaComponent },
  { path: 'noticias/:id', component: NoticiaComponent },
  { path: 'news-en', component: NewsComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'mision-espacial', component: ConcursoComponent },
  { path: 'concurso-asturias', component: ConcursotresComponent },
  { path: 'convocatoria', component: ConvocatoriaComponent },
  { path: 'ruben-dario', component: ConcursoDosComponent },
  { path: 'other-parliaments', component: OtrosParlamentosComponent },
  { path: 'sitemap', component: SitemapComponent },
  { path: '**', component: Error404Component },

]
export const appRoutingProviders: any[] = [];
export const routing: ModuleWithProviders<any> = RouterModule.forRoot(appRoutes);

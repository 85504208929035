<div class="container-lg">
    <div class="my-container py-5">
        <div class="container py-5">
            <img src="assets/svg/AlertaIcono.svg" alt="Ícono de Alerta" width="100px">
            <div class="section-body-container">
                <h1 class="page-title">Error 404</h1>
                <p class="info-subtitle pt-3">
                    Página no encontrada.
                </p>
                <p class="info-text">
                    Parece que ha habido un error con la página que estabas buscando.<br>
                    Es posible que la entrada haya sido eliminado o que la dirección no exista.
                </p>
                <button class="btn btn-primary ti-btn mt-4" type="button" routerLink="/inicio">
                    {{ "Ir a Inicio" | uppercase }}
                </button>
            </div>
        </div>
    </div>
</div>
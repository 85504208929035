import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NEWS_TYPES } from 'src/app/enums/enums';
import { IImage } from 'src/app/interfaces/i-image';
import { News } from 'src/app/models/news.model';
import { NewsService } from 'src/app/services/news.service';
declare var $: any;

@Component({
  selector: 'app-covid19',
  templateUrl: './covid19.component.html',
  styleUrls: ['./covid19.component.css']
})
export class Covid19Component implements OnInit {
  status:     string;
  news:       News[];
  loading:    boolean = false;
  noticias:   News[];
  page:       number = 1;
  pageSize:   number = 3;
  page1:      number = 1;
  pageSize1:  number = 3;
  images:     IImage[] = [
    { src: 'assets/img/covid19.png', srcset: 'assets/img/covid19.png 1x, assets/img/covid19@2x.png 2x', alt: 'Banderas izadas' },
    { src: 'assets/img/covid19.png', srcset: 'assets/img/covid19.png 1x, assets/img/covid19@2x.png 2x', alt: 'Banderas izadas' },
    { src: 'assets/img/covid19.png', srcset: 'assets/img/covid19.png 1x, assets/img/covid19@2x.png 2x', alt: 'Banderas izadas' },
  ];

  constructor(
    private _newsService: NewsService,
    protected _sanitizer: DomSanitizer,
  ) { }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.listCovid19News();
  }

  listCovid19News(): void {
    this.loading = true;
    this._newsService.listNewsByType(NEWS_TYPES.COVID19).subscribe(
      res => {
        if (!res.news) {
          this.status = 'error';
        } else {
          if (res.news.length === 0) {
            alert('No hay noticias para mostrar');
          } else {
            this.news = res.news.filter((noticia: any) => noticia.lang == 'en');
            this.noticias = res.news.filter((noticia: any) => noticia.lang == 'es');
            this.news = this.sortArray(this.news);
            this.noticias = this.sortArray(this.noticias);
          }
          this.status = 'ok';
        }
        this.loading = false;
      },
      err => this.onError(err),
    );
  }

  sortArray(arrayObj: any[]): any[] {
    return arrayObj.sort((a, b) =>
      (b.creationDate + b.creationTime).localeCompare(a.creationDate + a.creationTime)
    );
  }

  onError: (error: any) => void = error => {
    var errorMessage = error;
    this.loading = false;
    if (errorMessage != null) {
      this.status = 'error';
    }
    alert(error.error.message || error.error);
  }

  cleanURLResource(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

  cleanURL(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustUrl(oldURL);
  }
}

import { Component, OnInit } from '@angular/core';

declare var $: any;
@Component({
  selector: 'app-otros-parlamentos',
  templateUrl: './otros-parlamentos.component.html',
  styleUrls: ['./otros-parlamentos.component.css']
})
export class OtrosParlamentosComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
  }

}

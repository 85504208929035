import { Component, OnInit, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-acerca-de',
  templateUrl: './acerca-de.component.html',
  styleUrls: ['./acerca-de.component.css']
})
export class AcercaDeComponent implements OnInit, OnDestroy {
  public bodySize: number = 0;
  public donwloads: string[] = [
    "http://www.parlacen.int/LinkClick.aspx?fileticket=wNINoDfG3yI%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=iOtkQ1jCLJM%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=OMEYLQs4wRM%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=eOLzr-lGuzU%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=0qE82MgBpPE%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=86HLURDgGxE%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=1Nj4bdn5jqA%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=Obnbb_nKQCk%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=4OmzrLHYJF4%3d&tabid=125&mid=615&forcedownload=true",
    "http://www.parlacen.int/LinkClick.aspx?fileticket=F30hoLaRZ1U%3d&tabid=125&mid=615&forcedownload=true",

  ];

  constructor(
    public translate: TranslateService
  ) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    this.bodySize = window.screen.width;
    window.addEventListener("resize", this.onResize);
    $('html,body').scrollTop(0);
  }

  ngOnDestroy(): void {
    window.removeEventListener("resize", this.onResize);
  }

  onResize(): void {
    this.bodySize = window.screen.width;
  }

  downloadTranslation(index: number): void {
    location.href = this.donwloads[index];
  }
  switchLang(lang: string) {
    this.translate.use(lang);
  }
}

import { Component, OnInit } from '@angular/core';
import { DiputiesService } from 'src/app/services/diputies.service';
declare var $: any;

@Component({
  selector: 'app-boletin-parlamentario',
  templateUrl: './boletin-parlamentario.component.html',
  styleUrls: ['./boletin-parlamentario.component.css']
})
export class BoletinParlamentarioComponent implements OnInit {
  busqueda: string;
  public status: string;
  public loading: boolean = false;
  public isSubmitting: boolean = false;
  public isDeleting: boolean = false;
  public isUpdating: boolean = false;
  public imgNews: boolean = false;
  public imgres: boolean = false;
  public imgplay: boolean = false;
  public rtnurl: string;

  resultados: string[];
  constructor(
    private _deputyService: DiputiesService
  ) { }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    /* this.busqueda = "solidaridad";
    this.listarDiputados(); */
  }

  listarDiputados(): void {
    /* this.loading = true; */
    this._deputyService.search(this.busqueda).subscribe(
      res => {
        if (res.results?.length <= 0) {
          this.status = 'error';
        } else {
          if (res.deputies?.length === 0) {
            alert('No hay diputados para mostrar');
          } else {
            this.resultados = res.results;
            console.log(this.resultados);
            /* console.log(this.deputies); */
            /* this.sortArray(this.deputies); */
            /* this.nosedos = this.deputy.parliamentaryGroups; */
            /* console.log(this.deputies) */
          }
          this.status = 'ok';
        }
      },
      err => this.onError(err)
    );

  }

  imagen(evaluar: string): boolean {

    if (evaluar === "News") {
      this.imgNews = true;
    }
    if (evaluar === "PlenaryDocuments") {
      this.imgNews = false;
    }
    if (evaluar === "ParlamentTv") {
      this.imgNews = false;
    }

    return this.imgNews;
  }

  imagen1(evaluar: string): boolean {
    if (evaluar === "News") {
      this.imgplay = false;
    }
    if (evaluar === "PlenaryDocuments") {
      this.imgplay = false;
    }
    if (evaluar === "ParlamentTv") {
      this.imgplay = true;
    }

    return this.imgplay;
  }

  imagen2(evaluar: string): boolean {

    if (evaluar === "News") {
      this.imgres = false;
    }
    if (evaluar === "PlenaryDocuments") {
      this.imgres = true;
    }
    if (evaluar === "ParlamentTv") {
      this.imgres = false;
    }

    return this.imgres;
  }
  direccion(cole: string, id: string, url: string): string {
    const opcion1: string = "News";
    const opcion2: string = "PlenaryDocuments";
    const opcion3: string = "ParlamentTv";

    let urlaretornar: string;
    /* console.log(cole);
    console.log(id);
    console.log(url); */
    switch (cole) {
      case opcion1: {
        this.rtnurl = `noticias/${id}`;
        break;
      }
      case opcion2: {
        this.rtnurl = `${url}`;
        break;
      }
      case opcion3: {
        this.rtnurl = `https://youtu.be/${url}`;
        break;
      }
    }
    return this.rtnurl;
  }
  onError(error: any): void {
    let errorMessage = error;
    alert("Error al ejecutar la acción");
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.isSubmitting = false;
    this.isDeleting = false;
    this.isUpdating = false;
  }


}

<div class="iota">
    <!--<div>
        <app-carousel [heightIota]="true" class="hola" [images]="images"></app-carousel>
    </div> -->

    
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb pt-5 pb-4">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">
                            {{ "CONCURSO Miguel Ángel Asturias " | uppercase
                            }}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-body-container">
                <div class="row">

                    <h2 class="section-header w-100">“MIGUEL ANGEL ASTURIAS 2023” 9a EDICIÓN</h2>
                    <h3> </h3>
                    <p>
                        <b>PRESENTACIÓN:</b>
                        </p>
                    <p>
                        El Parlamento Centroamericano es el órgano regional, permanente de representación política y democrática del Sistema de la Integración Centroamericana -SICA-, que tiene como objetivo fundamental la realización de la integración de Centroamérica, para consolidarla como Región de Paz, Libertad, Democracia y Desarrollo, promoviendo el cumplimiento de las metas y fines de las políticas públicas de educación en la región.
                    </p>
                    <p>
                        A través del Concurso Miguel Ángel Asturias 2023 9ª Edición, se rinde homenaje a este gran escritor motivando a conocer y resaltar la memoria de tan gran escritor, es primordial fomentar la creación artística y cultural de Centroamérica y República Dominicana.
                    </p>
                    <p>El escritor Miguel Ángel Asturias periodista y diplomático guatemalteco, es uno de los escritores que contribuyó al desarrollo de la literatura latinoamericana y llama la atención sobre la importancia de las culturas indígenas.
                    </p>
                    <p>Miguel Ángel Asturias es el único Premio Nobel en nuestra región, dentro de sus obras destacan:</p>
                    <br/>
                    <br/>
                    <br/>
                    <ol >
                        <li>El señor presidente</li>
                            <li>Hombres de Maíz</li>
                            <li>Viento fuerte</li>
                            <li>El papa verde</li>
                    </ol>
                    <p>Siendo su obra más importante Hombres de Maíz en sus diferentes escritos Miguel Ángel Asturias nos enseña que hay que contar la historia, aunque sea en sus rudezas.</p>
                    <p>
                        <b>ENTIDAD CONVOCANTE </b>
                        <br/>EL Parlamento Centroamericano a través de la Comisión de Educación, Cultura, Deporte, Ciencia y Tecnología.</p>
                    <p>
                        <b>OBJETIVO</b>
                        <br/>El Concurso tiene por objeto primordial para el Parlamento Centroamericano, promover la creación artística y cultural y relacionar la obra Hombres de Maíz con la realidad de comunidades indígenas, sus costumbres, música, arte y comidas ancestrales.</p>
                </div>
            </div>
            

            <div class="container-lg">
                <div class="section-body-container">
                    <div class="row mision-vision">
                        <div class="col-lg-6">
                            <h2 class="mision-h" loading="lazy">BASES DEL CONCURSO</h2>
                            <h3 class="mision-h premio" loading="lazy">I. ORGANIZACIÓN.</h3>
                            <div>
                                <b>I. ORGANIZACIÓN.</b>
                                <br/>El concurso “MIGUEL ANGEL ASTURIAS 2023” 9a EDICIÓN, se declarará abierto a partir del miércoles 03 de mayo hasta el domingo 30 de julio de 2023, a las 23 horas con 59 minutos.
                                <br/>El formulario de inscripción estará disponible en el siguiente vínculo: https://parlacen.int/concurso-asturias los interesados deberán ingresar al link correspondiente, en la página web y redes sociales del PARLACEN, en donde estarán adjuntas las bases del concurso.
                            </div>
                            <div><b>II. TÉRMINOS Y CONDICIONES DE PARTICIPACIÓN.</b>
                                <br/>Para intervenir en el CONCURSO, cada participante, deberá presentar un proyecto audiovisual consiste en lo siguiente:
                                <br/>1. Proyecto audiovisual de cinco a siete 5/7 minutos conteniendo entrevistas con miembros de la comunidad indígena en español o en idiomas autóctonos (acompañada de su respectiva traducción al español).
                                <br/>2. Los temas del proyecto deben cubrir:
                                <ul>
                                    <li>a. Aspectos culturales de la comunidad indígena.    </li>
                                    <li>b. Narración a viva voz de los miembros de la comunidad para transmitir su realidad vigente. </li>
                                    <li>c. Considerar bailes, música, gastronomía, tradiciones. </li>
                                    <li> d. Fotografías Antiguas.</li>
                                </ul>
                                3. El Autor debe concluir con análisis de lo contenido en la novela de Hombres de Maíz y la realidad de la comunidad.
                                <br/>4. Los trabajos estarán dirigidos a la Comisión de Educación, Cultura, Deporte, Ciencia y Tecnología.
                                <br/>Se verificará por parte del jurado, que el mismo no haya sido premiado con antelación y no estar en proceso edición. Se descalificará sin responsabilidad alguna para el Parlamento Centroamericano cualquier video audio visual que no cumpla con tales requisitos.
                                <br/>Los participantes menores de edad deberán contar con autorización escrita de uno de sus padres o tutor legal, debiendo expresar, al inscribirse la aceptación de sujetarse a las bases del presente concurso y acatar la decisión del jurado.
                            </div>
                            <div>
                                <b>III. PARTICIPANTES</b>
                                <br/>El concurso estará dirigido a: Jóvenes, Educadores y Folckloristas de los países que integran el Sistema de la Integración Centroamericana.
                                <br/>El concurso libre donde participen Jóvenes, Educadores y Folckloristas que permitan a las autoridades de los países del Sistema de la Integración Centroamericana -SICA- (Guatemala, El Salvador, Honduras, Nicaragua, Panamá, República Dominicana, Belice y Costa Rica), fomentar por medio de un video audio visual que refleje los aspectos culturales de comunidades indígenas.                                
                            </div>
                            <div><b>IV. CONDICIONES TÉCNICAS</b>
                                <br/>1. El proyecto de los videos deben tener una duración de 5/7 minutos video para entrega, calidad mp4, resolución 1920 x 1080p full HD formato horizontal.
                                <br/>2. Para la calificación de los proyectos audiovisual se tomará en cuenta los términos de los participantes.
                                <br/>3. Estrictamente que las producciones sean de calidad.
                                <br/>4. Las grabaciones deben ser claras, nítidas y en discos nuevos.
                                <br/>El jurado verificará la originalidad de los proyectos audiovisual presentado, y descalificará cualquier video que no cumpla los requisitos.
                            </div>
                            <div><b>V. REQUISITOS DE ENTREGA DEL VIDEO AUDIO VISUAL</b>
                                <br/>1. Recepción del proyecto audiovisual al correo concursoasturias@parlacen.int y enviar vía wetransfer.com a: concursoasturias@parlacen.int, puede ser entregado en físico en la Sede Guatemala o Subsede de cada Estado Parte del PARLACEN.
                                <br/>2. Los participantes tendrán hasta el domingo 30 de julio de 2023, a las 23 horas con 59 minutos para enviar el proyecto audiovisual.
                                <br/>3. La presentación del proyecto audiovisual en español o en idiomas autóctonos (acompañadas de su respectiva traducción al español), deberá contener los siguientes requisitos adjuntados al formulario de inscripción:
                                <ul>
                                    <li>El proyecto audiovisual</li>
                                    <li>Portada únicamente el título del proyecto audiovisual</li>
                                    <li>Pseudónimo del autor o autora y la siguiente leyenda: Concurso Miguel Ángel Asturias 2023, 9a Edición del Parlamento Centroamericano.</li>
                                </ul>
                                4. Adjunto al proyecto audiovisual, deberá enviar otro documento en formato PDF, que contendrá la identificación del autor, con los siguientes datos: Pseudónimo, título de la obra, nombre completo del autor o autora, breve reseña biográfica, nacionalidad, institución en que estudia o labora, dirección actual de su residencia, teléfonos, correo electrónico, copia del documento de identidad y fotografía reciente. La ausencia de esta información será causal de descalificación del trabajo respectivo.
                            </div>
                             
                            
                        </div>
                        <div class="col-lg-6">
                            <div>
                                <b>VI. JURADO CALIFICADOR Y CRITERIOS DE EVALUACIÓN DEL PROYECTO AUDIOVISUAL</b>
                                <br/>1. El Jurado Calificador estará integrado por tres personas con las cualidades siguientes:
                                <ul>a. Ser conocedora de uso audiovisual.
                                <br/>b. Historiador Escritor de reconocido prestigio en los países parte que integran el Parlamento Centroamericano y será designado por los miembros de la Comisión de Educación, Cultura, Deporte, Ciencia y Tecnología.
                                </ul>
                                2. El criterio de evaluación a ser considerados será: Originalidad que refleje los aspectos culturales de comunidades indígenas de Centroamérica y República Dominicana.
                                <br/>3. El Jurado Calificador dará a conocer su dictamen a la Comisión de Educación, Cultura, Deporte, Ciencia y Tecnología el lunes 21 de agosto de 2023.
                                <br/>4. El jurado calificador decidirá por tres proyectos audiovisuales
                            </div>
                                <div><b>VII. PREMIO ÚNICO</b>
                                    <br/>1. La premiación se realizará el martes 22 de septiembre de 2023, los premios serán entregados en la ciudad de Guatemala, en la Sede del Parlamento Centroamericano o en las subsedes de los Estados Miembros del PARLACEN, según la nacionalidad de los ganadores.
                                    <br/>2. Se otorgará un Premio Único para Jóvenes, Educadores y Folckloristas que resulten ganadores en su respectivo lugar, atendiendo el nivel de complejidad.
                                <ul>
                                    <li>PRIMER LUGAR: USD 2000 (dos mil dólares).</li> 
                                    <li>SEGUNDO LUGAR: USD 1500 (mil quinientos dólares).</li>
                                    <li>TERCER LUGAR: USD 1000 (mil dólares).</li>
                                    <li>MENCIÓN ESPECIAL: USD 500 (quinientos dólares).</li>
                                </ul>
                            </div>
                            <div><b>VIII. PROPIEDAD INTELECTUAL (CESIÓN DE DERECHOS).</b>
                                <br/> 1. Los Proyectos Audiovisuales premiados serán publicados en la página web del Parlamento Centroamericano, así como en los medios de comunicación y redes sociales que considere oportunos.
                                <br/>2. La autoría es únicamente del propietario del proyecto audiovisual, y debe ser siempre comunicada al momento de realizar algún acto o actividad pública o a través de redes sociales u otros medios de comunicación. Los participantes también acuerdan con el Parlamento Centroamericano, no cometer actos de comunicación que puedan constituir una violación de los derechos morales de los participantes. Todos los participantes del concurso “MIGUEL ANGEL ASTURIAS 2023”, 9ª EDICIÓN aceptan: a). Informar a cualquier empresa, organización, institución gubernamental o no gubernamental, que pretenda hacer el uso del proyecto audiovisual presentado al presente concurso, de los derechos otorgados sobre el mismo al Parlamento Centroamericano, esto con el objeto de evitar disputa legal sobre su uso. b). Mantener indemne, excluir y eximir a “EL PARLACEN” de cualquier demanda y conflicto frente a terceros,
                                por el uso de los proyectos audiovisuales y la publicidad, la presentación, el contenido en Internet, comercial o cualquier otro material producido posteriormente, presentado y preparado por o en nombre del PARLACEN, que puedan afectar los derechos del proyecto audiovisual de los participantes o el PARLACEN.
                                <br/>3. Los participantes dan por entendido y aceptan el uso que “EL PARLACEN” haga del proyecto audiovisual sin limitación alguna, por lo tanto no obliga a “EL PARLACEN” a negociar con el participante, ni a otorgar ningún derecho o compensación.
                                <br/>4. Los participantes aceptan y reconocen que nada impide que “EL PARLACEN” utilice cualquier material o material similar a la presentación sin tener la obligación de comunicarlo al participante, incluyendo, pero sin limitarse al caso en que el organizador determine que tiene un derecho independiente de utilizar cualquier material por motivos que incluyen, pero no se limitan al hecho de que estos elementos o características no son nuevos u originales del participante, o fueron o pudieron haber sido creados de manera independiente por un tercero o presentados a los socios.
                                <br/>5. Los participantes reconocen que otros concursantes pueden haber utilizado en su presentación ideas o conceptos que podrían ser similares. El participante da por entendido y acepta que no tiene derecho a presentar demanda alguna o requerimiento de indemnización contra otro concursante, u organizador con respecto a alguna idea o concepto similar.
                                <br/>6. El PARLACEN podrá publicar la descripción del proyecto audiovisual recibido en cualquier medio de comunicación/red social que crea conveniente. En todo caso, el PARLACEN hará mención del autor o autores del video, implica el reconocimiento de la autoría y la cesión de reproducción del video audio visual ganador por un plazo de tres años a partir de la premiación, el Parlamento Centroamericano hará publicación, impresión, difusión y presentación a nivel mundial sin fines de lucro, en ningún caso con fines comerciales.
                                <br/>7. Los concursantes que no hayan ganado podrán a su vez utilizar sus proyectos audiovisuales en otros concursos a nivel mundial. Lo anterior sin perjuicio de “EL PARLACEN” a utilizar el proyecto audiovisual para fines no comerciales.
                                <br/>8. “El PARLACEN” se reserva el derecho a mantener la descripción del proyecto audiovisual ganador publicadas en la web o distintos medios de “EL PARLACEN”, el tiempo que considere oportuno, y de su uso para fines educativos y de sensibilización.
                            </div>   
                             
                        </div>
                    </div>
                </div>
            </div>

            <div class="section-body-container">
                <div class="row">
                    <p>El hecho de concurrir a la convocatoria implica la sujeción a las normas del Concurso Centroamericano y de República Dominicana en la Modalidad “Miguel Ángel Asturias 2023”, 9ª Edición.
                    <p>Ciudad de Guatemala, Guatemala, 25 de abril de 2023.</p>
                </div>
                <div class="row">
                    <img src="assets/img/Concurso 23 (3).jpg" class="img-fluid d-block" alt="...">
                </div>
            </div>
            
            <div class="section-title-container">
                <h3 class="section-title">
                    {{ "" | translate | uppercase }}
                </h3>
            </div>
            
            <div class="btn-descargar-folleto">
                <div class="row text-center">
                    <div class="col-lg-4">
                        <div class="card">
                            <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                                height="40px">
                            <div class="card-body">
                                <h3 class="card-title">
                                    Formulario
                                </h3>
                                <p class="info-text">
                                    Descarga el formulario de inscripción aca
                                </p>
                                <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                    <a class="nav-link" href="../../../assets/pdf/Formulario.pdf"
                                        target="_blank" download="Formulario concurso RB2022">
                                        Descargar
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="card">
                          <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                              height="40px">
                          <div class="card-body">
                              <h3 class="card-title">
                                  Convocatoria
                              </h3>
                              <p class="info-text">
                                BASES DE LA CONVOCATORIA
                              </p>
                              <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                  <a class="nav-link" href="../../../assets/pdf/Bases_ConcursoMiguelAngelAsturias2023_25-04-2023.pdf"
                                      target="_blank" download="BASES DEL CONCURSO RB2022">
                                      Descargar
                                  </a>
                              </button>
                          </div>
                      </div>
                  </div>
                </div>
            </div>

        </div>
    </div> 

</div>

<div id="carouselIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
        <li *ngFor="let image of images; let i = index;" data-target="#carouselIndicators"
            class="{{i===0 ? 'active' : ''}}" [attr.data-slide-to]="i.toString()">
        </li>
    </ol>
    <div class="carousel-inner" [ngClass]="{'image-iota': heightIota}">
        <div *ngFor="let image of images; let i = index;" class="carousel-item{{i===0 ? ' active' : ''}}">
            <img [src]="image.src" class="d-block w-100 carousel-img" [srcset]="" [attr.data-interval]="interval"
                [alt]="image.alt" loading="lazy" [ngClass]="{'reduce-brightness': reduceBrightness}" >
        </div>
    </div>
    <a class="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="sr-only">Next</span>
    </a>
    <div class="carousel-text" *ngIf="!textImg || title || text || buttonOptions">
        <img *ngIf="textImg" [src]="textImg" alt="Logo del Parlamento Centroamericano" class="pb-4 img" loading="lazy">
        <h3 *ngIf="title" class="page-title text-white" [innerHTML]="title"></h3>
        <p *ngIf="text" class="display-text" [innerHTML]="text">
        </p>
        <button *ngIf="buttonOptions" type="button" class="btn btn-primary btn-leer-mas buttonHome"
            [routerLink]="buttonOptions.link">
        </button>
    </div>
</div>
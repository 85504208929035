import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { News } from 'src/app/models/news.model';

@Component({
  selector: 'app-cards-display',
  templateUrl: './cards-display.component.html',
  styleUrls: ['./cards-display.component.css']
})
export class CardsDisplayComponent implements OnInit {
  @Input() data: News[];
  @Input() page: number;
  @Input() pageSize: number;
  @Input() boundaryLinks: boolean;
  @Input() maxSize: number;
  @Input() rotate: boolean;
  @Input() size: string;

  constructor(
    protected _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  cleanURLResource(oldURL: string): SafeResourceUrl {
    return this._sanitizer.bypassSecurityTrustResourceUrl(oldURL);
  }

}

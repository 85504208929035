import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MemberService } from 'src/app/services/member.services';
import { Member } from 'src/app/models/member.model';
import { DomSanitizer } from '@angular/platform-browser';
import { Deputies } from 'src/app/models/deputy.model';
import { DiputiesService } from 'src/app/services/diputies.service';
declare var $: any;

@Component({
  selector: 'app-grupos-parlamentarios',
  templateUrl: './grupos-parlamentarios.component.html',
  styleUrls: ['./grupos-parlamentarios.component.css']
})
export class GruposParlamentariosComponent implements OnInit {
  members: Member[];
  status: string;
  loading: boolean = false;
  memebers1: any = {}
  pg_GPICD: Member[];
  pg_GPI: Member[];
  pg_UDI: Member[];
  public deputies: Deputies[] = [];
  public deputiesGPICD: Deputies[] = [];
  public deputiesGPI: Deputies[] = [];
  public deputiesUDI: Deputies[] = [];
  public deputy: Deputies;

  constructor(
    public translate: TranslateService,
    private _membersService: MemberService,
    private _deputyService: DiputiesService,
  ) {
    translate.addLangs(['es', 'en']);
    translate.setDefaultLang('es');
  }

  ngOnInit(): void {
    $('html,body').scrollTop(0);
    this.listarDiputados();
    this.listarDiputadosGrupos();
  }

  listarDiputados(): void {
    this.loading = true;
    this._membersService.listMember().subscribe(
      res => {
        if (!res.member) {
          this.status = 'error';
        } else {
          if (res.member.length === 0) {
            alert('No hay documentos para mostrar');
          } else {
            this.members = res.member;
            this.pg_GPICD = this.members?.filter((member) => member.pg === "GRUPO PARLAMENTARIO INTEGRACIÓN CENTRO DEMOCRÁTICO");
            this.pg_GPI = this.members?.filter((member) => member.pg === "GRUPO PARLAMENTARIO DE IZQUIERDA");
            this.pg_UDI = this.members?.filter((member) => member.pg === "UNIDAD DEMOCRÁTICA INTEGRACIONISTA");
            this.pg_GPICD = this.sortArray(this.pg_GPICD);
            this.pg_GPI = this.sortArray(this.pg_GPI);
            this.pg_UDI = this.sortArray(this.pg_UDI);
          }
          this.status = 'ok';
        }
        this.loading = false;
      },
      err => this.onError(err)
    );
  }
  listarDiputadosGrupos(): void {
    //this.loading = true;
    this._deputyService.listarDiputadosGrupo().subscribe(

      res => {
        if (res.parliamentaryGroups?.length <= 0) {
          this.status = 'error';
        } else {
          if (res.parliamentaryGroups?.length === 0) {
            alert('No hay diputados para mostrar');
          } else {
            this.deputiesGPICD = res.parliamentaryGroups[0].deputies;
            this.deputiesGPI = res.parliamentaryGroups[1].deputies;
            this.deputiesUDI = res.parliamentaryGroups[2].deputies;
            this.sortDpByLast(this.deputiesGPICD);
            this.sortDpByLast(this.deputiesGPI);
            this.sortDpByLast(this.deputiesUDI);
            this.sortDescByDate(this.deputiesGPICD);
            this.sortDescByDate(this.deputiesGPI);
            this.sortDescByDate(this.deputiesUDI);
          }
          this.status = 'ok';
        }
      },
      err => this.onError(err)
    );
  }
  sortDpByLast(arrayObj: Deputies[]): Deputies[] {
    return arrayObj.sort((a, b) => {
      return a?.lastname?.localeCompare(b?.lastname);
    });
  }
  sortDescByDate(arrayObj: Deputies[]): Deputies[] {
    return arrayObj.sort((a, b) => {
      return a?.groupOrder?.localeCompare(b?.groupOrder);
    });
  }
  sortArray(array: Member[]): Member[] {
    return array?.sort((a, b) => (a.priority.toString() + a.country).localeCompare(b.priority.toString() + b.country));
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.loading = false;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
  }
}

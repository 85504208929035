<div class="boletin-parlamentario">
    <app-carousel [images]="images" [reduceBrightness]="true" [title]="'Parlacen ante \<br\>el Covid-19'"
        [interval]="10000"></app-carousel>
    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb py-5">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">{{ "Covid-19" | uppercase }}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-title-container mt-4 pb-0">
                <h3 class="section-title">{{ "Artículos de Covid-19" | uppercase }}</h3>
            </div>
            <div class="section-body-container">
                <app-cards-display [boundaryLinks]="false" [data]="noticias" [maxSize]="3"
                    [page]="page" [pageSize]="pageSize" [rotate]="true" size="sm"></app-cards-display>
            </div>
            <div class="pb-0 pt-4">
                <div class="section-title-container">
                    <h3 class="section-title">{{ "Articles in English" | uppercase }}</h3>
                </div>
            </div>
            <div class="section-body-container">
                <app-cards-display [boundaryLinks]="false" [data]="news" [maxSize]="3" [page]="page1"
                    [pageSize]="pageSize1" [rotate]="true" size="sm"></app-cards-display>
            </div>
            <div class="section-title-container pt-5">
                <h3 class="section-title">
                    {{ "political_legal_framework.know_more.title" | translate | uppercase }}
                </h3>
            </div>
            <div class="section-body-container">
                <div class="row conocer-mas text-white">
                    <div class="col-lg-6 text-center align-self-center ti-text">
                        <p class="ti-t">
                            {{ "covid_19.know_more.ti_text" | translate }}
                        </p>
                        <button type="button" class="btn btn-primary ti-btn" routerLink="/acerca-de">
                            {{ "covid_19.know_more.ti_btn" | translate | uppercase }}
                        </button>
                    </div>
                    <div class="col-lg-6 p-0 ti-image">
                        <img src="assets/img/photo4976974207179139262.png" width="100%" class="ti-img"
                            alt="Diputados del Parlamento Centroamericano" loading="lazy"
                            srcset="assets/img/photo4976974207179139262.png 1x, assets/img/photo4976974207179139262@2x.png 2x">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<app-spinner [loading]="loading"></app-spinner>
<div class="iota">
    <div>
        <app-carousel [heightIota]="true" class="hola" [images]="images"></app-carousel>
    </div>


    <div class="container-lg">
        <div class="my-container">
            <nav aria-label="breadcrumb d-responsive">
                <ol class="breadcrumb pt-5 pb-4">
                    <li class="breadcrumb-item">{{ "Inicio" | uppercase }}</li>
                    <li class="breadcrumb-item quote-transform active" aria-current="page">
                        <a class="nav-item" role="button">
                            {{ "Concurso Diseño Prototipo de Overol Fase 1" | uppercase
                            }}</a>
                    </li>
                </ol>
            </nav>
            <div class="section-body-container">
                <div class="row">

                    <h2 class="section-header w-100">“Diseño Prototipo de Overol de Misión (fase 1) Misión Espacial Centroamérica y el Caribe”</h2>
                    <p>El Parlamento Centroamericano es el órgano regional, permanente de representación política y democrática del Sistema de la Integración Centroamericana -SICA-, que tiene como objetivo fundamental la realización de la integración de Centroamérica, para consolidarla como Región de Paz, Libertad, Democracia y Desarrollo, promoviendo el cumplimiento de las metas y fines de las políticas públicas de educación en la región.</p>
                    <p>Uno de los objetivos primordiales para el Parlamento Centroamericano, radica en promover la Educación, Cultura, Deporte, Ciencia y Tecnología, como valor principal de la sociedad, que permiten el desarrollo y mejora de la calidad de vida de los habitantes de la región. </p>
                    <p>Congruente con ello para la Comisión de Educación, Cultura, Deporte, Ciencia y Tecnología del Parlamento Centroamericano, es primordial fomentar, incentivar y apoyar la Ciencia y Tecnología de Centroamérica y de República Dominicana, en la creación de identidad de la primera Misión Espacial de Centroamérica y el Caribe a desarrollarse con el programa aeroespacial de la Federación de Rusia.</p>
                </div>
            </div>

            <div class="container-lg">
                <div class="section-body-container">
                    <div class="row mision-vision text-center">
                        <div class="col-lg-6">
                            <h2 class="mision-h" loading="lazy">BASES DEL CONCURSO</h2>
                            <h3 class="mision-h premio" loading="lazy">I. ORGANIZACIÓN.</h3>
                            <ul class="mision-t">
                                <li>EL CONCURSO DE DISEÑO: PROTOTIPO DE OVEROL DE MISIÓN (FASE 1) MISIÓN ESPACIAL CENTROAMÉRICA Y EL CARIBE se declarará abierto a partir del lunes 9 de mayo y la fecha de cierre será el 9 de agosto de 2022.</li>
                                <li>El formulario de inscripción estará disponible en el siguiente vínculo: <a href="../../../assets/pdf/FORMULARIO_CONCURSO_DISEÑO_PROTOTIPO-DE-OVEROL.pdf">Descarga de formulario</a>.</li>
                                <li>Los interesados deberán ingresar al link correspondiente, en la página web y redes sociales del PARLACEN, en donde hallarán las <a href="../../../assets/pdf/BASES_CONCURSO-PROTOTIPO-DE-OVEROL-2.pdf">bases del concurso</a> y el <a href="../../../assets/pdf/FORMULARIO_CONCURSO_DISEÑO_PROTOTIPO-DE-OVEROL.pdf">formulario de inscripción</a>.</li>
                            </ul>
                            <h3 class="mision-h" loading="lazy">II. TÉRMINOS Y CONDICIONES DE PARTICIPACIÓN.</h3>
                            <ul class="mision-t">
                                <li>Para intervenir en el Concurso, cada participante, deberá presentar un diseño de prototipo original, se verificará por parte del jurado, que el mismo no haya sido  premiado con antelación y no estar en proceso de concurso. Se descalificará sin responsabilidad alguna para el Parlamento Centroamericano cualquier trabajo que no cumpla con tales requisitos. </li>
                                <li>Los participantes, al inscribirse aceptan respetar las bases del presente concurso, y acatar la decisión del jurado. </li>
                            </ul>
                            <h3 class="mision-h" loading="lazy">III. PARTICIPANTES:</h3>
                            <ul class="mision-t">
                                <li>El Concurso va dirigido a jóvenes Centroamericanos y de República Dominicana, de ambos géneros, concursantes en instituciones académicas regionales, a nivel técnico y universitario; preferentemente, involucrados en áreas relativas al diseño (por ejemplo, industrial, gráfico, textil, de moda, industrial, de interiores, de producto); con el objetivo de promover la inclusión de los jóvenes de la región en la creación de identidad de la primera Misión Espacial de Centroamérica y el Caribe; a desarrollarse con el programa aeroespacial de la Federación de Rusia.</li>
                            </ul>
                            <h3 class="mision-h" loading="lazy">IV. REQUISITOS DE ENTREGA DE DISEÑO:</h3>
                            <ol class="mision-t">
                                <li>- Recepción del diseño prototipo de overol de misión al correo <a href="mailto:misionespacial@parlacen.int">misionespacial@parlacen.int</a>, los participantes tendrán hasta el 9 de agosto de 2022, a las 23 horas con 59 minutos, para hacer entrega de sus diseños. </li>
                                <li>- Los participantes enviaran sus diseños y sus otorgamientos de derechos a la dirección de correo <a href="mailto:misionespacial@parlacen.int">misionespacial@parlacen.int</a></li>
                                <li>- Concluida la fecha de convocatoria (2 meses) se reunirá al jurado para la selección de tres opciones viables que cumplan los requisitos solicitados relativos al overol.</li>
                                <li>- El diseño, preferentemente, utilizará esquemas de color base del overol: pantone 11-4800 TPX Blanc de Blanc; pantone 14-5002 TPX Silver; pantone 18-4041 TCX Star Sapphire; pantone 18-3932 TCX Marlin; pantone 19-3915 TCX Graystone; pantone 19-0303 TCX Jet Black y pantone 16-1361 TCX Carrot generalmente asociados a la exploración espacial.</li>
                                <li>- Al diseño se le pueden agregar colores, preferentemente, los incluidos en las banderas nacionales de El Parlamento Centroamericano, El Salvador, Guatemala, Honduras, Nicaragua, Panamá y República Dominicana.</li>
                                <li>- Al diseño se le pueden agregar formas y símbolos, preferentemente, asociados a emblemas, escudos y otros característicamente representativos de El Parlamento Centroamericano, El Salvador, Guatemala, Honduras, Nicaragua, Panamá y República Dominicana; siempre y cuando no ocupen la parte frontal pectoral del overol y la parte superior de los brazos, izquierda y derecha) ya que allí se colocarán los parches de la misión pertinente.</li>
                                <li>- El diseño del prototipo debe de indicar el textil en que se debe confeccionar; preferentemente, el usado en uniformes industriales (por ejemplo, gabardina, tergal catalán y Atenas).</li>
                                <li>- El overol deberá de contar con un sistema ergonómico de cierre en el área frontal que corra desde el cuello hasta la cintura; y otro de tobillo a rodilla. 
                                    <br/> Diseño deberá contar con ocho (8) bolsillos con un sistema ergonómico de cierre: dos (2) bolsillos frontales en área pectoral (ver esquema); dos (2) laterales a nivel frontal de brazo (dejando espacio superior para parches de misión); dos (2) en el área frontal de los muslos (estilo pantalón) y dos (2) en el área lateral de tobillos.
                                </li>    
                                <li>- El diseño deberá incluir ajustadores de ancho, preferentemente de velcro, u otro tipo de sistema de ajuste ergonómico para muñecas, abdomen (cintura), parte inferior de tobillo o ruedo y cuello.</li>
                                <li>- El diseño deberá contar con refuerzos textiles en área de hombros y rodillas.</li>
                                <li>- El diseño deberá incluir sistema de capuchón desmontable con sistema de cierre ergonómico, manteniendo el campo visual similar al de una bala clava o pasa montaña.</li>
                            </ol>
                        </div>
                        <div class="col-lg-6">
                            <h3 class="mision-h" loading="lazy">V. JURADO CALIFICADOR Y CRITERIOS DE EVALUACIÓN DEL DISEÑO DE PROTOTIPO OVEROL:</h3>
                            <ol class="mision-t">
                                <li>- El Jurado calificador trasladará tres selecciones a Presidencia del PARLACEN quien deberá enviarla a los diputados centroamericanos quienes deberán de emitir su voto a favor por la opción favorita (puede hacerse en plenaria o bien por correo).</li>
                                <li>- El jurado estará integrado por los designados por el Presidente HD Guillermo Daniel Ortega Reyes; la diseñadora Liza Carrillo y el primer candidato a cosmonauta de Centroamérica y República Dominicana, Dr. Vinicio Montoya De León.</li>
                                <li>- El Presidente del PARLACEN HD Guillermo Daniel Ortega Reyes, presentará los 3 diseños seleccionados por el Jurado a Plenaria del PARLACEN para que los diputados centroamericanos voten por su diseño favorito.</li>
                                <li>- El (la) ganador(a) será notificado a su correo electrónico y número de contacto telefónico; y, el día de la presentación del overol confeccionado se dará a conocer públicamente.</li>
                                <li>- La selección ganadora será enviada a confección de dos overoles, en base a la biométrica del candidato, para presentarse al público en actividad y fecha designada para ello; en la que se incluirá la premiación del diseñador (a) ganador.</li>
                            </ol>
                            <h3 class="mision-h" loading="lazy">VI. PREMIO ÚNICO:</h3>
                            <ul class="mision-t">
                                La premiación se realizará el jueves 25 de agosto de 2022, y el premio será entregado en la Ciudad de Guatemala, en la sede del Parlamento Centroamericano, o, en las Subsedes de los Estados Miembros del Parlamento Centroamericano, según la nacionalidad del 
                                <li>PREMIO ÚNICO: USD 1000 (mil dólares), y Pergamino de Reconocimiento.</li>
                            </ul>
                            <h3 class="mision-h" loading="lazy">VII. PREMIO ÚNICO:</h3>
                            <ol class="mision-t">
                                <li>- El diseño premiado será publicado en la página web del Parlamento Centroamericano, así como en los medios de comunicación y redes sociales que considere oportunos. </li>
                                <li>- La autoría es únicamente de los diseñadores, y debe ser siempre comunicada al momento de realizar algún acto o actividad pública o a través de redes sociales u otros medios de comunicación. Los participantes también acuerdan con el Parlamento Centroamericano, no cometer actos de comunicación que puedan constituir una violación de los derechos morales de los participantes. Todos los participantes del CONCURSO DE DISEÑO: PROTOTIPO DE OVEROL DE MISIÓN (FASE 1) MISIÓN ESPACIAL CENTROAMÉRICA Y EL CARIBE aceptan que eximirán, otorgarán garantías e indemnizarán al PARLACEN de todas las demandas sobre la publicidad, la presentación, el contenido en Internet, comercial o cualquier otro material producido posteriormente, presentado y/o preparado por o en nombre del PARLACEN, que puedan afectar los derechos de la obra de poesía de los participantes o del PARLACEN. </li>
                                <li>- Los participantes dan por entendido y aceptan que el uso que PARLACEN haga de las presentaciones o los materiales similares o idénticos a la presentación, o que tengan características o elementos similares o idénticos a los que contiene la presentación, no obliga al PARLACEN a negociar con el participante, ni le otorga ningún derecho ni compensación de ningún tipo al participante. </li>
                                <li>- Los participantes aceptan y reconocen que nada impide que el PARLACEN utilice cualquier material o material similar a la presentación sin tener la obligación de comunicarlo al participante, incluyendo, pero sin limitarse al caso en que el organizador determine que tiene un derecho independiente de utilizar cualquier material por motivos que incluyen, pero no se limitan al hecho de que estos elementos o características no son nuevos u originales del participante, o fueron o pudieron haber sido creados de manera independiente por un tercero o presentados a los socios. </li>
                                <li>- Los participantes reconocen que otros participantes pueden haber utilizado en su presentación ideas y/o conceptos que podrían ser similares a la idea o el concepto incluido en la presentación del participante. El participante da por entendido y acepta que no tiene derecho a presentar demanda alguna contra otro participante, socio u organizador con respecto a ninguna similitud, y que no tiene derecho a ninguna indemnización por motivo de dicha similitud. </li>
                                <li>- El PARLACEN podrá publicar la descripción de la obra recibida en cualquier medio de comunicación/red social que crea conveniente. En todo caso, el PARLACEN hará mención del autor o autores de la obra. </li>
                                <li>- Lo anterior implica el reconocimiento de la autoría y la cesión del diseño de prototipo overol de misión ganador por un plazo de tres años a partir de la premiación, al Parlamento Centroamericano para su publicación, impresión, difusión y presentación a nivel mundial sin fines de lucro. En ningún caso con fines comerciales. </li>
                                <li>- Los concursantes que no hayan ganado podrán a su vez utilizar sus diseños presentadas con fines de poder participar en otros concursos a nivel mundial. Lo anterior sin perjuicio del PARLACEN a utilizar al diseñador para fines no comerciales. </li>
                                <li>- El PARLACEN se reserva el derecho a mantener el diseño de prototipo ganador publicado en la web o distintos medios del PARLACEN, el tiempo que considere oportuno, y de su uso para fines educativos y de sensibilización. </li>
                            </ol>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="section-body-container">
                
                <div class="row">
                    <img src="assets/img/Poster-Mision-Espacial-2.jpeg" class="img-fluid d-block" alt="imagen">
                </div>
            </div>
            <div class="section-title-container">
                <h3 class="section-title">
                    {{ "" | translate | uppercase }}
                </h3>
            </div>
            <div class="section-body-container">
                <div class="row">
                    <div class="col-lg-12 text-justify pt-3 px-0">
                        <p class="info-text pr-lg-3">
                            DESCARGAS
                        </p>
                    </div>
                </div>
            </div>
            <div class="btn-descargar-folleto">
                <div class="row text-center">
                    <div class="col-lg-4">
                        <div class="card">
                            <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                                height="40px">
                            <div class="card-body">
                                <h3 class="card-title">
                                    Formulario
                                </h3>
                                <p class="info-text">
                                    Descarga el formulario del concurso
                                </p>
                                <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                    <a class="nav-link" href="../../../assets/pdf/FORMULARIO_CONCURSO_DISEÑO_PROTOTIPO-DE-OVEROL.pdf"
                                        target="_blank" download="Formulario concurso PROTOTIPO-DE-OVEROL">
                                        Descargar
                                    </a>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                      <div class="card">
                          <img src="assets/svg/DocumentosAzul.svg" class="card-img-top" alt="..." width="40px"
                              height="40px">
                          <div class="card-body">
                              <h3 class="card-title">
                                  Convocatoria
                              </h3>
                              <p class="info-text">
                                BASES DEL CONCURSO
                              </p>
                              <button type="button" class="btn btn-outline-dark outline-leer-mas">
                                  <a class="nav-link" href="../../../assets/pdf/BASES_CONCURSO-PROTOTIPO-DE-OVEROL-2.pdf"
                                      target="_blank" download="BASES DEL CONCURSO PROTOTIPO-DE-OVEROL">
                                      Descargar
                                  </a>
                              </button>
                          </div>
                      </div>
                  </div>
                </div>
            </div>

        <!--   <div class="section-body-container">
                <div class="row">
                    <div class="col-lg-12 text-justify pt-3 px-0">
                        <p class="info-text pr-lg-3">
                            VIDEO
                        </p>
                    </div>
                </div>
            </div>
            <video width="80%" controls autoplay playsinline>
                <source src="assets/img/VID-20210520-WA0006.mp4" type="video/mp4">
            </video> 
        --> 
           
        </div>
    </div>
   
</div>
<app-spinner [loading]="loading"></app-spinner>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Deputies } from 'src/app/models/deputy.model';
import { DiputiesService } from 'src/app/services/diputies.service';
declare var $: any;

@Component({
  selector: 'app-bloque-mujeres-parlamentarias',
  templateUrl: './bloque-mujeres-parlamentarias.component.html',
  styleUrls: ['./bloque-mujeres-parlamentarias.component.css']
})
export class BloqueMujeresParlamentariasComponent implements OnInit, OnDestroy {
  public bodySize: number = 0;
  status: string;
  loading: boolean = false;
  public deputies: Deputies[] = [];
  public deputy: Deputies;

  constructor(
    private _deputyService: DiputiesService,
  ) {
  }

  ngOnInit(): void {
    this.bodySize = window.screen.width;
    window.addEventListener("resize", this.onResize);
    $('html,body').scrollTop(0);
    this.listarDiputadosGrupos();
  }
  listarDiputadosGrupos(): void {
    //this.loading = true;
    this._deputyService.listarDiputadosBlock().subscribe(

      res => {
        if (res.womensBlock?.length <= 0) {
          this.status = 'error';
        } else {
          if (res.womensBlock?.length === 0) {
            alert('No hay diputados para mostrar');
          } else {
            this.deputies = res.womensBlock;
            this.sortDpByLast(this.deputies);
            // console.log(this.deputies);
            this.sortDescByDate(this.deputies);
          }
          this.status = 'ok';
        }
      },
      err => this.onError(err)
    );
  }
  ngOnDestroy(): void {
    window.removeEventListener("resize", this.onResize);
  }

  sortDpByLast(arrayObj: Deputies[]): Deputies[] {
    return arrayObj.sort((a, b) => {
      return a?.lastname?.localeCompare(b?.lastname);
    });
  }

  sortDescByDate(arrayObj: Deputies[]): Deputies[] {
    return arrayObj.sort((a, b) => {
      return a?.blockOrder?.localeCompare(b?.blockOrder);
    });
  }

  onError(error: any): void {
    var errorMessage = error;
    if (errorMessage != null) {
      this.status = 'error';
    }
    this.loading = false;
  }

  onResize(): void {
    this.bodySize = window.screen.width;
  }

}
